import { z as zod } from 'zod'

/**
 * @summary Get list of time tracking records for current logged-in user
 */
export const projectsControllerFindAllQueryParams = zod.object({
  page: zod.coerce.number().optional(),
  limit: zod.coerce.number().optional(),
  'filter.name': zod.array(zod.coerce.string()).optional(),
  sortBy: zod
    .array(
      zod.enum(['name:ASC', 'name:DESC', 'createdAt:ASC', 'createdAt:DESC'])
    )
    .optional(),
  select: zod.coerce.string().optional(),
})

export const projectsControllerFindAllResponseDataItemSerialMax = 50
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanySerialMax = 50
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyNameMax = 100
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyAddressLatMin =
  -90

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyAddressLatMax = 90
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyAddressLonMin =
  -180

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyAddressLonMax = 180
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyTagNameMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyTagAliasesItemNameAliasMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyRatingMax = 5
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyMaxDistanceToProjectMin = 0
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerFirstNameMax = 100
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerLastNameMax = 100
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemPerHourSalaryMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemMonthlyDurationDaysMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemMonthlyDurationDaysMax = 365
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemMonthlyDurationHoursMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemMonthlyDurationHoursMax = 23
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemMonthlyDurationMinutesMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemMonthlyDurationMinutesMax = 59
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemMonthlyDurationMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemMonthlyDurationMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemGrossSalaryMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemGrossSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemVacationPerMonthDaysMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemVacationPerMonthDaysMax = 365
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemVacationPerMonthHoursMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemVacationPerMonthHoursMax = 23
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemVacationPerMonthMinutesMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemVacationPerMonthMinutesMax = 59
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemVacationPerMonthMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemVacationPerMonthMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemAttachmentsItemNameMax = 512
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemAttachmentsItemPathMax = 512
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemAttachmentsItemMimeTypeMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemPreviousSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemPreviousSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemPreviousPerHourSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemPreviousPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemSalaryDiffMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemSalaryDiffMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractPerHourSalaryMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractMonthlyDurationDaysMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractMonthlyDurationDaysMax = 365
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractMonthlyDurationHoursMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractMonthlyDurationHoursMax = 23
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractMonthlyDurationMinutesMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractMonthlyDurationMinutesMax = 59
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractMonthlyDurationMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractMonthlyDurationMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractGrossSalaryMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractGrossSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractVacationPerMonthDaysMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractVacationPerMonthDaysMax = 365
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractVacationPerMonthHoursMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractVacationPerMonthHoursMax = 23
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractVacationPerMonthMinutesMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractVacationPerMonthMinutesMax = 59
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractVacationPerMonthMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractVacationPerMonthMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractAttachmentsItemNameMax = 512
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractAttachmentsItemPathMax = 512
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractAttachmentsItemMimeTypeMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractPreviousSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractPreviousSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractPreviousPerHourSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractPreviousPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractSalaryDiffMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractSalaryDiffMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemPerHourMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemPerHourMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemMonthlyDurationDaysMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemMonthlyDurationDaysMax = 365
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemMonthlyDurationHoursMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemMonthlyDurationHoursMax = 23
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemMonthlyDurationMinutesMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemMonthlyDurationMinutesMax = 59
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemMonthlyDurationMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemMonthlyDurationMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemBaseMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemBaseMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemBonusMin =
  -100

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemBonusMax = 100
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemPaidMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemPaidMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerLeaveTypesItemNameMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerLeaveTypesItemDescriptionMax = 1024
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerLeaveTypesItemColorMin = 7

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerLeaveTypesItemColorMax = 7

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerLeaveTypesItemColorRegExp =
  new RegExp('^#?([0-9A-F]{3}|[0-9A-F]{4}|[0-9A-F]{6}|[0-9A-F]{8})$')
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearDaysMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearDaysMax = 365
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearHoursMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearHoursMax = 23
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearMinutesMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearMinutesMax = 59
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemExternalProjectManagerFirstNameMax = 100
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemExternalProjectManagerLastNameMax = 100
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemExternalProjectManagerAddressLatMin =
  -90

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemExternalProjectManagerAddressLatMax = 90
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemExternalProjectManagerAddressLonMin =
  -180

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemExternalProjectManagerAddressLonMax = 180
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemPeopleItemFirstNameMax = 100
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemPeopleItemLastNameMax = 100
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemPeopleItemAddressLatMin =
  -90

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemPeopleItemAddressLatMax = 90
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemPeopleItemAddressLonMin =
  -180

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemPeopleItemAddressLonMax = 180
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemMinNumberOfWorkersOnSiteMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemMinNumberOfWorkersOnSiteMax = 32767
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemMaxNumberOfWorkersOnSiteMin = 0
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemNetPriceMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemNetPriceMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemTagNameMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemTagAliasesItemNameAliasMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemWarrantyReserveMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemWarrantyReserveMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemUmlageAmountMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemUmlageAmountMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemSkontoAmountMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemSkontoAmountMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemAttachmentsItemNameMax = 512
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemAttachmentsItemPathMax = 512
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemAttachmentsItemMimeTypeMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanySubContractorCostItemTagNameMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanySubContractorCostItemTagAliasesItemNameAliasMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemCompanySubContractorCostItemItemsItemUnitPriceGcPercentMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemCompanySubContractorCostItemItemsItemUnitPriceGcPercentMax = 100
export const projectsControllerFindAllResponseDataItemAllocationsItemTagNameMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemTagAliasesItemNameAliasMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerFirstNameMax = 100
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerLastNameMax = 100
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemPerHourSalaryMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemMonthlyDurationDaysMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemMonthlyDurationDaysMax = 365
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemMonthlyDurationHoursMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemMonthlyDurationHoursMax = 23
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemMonthlyDurationMinutesMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemMonthlyDurationMinutesMax = 59
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemMonthlyDurationMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemMonthlyDurationMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemGrossSalaryMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemGrossSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemVacationPerMonthDaysMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemVacationPerMonthDaysMax = 365
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemVacationPerMonthHoursMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemVacationPerMonthHoursMax = 23
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemVacationPerMonthMinutesMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemVacationPerMonthMinutesMax = 59
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemVacationPerMonthMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemVacationPerMonthMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemAttachmentsItemNameMax = 512
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemAttachmentsItemPathMax = 512
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemAttachmentsItemMimeTypeMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemPreviousSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemPreviousSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemPreviousPerHourSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemPreviousPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemSalaryDiffMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemSalaryDiffMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractPerHourSalaryMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractMonthlyDurationDaysMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractMonthlyDurationDaysMax = 365
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractMonthlyDurationHoursMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractMonthlyDurationHoursMax = 23
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractMonthlyDurationMinutesMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractMonthlyDurationMinutesMax = 59
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractMonthlyDurationMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractMonthlyDurationMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractGrossSalaryMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractGrossSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractVacationPerMonthDaysMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractVacationPerMonthDaysMax = 365
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractVacationPerMonthHoursMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractVacationPerMonthHoursMax = 23
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractVacationPerMonthMinutesMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractVacationPerMonthMinutesMax = 59
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractVacationPerMonthMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractVacationPerMonthMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractAttachmentsItemNameMax = 512
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractAttachmentsItemPathMax = 512
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractAttachmentsItemMimeTypeMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractPreviousSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractPreviousSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractPreviousPerHourSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractPreviousPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractSalaryDiffMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractSalaryDiffMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemPerHourMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemPerHourMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemMonthlyDurationDaysMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemMonthlyDurationDaysMax = 365
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemMonthlyDurationHoursMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemMonthlyDurationHoursMax = 23
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemMonthlyDurationMinutesMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemMonthlyDurationMinutesMax = 59
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemMonthlyDurationMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemMonthlyDurationMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemBaseMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemBaseMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemBonusMin =
  -100

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemBonusMax = 100
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemPaidMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemPaidMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerLeaveTypesItemNameMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerLeaveTypesItemDescriptionMax = 1024
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerLeaveTypesItemColorMin = 7

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerLeaveTypesItemColorMax = 7

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerLeaveTypesItemColorRegExp =
  new RegExp('^#?([0-9A-F]{3}|[0-9A-F]{4}|[0-9A-F]{6}|[0-9A-F]{8})$')
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerLeaveTypesItemAllowedPerYearDaysMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerLeaveTypesItemAllowedPerYearDaysMax = 365
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerLeaveTypesItemAllowedPerYearHoursMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerLeaveTypesItemAllowedPerYearHoursMax = 23
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerLeaveTypesItemAllowedPerYearMinutesMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerLeaveTypesItemAllowedPerYearMinutesMax = 59
export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerLeaveTypesItemAllowedPerYearMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerLeaveTypesItemAllowedPerYearMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerCompanySerialMax = 50
export const projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerCompanyNameMax = 100
export const projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerCompanyAddressLatMin =
  -90

export const projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerCompanyAddressLatMax = 90
export const projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerCompanyAddressLonMin =
  -180

export const projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerCompanyAddressLonMax = 180
export const projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerCompanyTagNameMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerCompanyTagAliasesItemNameAliasMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerCompanyRatingMax = 5
export const projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerCompanyMaxDistanceToProjectMin = 0
export const projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerCompanySubContractorCostItemTagNameMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerCompanySubContractorCostItemTagAliasesItemNameAliasMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerCompanySubContractorCostItemItemsItemUnitPriceGcPercentMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerCompanySubContractorCostItemItemsItemUnitPriceGcPercentMax = 100
export const projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerFirstNameMax = 100
export const projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerLastNameMax = 100
export const projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerAddressLatMin =
  -90

export const projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerAddressLatMax = 90
export const projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerAddressLonMin =
  -180

export const projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerAddressLonMax = 180
export const projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemCompanySerialMax = 50
export const projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemCompanyNameMax = 100
export const projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemCompanyAddressLatMin =
  -90

export const projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemCompanyAddressLatMax = 90
export const projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemCompanyAddressLonMin =
  -180

export const projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemCompanyAddressLonMax = 180
export const projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemCompanyTagNameMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemCompanyTagAliasesItemNameAliasMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemCompanyRatingMax = 5
export const projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemCompanyMaxDistanceToProjectMin = 0
export const projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemCompanySubContractorCostItemTagNameMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemCompanySubContractorCostItemTagAliasesItemNameAliasMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemCompanySubContractorCostItemItemsItemUnitPriceGcPercentMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemCompanySubContractorCostItemItemsItemUnitPriceGcPercentMax = 100
export const projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemFirstNameMax = 100
export const projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemLastNameMax = 100
export const projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemAddressLatMin =
  -90

export const projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemAddressLatMax = 90
export const projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemAddressLonMin =
  -180

export const projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemAddressLonMax = 180
export const projectsControllerFindAllResponseDataItemAllocationsItemContractMinNumberOfWorkersOnSiteMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractMinNumberOfWorkersOnSiteMax = 32767
export const projectsControllerFindAllResponseDataItemAllocationsItemContractMaxNumberOfWorkersOnSiteMin = 0
export const projectsControllerFindAllResponseDataItemAllocationsItemContractCompanySerialMax = 50
export const projectsControllerFindAllResponseDataItemAllocationsItemContractCompanyNameMax = 100
export const projectsControllerFindAllResponseDataItemAllocationsItemContractCompanyAddressLatMin =
  -90

export const projectsControllerFindAllResponseDataItemAllocationsItemContractCompanyAddressLatMax = 90
export const projectsControllerFindAllResponseDataItemAllocationsItemContractCompanyAddressLonMin =
  -180

export const projectsControllerFindAllResponseDataItemAllocationsItemContractCompanyAddressLonMax = 180
export const projectsControllerFindAllResponseDataItemAllocationsItemContractCompanyTagNameMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemContractCompanyTagAliasesItemNameAliasMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemContractCompanyRatingMax = 5
export const projectsControllerFindAllResponseDataItemAllocationsItemContractCompanyMaxDistanceToProjectMin = 0
export const projectsControllerFindAllResponseDataItemAllocationsItemContractCompanySubContractorCostItemTagNameMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemContractCompanySubContractorCostItemTagAliasesItemNameAliasMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemContractCompanySubContractorCostItemItemsItemUnitPriceGcPercentMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractCompanySubContractorCostItemItemsItemUnitPriceGcPercentMax = 100
export const projectsControllerFindAllResponseDataItemAllocationsItemContractNetPriceMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemContractNetPriceMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemContractTagNameMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemContractTagAliasesItemNameAliasMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemContractWarrantyReserveMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationsItemContractWarrantyReserveMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemContractUmlageAmountMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationsItemContractUmlageAmountMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemContractSkontoAmountMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationsItemContractSkontoAmountMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemContractAttachmentsItemNameMax = 512
export const projectsControllerFindAllResponseDataItemAllocationsItemContractAttachmentsItemPathMax = 512
export const projectsControllerFindAllResponseDataItemAllocationsItemContractAttachmentsItemMimeTypeMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemFirstNameMax = 100
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemLastNameMax = 100
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemPerHourSalaryMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemMonthlyDurationDaysMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemMonthlyDurationDaysMax = 365
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemMonthlyDurationHoursMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemMonthlyDurationHoursMax = 23
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemMonthlyDurationMinutesMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemMonthlyDurationMinutesMax = 59
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemMonthlyDurationMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemMonthlyDurationMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemGrossSalaryMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemGrossSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemVacationPerMonthDaysMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemVacationPerMonthDaysMax = 365
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemVacationPerMonthHoursMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemVacationPerMonthHoursMax = 23
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemVacationPerMonthMinutesMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemVacationPerMonthMinutesMax = 59
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemVacationPerMonthMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemVacationPerMonthMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemAttachmentsItemNameMax = 512
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemAttachmentsItemPathMax = 512
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemAttachmentsItemMimeTypeMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemPreviousSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemPreviousSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemPreviousPerHourSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemPreviousPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemSalaryDiffMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemSalaryDiffMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractPerHourSalaryMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractMonthlyDurationDaysMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractMonthlyDurationDaysMax = 365
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractMonthlyDurationHoursMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractMonthlyDurationHoursMax = 23
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractMonthlyDurationMinutesMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractMonthlyDurationMinutesMax = 59
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractMonthlyDurationMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractMonthlyDurationMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractGrossSalaryMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractGrossSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractVacationPerMonthDaysMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractVacationPerMonthDaysMax = 365
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractVacationPerMonthHoursMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractVacationPerMonthHoursMax = 23
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractVacationPerMonthMinutesMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractVacationPerMonthMinutesMax = 59
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractVacationPerMonthMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractVacationPerMonthMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractAttachmentsItemNameMax = 512
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractAttachmentsItemPathMax = 512
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractAttachmentsItemMimeTypeMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractPreviousSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractPreviousSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractPreviousPerHourSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractPreviousPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractSalaryDiffMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractSalaryDiffMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemPerHourMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemPerHourMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemMonthlyDurationDaysMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemMonthlyDurationDaysMax = 365
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemMonthlyDurationHoursMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemMonthlyDurationHoursMax = 23
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemMonthlyDurationMinutesMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemMonthlyDurationMinutesMax = 59
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemMonthlyDurationMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemMonthlyDurationMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemBaseMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemBaseMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemBonusMin =
  -100

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemBonusMax = 100
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemPaidMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemPaidMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemLeaveTypesItemNameMax = 255
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemLeaveTypesItemDescriptionMax = 1024
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemLeaveTypesItemColorMin = 7

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemLeaveTypesItemColorMax = 7

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemLeaveTypesItemColorRegExp =
  new RegExp('^#?([0-9A-F]{3}|[0-9A-F]{4}|[0-9A-F]{6}|[0-9A-F]{8})$')
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemLeaveTypesItemAllowedPerYearDaysMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemLeaveTypesItemAllowedPerYearDaysMax = 365
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemLeaveTypesItemAllowedPerYearHoursMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemLeaveTypesItemAllowedPerYearHoursMax = 23
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemLeaveTypesItemAllowedPerYearMinutesMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemLeaveTypesItemAllowedPerYearMinutesMax = 59
export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemLeaveTypesItemAllowedPerYearMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemLeaveTypesItemAllowedPerYearMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemAllocationsItemAgreedPriceMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationsItemAgreedPriceMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemInitialBudgetMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationsItemInitialBudgetMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemMultipleFactorMin = 0
export const projectsControllerFindAllResponseDataItemAllocationsItemGlobalBudgetMin = 0

export const projectsControllerFindAllResponseDataItemAllocationsItemGlobalBudgetMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemDiscountAmountMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationsItemDiscountAmountMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationsItemTaxAmountMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationsItemTaxAmountMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemRoadmapsItemTagNameMax = 255
export const projectsControllerFindAllResponseDataItemRoadmapsItemTagAliasesItemNameAliasMax = 255
export const projectsControllerFindAllResponseDataItemRoadmapsItemColorMin = 7

export const projectsControllerFindAllResponseDataItemRoadmapsItemColorMax = 7
export const projectsControllerFindAllResponseDataItemRoadmapsItemItemsItemColorMin = 7

export const projectsControllerFindAllResponseDataItemRoadmapsItemItemsItemColorMax = 7
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerFirstNameMax = 100
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerLastNameMax = 100
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemPerHourSalaryMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemMonthlyDurationDaysMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemMonthlyDurationDaysMax = 365
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemMonthlyDurationHoursMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemMonthlyDurationHoursMax = 23
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemMonthlyDurationMinutesMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemMonthlyDurationMinutesMax = 59
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemMonthlyDurationMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemMonthlyDurationMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemGrossSalaryMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemGrossSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemVacationPerMonthDaysMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemVacationPerMonthDaysMax = 365
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemVacationPerMonthHoursMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemVacationPerMonthHoursMax = 23
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemVacationPerMonthMinutesMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemVacationPerMonthMinutesMax = 59
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemVacationPerMonthMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemVacationPerMonthMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemAttachmentsItemNameMax = 512
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemAttachmentsItemPathMax = 512
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemAttachmentsItemMimeTypeMax = 255
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemPreviousSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemPreviousSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemPreviousPerHourSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemPreviousPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemSalaryDiffMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemSalaryDiffMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractPerHourSalaryMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractMonthlyDurationDaysMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractMonthlyDurationDaysMax = 365
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractMonthlyDurationHoursMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractMonthlyDurationHoursMax = 23
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractMonthlyDurationMinutesMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractMonthlyDurationMinutesMax = 59
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractMonthlyDurationMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractMonthlyDurationMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractGrossSalaryMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractGrossSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractVacationPerMonthDaysMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractVacationPerMonthDaysMax = 365
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractVacationPerMonthHoursMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractVacationPerMonthHoursMax = 23
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractVacationPerMonthMinutesMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractVacationPerMonthMinutesMax = 59
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractVacationPerMonthMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractVacationPerMonthMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractAttachmentsItemNameMax = 512
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractAttachmentsItemPathMax = 512
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractAttachmentsItemMimeTypeMax = 255
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractPreviousSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractPreviousSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractPreviousPerHourSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractPreviousPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractSalaryDiffMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractSalaryDiffMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemPerHourMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemPerHourMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemMonthlyDurationDaysMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemMonthlyDurationDaysMax = 365
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemMonthlyDurationHoursMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemMonthlyDurationHoursMax = 23
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemMonthlyDurationMinutesMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemMonthlyDurationMinutesMax = 59
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemMonthlyDurationMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemMonthlyDurationMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemBaseMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemBaseMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemBonusMin =
  -100

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemBonusMax = 100
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemPaidMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemPaidMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerLeaveTypesItemNameMax = 255
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerLeaveTypesItemDescriptionMax = 1024
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerLeaveTypesItemColorMin = 7

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerLeaveTypesItemColorMax = 7

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerLeaveTypesItemColorRegExp =
  new RegExp('^#?([0-9A-F]{3}|[0-9A-F]{4}|[0-9A-F]{6}|[0-9A-F]{8})$')
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearDaysMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearDaysMax = 365
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearHoursMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearHoursMax = 23
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearMinutesMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearMinutesMax = 59
export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerCompanySerialMax = 50
export const projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerCompanyNameMax = 100
export const projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerCompanyAddressLatMin =
  -90

export const projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerCompanyAddressLatMax = 90
export const projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerCompanyAddressLonMin =
  -180

export const projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerCompanyAddressLonMax = 180
export const projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerCompanyTagNameMax = 255
export const projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerCompanyTagAliasesItemNameAliasMax = 255
export const projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerCompanyRatingMax = 5
export const projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerCompanyMaxDistanceToProjectMin = 0
export const projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerCompanySubContractorCostItemTagNameMax = 255
export const projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerCompanySubContractorCostItemTagAliasesItemNameAliasMax = 255
export const projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerCompanySubContractorCostItemItemsItemUnitPriceGcPercentMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerCompanySubContractorCostItemItemsItemUnitPriceGcPercentMax = 100
export const projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerFirstNameMax = 100
export const projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerLastNameMax = 100
export const projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerAddressLatMin =
  -90

export const projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerAddressLatMax = 90
export const projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerAddressLonMin =
  -180

export const projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerAddressLonMax = 180
export const projectsControllerFindAllResponseDataItemContractsItemPeopleItemCompanySerialMax = 50
export const projectsControllerFindAllResponseDataItemContractsItemPeopleItemCompanyNameMax = 100
export const projectsControllerFindAllResponseDataItemContractsItemPeopleItemCompanyAddressLatMin =
  -90

export const projectsControllerFindAllResponseDataItemContractsItemPeopleItemCompanyAddressLatMax = 90
export const projectsControllerFindAllResponseDataItemContractsItemPeopleItemCompanyAddressLonMin =
  -180

export const projectsControllerFindAllResponseDataItemContractsItemPeopleItemCompanyAddressLonMax = 180
export const projectsControllerFindAllResponseDataItemContractsItemPeopleItemCompanyTagNameMax = 255
export const projectsControllerFindAllResponseDataItemContractsItemPeopleItemCompanyTagAliasesItemNameAliasMax = 255
export const projectsControllerFindAllResponseDataItemContractsItemPeopleItemCompanyRatingMax = 5
export const projectsControllerFindAllResponseDataItemContractsItemPeopleItemCompanyMaxDistanceToProjectMin = 0
export const projectsControllerFindAllResponseDataItemContractsItemPeopleItemCompanySubContractorCostItemTagNameMax = 255
export const projectsControllerFindAllResponseDataItemContractsItemPeopleItemCompanySubContractorCostItemTagAliasesItemNameAliasMax = 255
export const projectsControllerFindAllResponseDataItemContractsItemPeopleItemCompanySubContractorCostItemItemsItemUnitPriceGcPercentMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemPeopleItemCompanySubContractorCostItemItemsItemUnitPriceGcPercentMax = 100
export const projectsControllerFindAllResponseDataItemContractsItemPeopleItemFirstNameMax = 100
export const projectsControllerFindAllResponseDataItemContractsItemPeopleItemLastNameMax = 100
export const projectsControllerFindAllResponseDataItemContractsItemPeopleItemAddressLatMin =
  -90

export const projectsControllerFindAllResponseDataItemContractsItemPeopleItemAddressLatMax = 90
export const projectsControllerFindAllResponseDataItemContractsItemPeopleItemAddressLonMin =
  -180

export const projectsControllerFindAllResponseDataItemContractsItemPeopleItemAddressLonMax = 180
export const projectsControllerFindAllResponseDataItemContractsItemMinNumberOfWorkersOnSiteMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemMinNumberOfWorkersOnSiteMax = 32767
export const projectsControllerFindAllResponseDataItemContractsItemMaxNumberOfWorkersOnSiteMin = 0
export const projectsControllerFindAllResponseDataItemContractsItemCompanySerialMax = 50
export const projectsControllerFindAllResponseDataItemContractsItemCompanyNameMax = 100
export const projectsControllerFindAllResponseDataItemContractsItemCompanyAddressLatMin =
  -90

export const projectsControllerFindAllResponseDataItemContractsItemCompanyAddressLatMax = 90
export const projectsControllerFindAllResponseDataItemContractsItemCompanyAddressLonMin =
  -180

export const projectsControllerFindAllResponseDataItemContractsItemCompanyAddressLonMax = 180
export const projectsControllerFindAllResponseDataItemContractsItemCompanyTagNameMax = 255
export const projectsControllerFindAllResponseDataItemContractsItemCompanyTagAliasesItemNameAliasMax = 255
export const projectsControllerFindAllResponseDataItemContractsItemCompanyRatingMax = 5
export const projectsControllerFindAllResponseDataItemContractsItemCompanyMaxDistanceToProjectMin = 0
export const projectsControllerFindAllResponseDataItemContractsItemCompanySubContractorCostItemTagNameMax = 255
export const projectsControllerFindAllResponseDataItemContractsItemCompanySubContractorCostItemTagAliasesItemNameAliasMax = 255
export const projectsControllerFindAllResponseDataItemContractsItemCompanySubContractorCostItemItemsItemUnitPriceGcPercentMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemCompanySubContractorCostItemItemsItemUnitPriceGcPercentMax = 100
export const projectsControllerFindAllResponseDataItemContractsItemNetPriceMin = 0

export const projectsControllerFindAllResponseDataItemContractsItemNetPriceMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemContractsItemTagNameMax = 255
export const projectsControllerFindAllResponseDataItemContractsItemTagAliasesItemNameAliasMax = 255
export const projectsControllerFindAllResponseDataItemContractsItemWarrantyReserveMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemContractsItemWarrantyReserveMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemContractsItemUmlageAmountMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemContractsItemUmlageAmountMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemContractsItemSkontoAmountMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemContractsItemSkontoAmountMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemContractsItemAttachmentsItemNameMax = 512
export const projectsControllerFindAllResponseDataItemContractsItemAttachmentsItemPathMax = 512
export const projectsControllerFindAllResponseDataItemContractsItemAttachmentsItemMimeTypeMax = 255
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerFirstNameMax = 100
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerLastNameMax = 100
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemPerHourSalaryMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemMonthlyDurationDaysMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemMonthlyDurationDaysMax = 365
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemMonthlyDurationHoursMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemMonthlyDurationHoursMax = 23
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemMonthlyDurationMinutesMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemMonthlyDurationMinutesMax = 59
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemMonthlyDurationMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemMonthlyDurationMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemGrossSalaryMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemGrossSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemVacationPerMonthDaysMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemVacationPerMonthDaysMax = 365
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemVacationPerMonthHoursMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemVacationPerMonthHoursMax = 23
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemVacationPerMonthMinutesMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemVacationPerMonthMinutesMax = 59
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemVacationPerMonthMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemVacationPerMonthMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemAttachmentsItemNameMax = 512
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemAttachmentsItemPathMax = 512
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemAttachmentsItemMimeTypeMax = 255
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemPreviousSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemPreviousSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemPreviousPerHourSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemPreviousPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemSalaryDiffMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemSalaryDiffMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractPerHourSalaryMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractMonthlyDurationDaysMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractMonthlyDurationDaysMax = 365
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractMonthlyDurationHoursMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractMonthlyDurationHoursMax = 23
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractMonthlyDurationMinutesMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractMonthlyDurationMinutesMax = 59
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractMonthlyDurationMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractMonthlyDurationMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractGrossSalaryMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractGrossSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractVacationPerMonthDaysMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractVacationPerMonthDaysMax = 365
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractVacationPerMonthHoursMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractVacationPerMonthHoursMax = 23
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractVacationPerMonthMinutesMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractVacationPerMonthMinutesMax = 59
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractVacationPerMonthMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractVacationPerMonthMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractAttachmentsItemNameMax = 512
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractAttachmentsItemPathMax = 512
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractAttachmentsItemMimeTypeMax = 255
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractPreviousSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractPreviousSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractPreviousPerHourSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractPreviousPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractSalaryDiffMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractSalaryDiffMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemPerHourMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemPerHourMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemMonthlyDurationDaysMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemMonthlyDurationDaysMax = 365
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemMonthlyDurationHoursMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemMonthlyDurationHoursMax = 23
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemMonthlyDurationMinutesMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemMonthlyDurationMinutesMax = 59
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemMonthlyDurationMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemMonthlyDurationMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemBaseMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemBaseMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemBonusMin =
  -100

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemBonusMax = 100
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemPaidMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemPaidMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerLeaveTypesItemNameMax = 255
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerLeaveTypesItemDescriptionMax = 1024
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerLeaveTypesItemColorMin = 7

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerLeaveTypesItemColorMax = 7

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerLeaveTypesItemColorRegExp =
  new RegExp('^#?([0-9A-F]{3}|[0-9A-F]{4}|[0-9A-F]{6}|[0-9A-F]{8})$')
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerLeaveTypesItemAllowedPerYearDaysMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerLeaveTypesItemAllowedPerYearDaysMax = 365
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerLeaveTypesItemAllowedPerYearHoursMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerLeaveTypesItemAllowedPerYearHoursMax = 23
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerLeaveTypesItemAllowedPerYearMinutesMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerLeaveTypesItemAllowedPerYearMinutesMax = 59
export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerLeaveTypesItemAllowedPerYearMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerLeaveTypesItemAllowedPerYearMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerCompanySerialMax = 50
export const projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerCompanyNameMax = 100
export const projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerCompanyAddressLatMin =
  -90

export const projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerCompanyAddressLatMax = 90
export const projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerCompanyAddressLonMin =
  -180

export const projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerCompanyAddressLonMax = 180
export const projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerCompanyTagNameMax = 255
export const projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerCompanyTagAliasesItemNameAliasMax = 255
export const projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerCompanyRatingMax = 5
export const projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerCompanyMaxDistanceToProjectMin = 0
export const projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerCompanySubContractorCostItemTagNameMax = 255
export const projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerCompanySubContractorCostItemTagAliasesItemNameAliasMax = 255
export const projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerCompanySubContractorCostItemItemsItemUnitPriceGcPercentMin = 0

export const projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerCompanySubContractorCostItemItemsItemUnitPriceGcPercentMax = 100
export const projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerFirstNameMax = 100
export const projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerLastNameMax = 100
export const projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerAddressLatMin =
  -90

export const projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerAddressLatMax = 90
export const projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerAddressLonMin =
  -180

export const projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerAddressLonMax = 180
export const projectsControllerFindAllResponseDataItemClientContractPeopleItemCompanySerialMax = 50
export const projectsControllerFindAllResponseDataItemClientContractPeopleItemCompanyNameMax = 100
export const projectsControllerFindAllResponseDataItemClientContractPeopleItemCompanyAddressLatMin =
  -90

export const projectsControllerFindAllResponseDataItemClientContractPeopleItemCompanyAddressLatMax = 90
export const projectsControllerFindAllResponseDataItemClientContractPeopleItemCompanyAddressLonMin =
  -180

export const projectsControllerFindAllResponseDataItemClientContractPeopleItemCompanyAddressLonMax = 180
export const projectsControllerFindAllResponseDataItemClientContractPeopleItemCompanyTagNameMax = 255
export const projectsControllerFindAllResponseDataItemClientContractPeopleItemCompanyTagAliasesItemNameAliasMax = 255
export const projectsControllerFindAllResponseDataItemClientContractPeopleItemCompanyRatingMax = 5
export const projectsControllerFindAllResponseDataItemClientContractPeopleItemCompanyMaxDistanceToProjectMin = 0
export const projectsControllerFindAllResponseDataItemClientContractPeopleItemCompanySubContractorCostItemTagNameMax = 255
export const projectsControllerFindAllResponseDataItemClientContractPeopleItemCompanySubContractorCostItemTagAliasesItemNameAliasMax = 255
export const projectsControllerFindAllResponseDataItemClientContractPeopleItemCompanySubContractorCostItemItemsItemUnitPriceGcPercentMin = 0

export const projectsControllerFindAllResponseDataItemClientContractPeopleItemCompanySubContractorCostItemItemsItemUnitPriceGcPercentMax = 100
export const projectsControllerFindAllResponseDataItemClientContractPeopleItemFirstNameMax = 100
export const projectsControllerFindAllResponseDataItemClientContractPeopleItemLastNameMax = 100
export const projectsControllerFindAllResponseDataItemClientContractPeopleItemAddressLatMin =
  -90

export const projectsControllerFindAllResponseDataItemClientContractPeopleItemAddressLatMax = 90
export const projectsControllerFindAllResponseDataItemClientContractPeopleItemAddressLonMin =
  -180

export const projectsControllerFindAllResponseDataItemClientContractPeopleItemAddressLonMax = 180
export const projectsControllerFindAllResponseDataItemClientContractMinNumberOfWorkersOnSiteMin = 0

export const projectsControllerFindAllResponseDataItemClientContractMinNumberOfWorkersOnSiteMax = 32767
export const projectsControllerFindAllResponseDataItemClientContractMaxNumberOfWorkersOnSiteMin = 0
export const projectsControllerFindAllResponseDataItemClientContractCompanySerialMax = 50
export const projectsControllerFindAllResponseDataItemClientContractCompanyNameMax = 100
export const projectsControllerFindAllResponseDataItemClientContractCompanyAddressLatMin =
  -90

export const projectsControllerFindAllResponseDataItemClientContractCompanyAddressLatMax = 90
export const projectsControllerFindAllResponseDataItemClientContractCompanyAddressLonMin =
  -180

export const projectsControllerFindAllResponseDataItemClientContractCompanyAddressLonMax = 180
export const projectsControllerFindAllResponseDataItemClientContractCompanyTagNameMax = 255
export const projectsControllerFindAllResponseDataItemClientContractCompanyTagAliasesItemNameAliasMax = 255
export const projectsControllerFindAllResponseDataItemClientContractCompanyRatingMax = 5
export const projectsControllerFindAllResponseDataItemClientContractCompanyMaxDistanceToProjectMin = 0
export const projectsControllerFindAllResponseDataItemClientContractCompanySubContractorCostItemTagNameMax = 255
export const projectsControllerFindAllResponseDataItemClientContractCompanySubContractorCostItemTagAliasesItemNameAliasMax = 255
export const projectsControllerFindAllResponseDataItemClientContractCompanySubContractorCostItemItemsItemUnitPriceGcPercentMin = 0

export const projectsControllerFindAllResponseDataItemClientContractCompanySubContractorCostItemItemsItemUnitPriceGcPercentMax = 100
export const projectsControllerFindAllResponseDataItemClientContractNetPriceMin = 0

export const projectsControllerFindAllResponseDataItemClientContractNetPriceMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemClientContractTagNameMax = 255
export const projectsControllerFindAllResponseDataItemClientContractTagAliasesItemNameAliasMax = 255
export const projectsControllerFindAllResponseDataItemClientContractWarrantyReserveMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemClientContractWarrantyReserveMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemClientContractUmlageAmountMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemClientContractUmlageAmountMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemClientContractSkontoAmountMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemClientContractSkontoAmountMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemClientContractAttachmentsItemNameMax = 512
export const projectsControllerFindAllResponseDataItemClientContractAttachmentsItemPathMax = 512
export const projectsControllerFindAllResponseDataItemClientContractAttachmentsItemMimeTypeMax = 255
export const projectsControllerFindAllResponseDataItemProjectAssistantFirstNameMax = 100
export const projectsControllerFindAllResponseDataItemProjectAssistantLastNameMax = 100
export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemPerHourSalaryMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemMonthlyDurationDaysMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemMonthlyDurationDaysMax = 365
export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemMonthlyDurationHoursMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemMonthlyDurationHoursMax = 23
export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemMonthlyDurationMinutesMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemMonthlyDurationMinutesMax = 59
export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemMonthlyDurationMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemMonthlyDurationMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemGrossSalaryMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemGrossSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemVacationPerMonthDaysMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemVacationPerMonthDaysMax = 365
export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemVacationPerMonthHoursMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemVacationPerMonthHoursMax = 23
export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemVacationPerMonthMinutesMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemVacationPerMonthMinutesMax = 59
export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemVacationPerMonthMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemVacationPerMonthMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemAttachmentsItemNameMax = 512
export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemAttachmentsItemPathMax = 512
export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemAttachmentsItemMimeTypeMax = 255
export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemPreviousSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemPreviousSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemPreviousPerHourSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemPreviousPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemSalaryDiffMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemProjectAssistantContractsItemSalaryDiffMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractPerHourSalaryMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractMonthlyDurationDaysMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractMonthlyDurationDaysMax = 365
export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractMonthlyDurationHoursMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractMonthlyDurationHoursMax = 23
export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractMonthlyDurationMinutesMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractMonthlyDurationMinutesMax = 59
export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractMonthlyDurationMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractMonthlyDurationMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractGrossSalaryMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractGrossSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractVacationPerMonthDaysMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractVacationPerMonthDaysMax = 365
export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractVacationPerMonthHoursMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractVacationPerMonthHoursMax = 23
export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractVacationPerMonthMinutesMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractVacationPerMonthMinutesMax = 59
export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractVacationPerMonthMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractVacationPerMonthMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractAttachmentsItemNameMax = 512
export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractAttachmentsItemPathMax = 512
export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractAttachmentsItemMimeTypeMax = 255
export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractPreviousSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractPreviousSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractPreviousPerHourSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractPreviousPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractSalaryDiffMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractSalaryDiffMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemPerHourMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemPerHourMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemMonthlyDurationDaysMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemMonthlyDurationDaysMax = 365
export const projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemMonthlyDurationHoursMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemMonthlyDurationHoursMax = 23
export const projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemMonthlyDurationMinutesMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemMonthlyDurationMinutesMax = 59
export const projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemMonthlyDurationMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemMonthlyDurationMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemBaseMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemBaseMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemBonusMin =
  -100

export const projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemBonusMax = 100
export const projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemPaidMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemPaidMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemProjectAssistantLeaveTypesItemNameMax = 255
export const projectsControllerFindAllResponseDataItemProjectAssistantLeaveTypesItemDescriptionMax = 1024
export const projectsControllerFindAllResponseDataItemProjectAssistantLeaveTypesItemColorMin = 7

export const projectsControllerFindAllResponseDataItemProjectAssistantLeaveTypesItemColorMax = 7

export const projectsControllerFindAllResponseDataItemProjectAssistantLeaveTypesItemColorRegExp =
  new RegExp('^#?([0-9A-F]{3}|[0-9A-F]{4}|[0-9A-F]{6}|[0-9A-F]{8})$')
export const projectsControllerFindAllResponseDataItemProjectAssistantLeaveTypesItemAllowedPerYearDaysMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantLeaveTypesItemAllowedPerYearDaysMax = 365
export const projectsControllerFindAllResponseDataItemProjectAssistantLeaveTypesItemAllowedPerYearHoursMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantLeaveTypesItemAllowedPerYearHoursMax = 23
export const projectsControllerFindAllResponseDataItemProjectAssistantLeaveTypesItemAllowedPerYearMinutesMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantLeaveTypesItemAllowedPerYearMinutesMax = 59
export const projectsControllerFindAllResponseDataItemProjectAssistantLeaveTypesItemAllowedPerYearMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemProjectAssistantLeaveTypesItemAllowedPerYearMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemAllocationSummaryParamsTargetMin = 0

export const projectsControllerFindAllResponseDataItemAllocationSummaryParamsTargetMax = 32767
export const projectsControllerFindAllResponseDataItemAllocationSummaryParamsMultipleFactorMin = 0
export const projectsControllerFindAllResponseDataItemAllocationSummaryTotalContractMin = 0

export const projectsControllerFindAllResponseDataItemAllocationSummaryTotalContractMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationSummaryTotalAllocationMin = 0

export const projectsControllerFindAllResponseDataItemAllocationSummaryTotalAllocationMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationSummarySignedContractsPercentMin = 0

export const projectsControllerFindAllResponseDataItemAllocationSummarySignedContractsPercentMax = 100
export const projectsControllerFindAllResponseDataItemAllocationSummarySumBudgetMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationSummarySumBudgetMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationSummarySumPriceMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationSummarySumPriceMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationSummarySumTaxMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationSummarySumTaxMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationSummarySumDiscountMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationSummarySumDiscountMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationSummaryGAndAIncludingPmMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationSummaryGAndAIncludingPmMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationSummaryAdditionalCostsMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationSummaryAdditionalCostsMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationSummaryReducedCostsMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationSummaryReducedCostsMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationSummaryCostOfSupplementsMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationSummaryCostOfSupplementsMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationSummaryWarrantyReservePercentMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationSummaryWarrantyReservePercentMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationSummaryContractValueConstructionMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationSummaryContractValueConstructionMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationSummaryWarrantyReserveAmountMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationSummaryWarrantyReserveAmountMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationSummaryProfitLossMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationSummaryProfitLossMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationSummaryTargetMin = 0
export const projectsControllerFindAllResponseDataItemAllocationSummaryContractValueSumMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationSummaryContractValueSumMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationSummaryCostsIncludingGAndAAndWarrantyReserveMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationSummaryCostsIncludingGAndAAndWarrantyReserveMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationSummaryContributionMarginWithoutDiscountAndTaxAmountMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationSummaryContributionMarginWithoutDiscountAndTaxAmountMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationSummaryContributionMarginWithoutDiscountAndTaxPercentMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationSummaryContributionMarginWithoutDiscountAndTaxPercentMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationSummaryContributionMarginWithDiscountAndTaxAmountMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationSummaryContributionMarginWithDiscountAndTaxAmountMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemAllocationSummaryContributionMarginWithDiscountAndTaxPercentMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemAllocationSummaryContributionMarginWithDiscountAndTaxPercentMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserFirstNameMax = 100
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserLastNameMax = 100
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemPerHourSalaryMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemMonthlyDurationDaysMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemMonthlyDurationDaysMax = 365
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemMonthlyDurationHoursMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemMonthlyDurationHoursMax = 23
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemMonthlyDurationMinutesMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemMonthlyDurationMinutesMax = 59
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemMonthlyDurationMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemMonthlyDurationMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemGrossSalaryMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemGrossSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemVacationPerMonthDaysMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemVacationPerMonthDaysMax = 365
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemVacationPerMonthHoursMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemVacationPerMonthHoursMax = 23
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemVacationPerMonthMinutesMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemVacationPerMonthMinutesMax = 59
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemVacationPerMonthMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemVacationPerMonthMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemAttachmentsItemNameMax = 512
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemAttachmentsItemPathMax = 512
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemAttachmentsItemMimeTypeMax = 255
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemPreviousSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemPreviousSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemPreviousPerHourSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemPreviousPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemSalaryDiffMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemSalaryDiffMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractPerHourSalaryMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractMonthlyDurationDaysMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractMonthlyDurationDaysMax = 365
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractMonthlyDurationHoursMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractMonthlyDurationHoursMax = 23
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractMonthlyDurationMinutesMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractMonthlyDurationMinutesMax = 59
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractMonthlyDurationMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractMonthlyDurationMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractGrossSalaryMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractGrossSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractVacationPerMonthDaysMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractVacationPerMonthDaysMax = 365
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractVacationPerMonthHoursMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractVacationPerMonthHoursMax = 23
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractVacationPerMonthMinutesMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractVacationPerMonthMinutesMax = 59
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractVacationPerMonthMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractVacationPerMonthMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractAttachmentsItemNameMax = 512
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractAttachmentsItemPathMax = 512
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractAttachmentsItemMimeTypeMax = 255
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractPreviousSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractPreviousSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractPreviousPerHourSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractPreviousPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractSalaryDiffMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractSalaryDiffMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemPerHourMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemPerHourMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemMonthlyDurationDaysMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemMonthlyDurationDaysMax = 365
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemMonthlyDurationHoursMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemMonthlyDurationHoursMax = 23
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemMonthlyDurationMinutesMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemMonthlyDurationMinutesMax = 59
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemMonthlyDurationMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemMonthlyDurationMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemBaseMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemBaseMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemBonusMin =
  -100

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemBonusMax = 100
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemPaidMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemPaidMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserLeaveTypesItemNameMax = 255
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserLeaveTypesItemDescriptionMax = 1024
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserLeaveTypesItemColorMin = 7

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserLeaveTypesItemColorMax = 7

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserLeaveTypesItemColorRegExp =
  new RegExp('^#?([0-9A-F]{3}|[0-9A-F]{4}|[0-9A-F]{6}|[0-9A-F]{8})$')
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserLeaveTypesItemAllowedPerYearDaysMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserLeaveTypesItemAllowedPerYearDaysMax = 365
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserLeaveTypesItemAllowedPerYearHoursMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserLeaveTypesItemAllowedPerYearHoursMax = 23
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserLeaveTypesItemAllowedPerYearMinutesMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserLeaveTypesItemAllowedPerYearMinutesMax = 59
export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserLeaveTypesItemAllowedPerYearMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemUserLeaveTypesItemAllowedPerYearMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemSalaryCostsItemEmploymentRateMin = 0

export const projectsControllerFindAllResponseDataItemSalaryCostsItemEmploymentRateMax = 100
export const projectsControllerFindAllResponseDataItemSalaryCostsItemOrderIndexMax = 32767
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserFirstNameMax = 100
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserLastNameMax = 100
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemPerHourSalaryMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemMonthlyDurationDaysMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemMonthlyDurationDaysMax = 365
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemMonthlyDurationHoursMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemMonthlyDurationHoursMax = 23
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemMonthlyDurationMinutesMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemMonthlyDurationMinutesMax = 59
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemMonthlyDurationMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemMonthlyDurationMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemGrossSalaryMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemGrossSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemVacationPerMonthDaysMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemVacationPerMonthDaysMax = 365
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemVacationPerMonthHoursMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemVacationPerMonthHoursMax = 23
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemVacationPerMonthMinutesMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemVacationPerMonthMinutesMax = 59
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemVacationPerMonthMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemVacationPerMonthMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemAttachmentsItemNameMax = 512
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemAttachmentsItemPathMax = 512
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemAttachmentsItemMimeTypeMax = 255
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemPreviousSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemPreviousSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemPreviousPerHourSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemPreviousPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemSalaryDiffMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemSalaryDiffMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractPerHourSalaryMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractMonthlyDurationDaysMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractMonthlyDurationDaysMax = 365
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractMonthlyDurationHoursMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractMonthlyDurationHoursMax = 23
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractMonthlyDurationMinutesMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractMonthlyDurationMinutesMax = 59
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractMonthlyDurationMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractMonthlyDurationMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractGrossSalaryMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractGrossSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractVacationPerMonthDaysMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractVacationPerMonthDaysMax = 365
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractVacationPerMonthHoursMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractVacationPerMonthHoursMax = 23
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractVacationPerMonthMinutesMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractVacationPerMonthMinutesMax = 59
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractVacationPerMonthMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractVacationPerMonthMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractAttachmentsItemNameMax = 512
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractAttachmentsItemPathMax = 512
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractAttachmentsItemMimeTypeMax = 255
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractPreviousSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractPreviousSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractPreviousPerHourSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractPreviousPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractSalaryDiffMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractSalaryDiffMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemPerHourMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemPerHourMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemMonthlyDurationDaysMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemMonthlyDurationDaysMax = 365
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemMonthlyDurationHoursMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemMonthlyDurationHoursMax = 23
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemMonthlyDurationMinutesMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemMonthlyDurationMinutesMax = 59
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemMonthlyDurationMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemMonthlyDurationMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemBaseMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemBaseMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemBonusMin =
  -100

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemBonusMax = 100
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemPaidMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemPaidMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserLeaveTypesItemNameMax = 255
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserLeaveTypesItemDescriptionMax = 1024
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserLeaveTypesItemColorMin = 7

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserLeaveTypesItemColorMax = 7

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserLeaveTypesItemColorRegExp =
  new RegExp('^#?([0-9A-F]{3}|[0-9A-F]{4}|[0-9A-F]{6}|[0-9A-F]{8})$')
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserLeaveTypesItemAllowedPerYearDaysMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserLeaveTypesItemAllowedPerYearDaysMax = 365
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserLeaveTypesItemAllowedPerYearHoursMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserLeaveTypesItemAllowedPerYearHoursMax = 23
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserLeaveTypesItemAllowedPerYearMinutesMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserLeaveTypesItemAllowedPerYearMinutesMax = 59
export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserLeaveTypesItemAllowedPerYearMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemUserLeaveTypesItemAllowedPerYearMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemSalaryActualsItemEmploymentRateMin = 0

export const projectsControllerFindAllResponseDataItemSalaryActualsItemEmploymentRateMax = 100
export const projectsControllerFindAllResponseDataItemAdditionReduceCostItemTagNameMax = 255
export const projectsControllerFindAllResponseDataItemAdditionReduceCostItemTagAliasesItemNameAliasMax = 255
export const projectsControllerFindAllResponseDataItemAdditionReduceCostItemItemsItemUnitPriceGcPercentMin = 0

export const projectsControllerFindAllResponseDataItemAdditionReduceCostItemItemsItemUnitPriceGcPercentMax = 100
export const projectsControllerFindAllResponseDataItemActualTargetPercentItemValueMin = 0

export const projectsControllerFindAllResponseDataItemActualTargetPercentItemValueMax = 100
export const projectsControllerFindAllResponseDataItemGrossProfitPercentMin = 0
export const projectsControllerFindAllResponseDataItemFinancialPlansItemInvoiceNumberMax = 32767
export const projectsControllerFindAllResponseDataItemFinancialPlansItemTaxMax = 100
export const projectsControllerFindAllResponseDataItemTotalAllocationsMin = 0
export const projectsControllerFindAllResponseDataItemProjectManagersItemFirstNameMax = 100
export const projectsControllerFindAllResponseDataItemProjectManagersItemLastNameMax = 100
export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemPerHourSalaryMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemMonthlyDurationDaysMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemMonthlyDurationDaysMax = 365
export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemMonthlyDurationHoursMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemMonthlyDurationHoursMax = 23
export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemMonthlyDurationMinutesMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemMonthlyDurationMinutesMax = 59
export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemMonthlyDurationMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemMonthlyDurationMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemGrossSalaryMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemGrossSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemVacationPerMonthDaysMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemVacationPerMonthDaysMax = 365
export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemVacationPerMonthHoursMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemVacationPerMonthHoursMax = 23
export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemVacationPerMonthMinutesMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemVacationPerMonthMinutesMax = 59
export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemVacationPerMonthMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemVacationPerMonthMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemAttachmentsItemNameMax = 512
export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemAttachmentsItemPathMax = 512
export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemAttachmentsItemMimeTypeMax = 255
export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemPreviousSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemPreviousSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemPreviousPerHourSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemPreviousPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemSalaryDiffMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemSalaryDiffMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractPerHourSalaryMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractMonthlyDurationDaysMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractMonthlyDurationDaysMax = 365
export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractMonthlyDurationHoursMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractMonthlyDurationHoursMax = 23
export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractMonthlyDurationMinutesMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractMonthlyDurationMinutesMax = 59
export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractMonthlyDurationMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractMonthlyDurationMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractGrossSalaryMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractGrossSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractVacationPerMonthDaysMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractVacationPerMonthDaysMax = 365
export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractVacationPerMonthHoursMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractVacationPerMonthHoursMax = 23
export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractVacationPerMonthMinutesMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractVacationPerMonthMinutesMax = 59
export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractVacationPerMonthMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractVacationPerMonthMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractAttachmentsItemNameMax = 512
export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractAttachmentsItemPathMax = 512
export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractAttachmentsItemMimeTypeMax = 255
export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractPreviousSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractPreviousSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractPreviousPerHourSalaryMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractPreviousPerHourSalaryMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractSalaryDiffMin =
  -9007199254740991

export const projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractSalaryDiffMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemPerHourMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemPerHourMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemMonthlyDurationDaysMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemMonthlyDurationDaysMax = 365
export const projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemMonthlyDurationHoursMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemMonthlyDurationHoursMax = 23
export const projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemMonthlyDurationMinutesMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemMonthlyDurationMinutesMax = 59
export const projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemMonthlyDurationMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemMonthlyDurationMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemBaseMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemBaseMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemBonusMin =
  -100

export const projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemBonusMax = 100
export const projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemPaidMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemPaidMax = 9007199254740991
export const projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemNameMax = 255
export const projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemDescriptionMax = 1024
export const projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemColorMin = 7

export const projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemColorMax = 7

export const projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemColorRegExp =
  new RegExp('^#?([0-9A-F]{3}|[0-9A-F]{4}|[0-9A-F]{6}|[0-9A-F]{8})$')
export const projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemAllowedPerYearDaysMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemAllowedPerYearDaysMax = 365
export const projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemAllowedPerYearHoursMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemAllowedPerYearHoursMax = 23
export const projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemAllowedPerYearMinutesMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemAllowedPerYearMinutesMax = 59
export const projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemAllowedPerYearMillisecondsMin = 0

export const projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemAllowedPerYearMillisecondsMax = 999
export const projectsControllerFindAllResponseDataItemAttachmentsItemNameMax = 512
export const projectsControllerFindAllResponseDataItemAttachmentsItemPathMax = 512
export const projectsControllerFindAllResponseDataItemAttachmentsItemMimeTypeMax = 255
export const projectsControllerFindAllResponseDataItemAddressLatMin = -90

export const projectsControllerFindAllResponseDataItemAddressLatMax = 90
export const projectsControllerFindAllResponseDataItemAddressLonMin = -180

export const projectsControllerFindAllResponseDataItemAddressLonMax = 180

export const projectsControllerFindAllResponse = zod
  .object({
    data: zod.array(zod.object({})),
    meta: zod.object({
      itemsPerPage: zod.coerce.number(),
      totalItems: zod.coerce.number(),
      currentPage: zod.coerce.number(),
      totalPages: zod.coerce.number(),
      sortBy: zod
        .array(zod.array(zod.coerce.string().or(zod.enum(['ASC', 'DESC']))))
        .optional(),
      searchBy: zod.array(zod.coerce.string()).optional(),
      search: zod.coerce.string().optional(),
      select: zod.array(zod.coerce.string()).optional(),
      filter: zod.object({}),
    }),
    links: zod.object({
      first: zod.coerce.string().optional(),
      previous: zod.coerce.string().optional(),
      current: zod.coerce.string().optional(),
      next: zod.coerce.string().optional(),
      last: zod.coerce.string().optional(),
    }),
  })
  .and(
    zod.object({
      data: zod
        .array(
          zod.object({
            serial: zod.coerce
              .string()
              .max(projectsControllerFindAllResponseDataItemSerialMax)
              .optional(),
            projectSize: zod.coerce.number(),
            workingFloor: zod.coerce.number().optional(),
            buildingGrossFloor: zod.coerce.number(),
            buildingReferenceIndex: zod.coerce.number(),
            guaranteedUntil: zod.coerce.date().optional(),
            buildingType: zod.object({
              name: zod.coerce.string(),
              uuid: zod.coerce.string(),
            }),
            startedAt: zod.coerce.date().optional(),
            estimatedEndAt: zod.coerce.date().optional(),
            endAt: zod.coerce.date().optional(),
            status: zod.enum(['Active', 'In Active', 'Potential']).optional(),
            allocations: zod
              .array(
                zod.object({
                  project: zod.any(),
                  isProjectEditable: zod.coerce.boolean().optional(),
                  company: zod
                    .object({
                      uuid: zod.coerce.string().uuid(),
                      serial: zod.coerce
                        .string()
                        .max(
                          projectsControllerFindAllResponseDataItemAllocationsItemCompanySerialMax
                        )
                        .nullish(),
                      name: zod.coerce
                        .string()
                        .max(
                          projectsControllerFindAllResponseDataItemAllocationsItemCompanyNameMax
                        ),
                      address: zod
                        .object({
                          uuid: zod.coerce.string().uuid(),
                          country: zod.coerce.string().optional(),
                          city: zod.coerce.string().nullish(),
                          street: zod.coerce.string().nullish(),
                          postcode: zod.coerce.string().nullish(),
                          lat: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemAllocationsItemCompanyAddressLatMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemCompanyAddressLatMax
                            )
                            .nullish(),
                          lon: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemAllocationsItemCompanyAddressLonMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemCompanyAddressLonMax
                            )
                            .nullish(),
                        })
                        .nullish(),
                      phoneNumber: zod.coerce.string().nullish(),
                      email: zod.coerce.string().email().nullish(),
                      website: zod.coerce.string().url().nullable(),
                      logo: zod.coerce.string().optional(),
                      companyType: zod.enum([
                        'Client',
                        'Contractor',
                        'Purchase',
                        'Surcharge',
                        'Creditor',
                      ]),
                      tag: zod
                        .object({
                          uuid: zod.coerce.string().uuid(),
                          parent: zod.any().nullish(),
                          children: zod.array(zod.any()).optional(),
                          name: zod.coerce
                            .string()
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemCompanyTagNameMax
                            ),
                          companyType: zod.enum([
                            'Client',
                            'Contractor',
                            'Purchase',
                            'Surcharge',
                            'Creditor',
                          ]),
                          serial: zod.coerce.string().optional(),
                          priority: zod.coerce.number().nullish(),
                          aliases: zod
                            .array(
                              zod.object({
                                uuid: zod.coerce.string().uuid(),
                                isAbbr: zod.coerce.boolean(),
                                tag: zod.any(),
                                nameAlias: zod.coerce
                                  .string()
                                  .min(1)
                                  .max(
                                    projectsControllerFindAllResponseDataItemAllocationsItemCompanyTagAliasesItemNameAliasMax
                                  ),
                                language: zod.coerce.string().optional(),
                              })
                            )
                            .optional(),
                        })
                        .nullish(),
                      rating: zod.coerce
                        .number()
                        .min(1)
                        .max(
                          projectsControllerFindAllResponseDataItemAllocationsItemCompanyRatingMax
                        )
                        .nullish(),
                      companySize: zod.coerce.number().min(1).nullish(),
                      maxDistanceToProject: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemAllocationsItemCompanyMaxDistanceToProjectMin
                        )
                        .optional(),
                      createdAt: zod.coerce.date(),
                      updatedAt: zod.coerce.date(),
                      contracts: zod
                        .array(
                          zod.object({
                            uuid: zod.coerce.string().uuid(),
                            description: zod.coerce.string().nullable(),
                            project: zod.any(),
                            internalProjectManager: zod
                              .object({
                                uuid: zod.coerce.string().uuid(),
                                email: zod.coerce.string().email(),
                                firstName: zod.coerce
                                  .string()
                                  .max(
                                    projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerFirstNameMax
                                  ),
                                lastName: zod.coerce
                                  .string()
                                  .max(
                                    projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerLastNameMax
                                  ),
                                privatePhoneNumber: zod.coerce
                                  .string()
                                  .optional(),
                                workPhoneNumber: zod.coerce.string().optional(),
                                status: zod.enum(['Active', 'Terminated']),
                                permissions: zod
                                  .array(
                                    zod.enum([
                                      'admin',
                                      'human-resource',
                                      'user',
                                    ])
                                  )
                                  .optional(),
                                createdAt: zod.coerce.date(),
                                updatedAt: zod.coerce.date(),
                                contracts: zod.array(
                                  zod.object({
                                    uuid: zod.coerce.string().uuid(),
                                    user: zod.any(),
                                    role: zod.object({
                                      uuid: zod.coerce.string().uuid(),
                                      name: zod.coerce.string(),
                                    }),
                                    department: zod.object({
                                      uuid: zod.coerce.string().uuid(),
                                      name: zod.coerce.string(),
                                    }),
                                    perHourSalary: zod.coerce
                                      .number()
                                      .min(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemPerHourSalaryMin
                                      )
                                      .max(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemPerHourSalaryMax
                                      )
                                      .optional(),
                                    monthlyDuration: zod
                                      .object({
                                        days: zod.coerce
                                          .number()
                                          .min(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemMonthlyDurationDaysMin
                                          )
                                          .max(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemMonthlyDurationDaysMax
                                          )
                                          .optional(),
                                        hours: zod.coerce
                                          .number()
                                          .min(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemMonthlyDurationHoursMin
                                          )
                                          .max(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemMonthlyDurationHoursMax
                                          )
                                          .optional(),
                                        minutes: zod.coerce
                                          .number()
                                          .min(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemMonthlyDurationMinutesMin
                                          )
                                          .max(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemMonthlyDurationMinutesMax
                                          )
                                          .optional(),
                                        seconds: zod.coerce.number().optional(),
                                        milliseconds: zod.coerce
                                          .number()
                                          .min(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemMonthlyDurationMillisecondsMin
                                          )
                                          .max(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemMonthlyDurationMillisecondsMax
                                          )
                                          .optional(),
                                      })
                                      .optional(),
                                    grossSalary: zod.coerce
                                      .number()
                                      .min(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemGrossSalaryMin
                                      )
                                      .max(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemGrossSalaryMax
                                      ),
                                    type: zod.enum([
                                      'full-time',
                                      'part-time',
                                      'hourly',
                                    ]),
                                    startDate: zod.coerce.date(),
                                    endDate: zod.coerce.date().optional(),
                                    vacationPerMonth: zod.object({
                                      days: zod.coerce
                                        .number()
                                        .min(
                                          projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemVacationPerMonthDaysMin
                                        )
                                        .max(
                                          projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemVacationPerMonthDaysMax
                                        )
                                        .optional(),
                                      hours: zod.coerce
                                        .number()
                                        .min(
                                          projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemVacationPerMonthHoursMin
                                        )
                                        .max(
                                          projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemVacationPerMonthHoursMax
                                        )
                                        .optional(),
                                      minutes: zod.coerce
                                        .number()
                                        .min(
                                          projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemVacationPerMonthMinutesMin
                                        )
                                        .max(
                                          projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemVacationPerMonthMinutesMax
                                        )
                                        .optional(),
                                      seconds: zod.coerce.number().optional(),
                                      milliseconds: zod.coerce
                                        .number()
                                        .min(
                                          projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemVacationPerMonthMillisecondsMin
                                        )
                                        .max(
                                          projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemVacationPerMonthMillisecondsMax
                                        )
                                        .optional(),
                                    }),
                                    attachments: zod.array(
                                      zod.object({
                                        name: zod.coerce
                                          .string()
                                          .max(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemAttachmentsItemNameMax
                                          ),
                                        path: zod.coerce
                                          .string()
                                          .max(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemAttachmentsItemPathMax
                                          ),
                                        mimeType: zod.coerce
                                          .string()
                                          .max(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemAttachmentsItemMimeTypeMax
                                          )
                                          .optional(),
                                        size: zod.coerce.number().optional(),
                                        expireAt: zod.coerce.date().optional(),
                                        fileUrl: zod.coerce.string(),
                                        uuid: zod.coerce.string(),
                                        createdAt: zod.coerce.date(),
                                        updatedAt: zod.coerce.date(),
                                      })
                                    ),
                                    previousSalary: zod.coerce
                                      .number()
                                      .min(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemPreviousSalaryMin
                                      )
                                      .max(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemPreviousSalaryMax
                                      ),
                                    previousPerHourSalary: zod.coerce
                                      .number()
                                      .min(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemPreviousPerHourSalaryMin
                                      )
                                      .max(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemPreviousPerHourSalaryMax
                                      ),
                                    salaryDiff: zod.coerce
                                      .number()
                                      .min(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemSalaryDiffMin
                                      )
                                      .max(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerContractsItemSalaryDiffMax
                                      ),
                                    isTypeChanged: zod.coerce.boolean(),
                                    previousContractType: zod
                                      .enum([
                                        'full-time',
                                        'part-time',
                                        'hourly',
                                      ])
                                      .optional(),
                                    createdAt: zod.coerce.date(),
                                    updatedAt: zod.coerce.date(),
                                  })
                                ),
                                currentContract: zod
                                  .object({
                                    uuid: zod.coerce.string().uuid(),
                                    user: zod.any(),
                                    role: zod.object({
                                      uuid: zod.coerce.string().uuid(),
                                      name: zod.coerce.string(),
                                    }),
                                    department: zod.object({
                                      uuid: zod.coerce.string().uuid(),
                                      name: zod.coerce.string(),
                                    }),
                                    perHourSalary: zod.coerce
                                      .number()
                                      .min(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractPerHourSalaryMin
                                      )
                                      .max(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractPerHourSalaryMax
                                      )
                                      .optional(),
                                    monthlyDuration: zod
                                      .object({
                                        days: zod.coerce
                                          .number()
                                          .min(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractMonthlyDurationDaysMin
                                          )
                                          .max(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractMonthlyDurationDaysMax
                                          )
                                          .optional(),
                                        hours: zod.coerce
                                          .number()
                                          .min(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractMonthlyDurationHoursMin
                                          )
                                          .max(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractMonthlyDurationHoursMax
                                          )
                                          .optional(),
                                        minutes: zod.coerce
                                          .number()
                                          .min(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractMonthlyDurationMinutesMin
                                          )
                                          .max(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractMonthlyDurationMinutesMax
                                          )
                                          .optional(),
                                        seconds: zod.coerce.number().optional(),
                                        milliseconds: zod.coerce
                                          .number()
                                          .min(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractMonthlyDurationMillisecondsMin
                                          )
                                          .max(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractMonthlyDurationMillisecondsMax
                                          )
                                          .optional(),
                                      })
                                      .optional(),
                                    grossSalary: zod.coerce
                                      .number()
                                      .min(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractGrossSalaryMin
                                      )
                                      .max(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractGrossSalaryMax
                                      ),
                                    type: zod.enum([
                                      'full-time',
                                      'part-time',
                                      'hourly',
                                    ]),
                                    startDate: zod.coerce.date(),
                                    endDate: zod.coerce.date().optional(),
                                    vacationPerMonth: zod.object({
                                      days: zod.coerce
                                        .number()
                                        .min(
                                          projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractVacationPerMonthDaysMin
                                        )
                                        .max(
                                          projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractVacationPerMonthDaysMax
                                        )
                                        .optional(),
                                      hours: zod.coerce
                                        .number()
                                        .min(
                                          projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractVacationPerMonthHoursMin
                                        )
                                        .max(
                                          projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractVacationPerMonthHoursMax
                                        )
                                        .optional(),
                                      minutes: zod.coerce
                                        .number()
                                        .min(
                                          projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractVacationPerMonthMinutesMin
                                        )
                                        .max(
                                          projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractVacationPerMonthMinutesMax
                                        )
                                        .optional(),
                                      seconds: zod.coerce.number().optional(),
                                      milliseconds: zod.coerce
                                        .number()
                                        .min(
                                          projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractVacationPerMonthMillisecondsMin
                                        )
                                        .max(
                                          projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractVacationPerMonthMillisecondsMax
                                        )
                                        .optional(),
                                    }),
                                    attachments: zod.array(
                                      zod.object({
                                        name: zod.coerce
                                          .string()
                                          .max(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractAttachmentsItemNameMax
                                          ),
                                        path: zod.coerce
                                          .string()
                                          .max(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractAttachmentsItemPathMax
                                          ),
                                        mimeType: zod.coerce
                                          .string()
                                          .max(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractAttachmentsItemMimeTypeMax
                                          )
                                          .optional(),
                                        size: zod.coerce.number().optional(),
                                        expireAt: zod.coerce.date().optional(),
                                        fileUrl: zod.coerce.string(),
                                        uuid: zod.coerce.string(),
                                        createdAt: zod.coerce.date(),
                                        updatedAt: zod.coerce.date(),
                                      })
                                    ),
                                    previousSalary: zod.coerce
                                      .number()
                                      .min(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractPreviousSalaryMin
                                      )
                                      .max(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractPreviousSalaryMax
                                      ),
                                    previousPerHourSalary: zod.coerce
                                      .number()
                                      .min(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractPreviousPerHourSalaryMin
                                      )
                                      .max(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractPreviousPerHourSalaryMax
                                      ),
                                    salaryDiff: zod.coerce
                                      .number()
                                      .min(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractSalaryDiffMin
                                      )
                                      .max(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerCurrentContractSalaryDiffMax
                                      ),
                                    isTypeChanged: zod.coerce.boolean(),
                                    previousContractType: zod
                                      .enum([
                                        'full-time',
                                        'part-time',
                                        'hourly',
                                      ])
                                      .optional(),
                                    createdAt: zod.coerce.date(),
                                    updatedAt: zod.coerce.date(),
                                  })
                                  .optional(),
                                salaries: zod.array(
                                  zod.object({
                                    uuid: zod.coerce.string().uuid(),
                                    user: zod.any(),
                                    date: zod.coerce.date(),
                                    paymentDate: zod.coerce.date().optional(),
                                    perHour: zod.coerce
                                      .number()
                                      .min(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemPerHourMin
                                      )
                                      .max(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemPerHourMax
                                      )
                                      .optional(),
                                    monthlyDuration: zod
                                      .object({
                                        days: zod.coerce
                                          .number()
                                          .min(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemMonthlyDurationDaysMin
                                          )
                                          .max(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemMonthlyDurationDaysMax
                                          )
                                          .optional(),
                                        hours: zod.coerce
                                          .number()
                                          .min(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemMonthlyDurationHoursMin
                                          )
                                          .max(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemMonthlyDurationHoursMax
                                          )
                                          .optional(),
                                        minutes: zod.coerce
                                          .number()
                                          .min(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemMonthlyDurationMinutesMin
                                          )
                                          .max(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemMonthlyDurationMinutesMax
                                          )
                                          .optional(),
                                        seconds: zod.coerce.number().optional(),
                                        milliseconds: zod.coerce
                                          .number()
                                          .min(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemMonthlyDurationMillisecondsMin
                                          )
                                          .max(
                                            projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemMonthlyDurationMillisecondsMax
                                          )
                                          .optional(),
                                      })
                                      .optional(),
                                    base: zod.coerce
                                      .number()
                                      .min(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemBaseMin
                                      )
                                      .max(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemBaseMax
                                      ),
                                    bonus: zod.coerce
                                      .number()
                                      .min(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemBonusMin
                                      )
                                      .max(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemBonusMax
                                      )
                                      .optional(),
                                    paid: zod.coerce
                                      .number()
                                      .min(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemPaidMin
                                      )
                                      .max(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerSalariesItemPaidMax
                                      ),
                                    type: zod.enum([
                                      'full-time',
                                      'part-time',
                                      'hourly',
                                    ]),
                                    role: zod.object({
                                      uuid: zod.coerce.string().uuid(),
                                      name: zod.coerce.string(),
                                    }),
                                    department: zod.object({
                                      uuid: zod.coerce.string().uuid(),
                                      name: zod.coerce.string(),
                                    }),
                                    createdAt: zod.coerce.date(),
                                    updatedAt: zod.coerce.date(),
                                    isMultiContract: zod.coerce.boolean(),
                                  })
                                ),
                                hireDate: zod.coerce.date().optional(),
                                profilePicture: zod.coerce.string(),
                                fullName: zod.coerce.string(),
                                leaveTypes: zod.array(
                                  zod.object({
                                    uuid: zod.coerce.string().uuid(),
                                    name: zod.coerce
                                      .string()
                                      .min(1)
                                      .max(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerLeaveTypesItemNameMax
                                      ),
                                    description: zod.coerce
                                      .string()
                                      .max(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerLeaveTypesItemDescriptionMax
                                      )
                                      .optional(),
                                    color: zod.coerce
                                      .string()
                                      .min(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerLeaveTypesItemColorMin
                                      )
                                      .max(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerLeaveTypesItemColorMax
                                      )
                                      .regex(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerLeaveTypesItemColorRegExp
                                      )
                                      .optional(),
                                    allowedPerYear: zod.object({
                                      days: zod.coerce
                                        .number()
                                        .min(
                                          projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearDaysMin
                                        )
                                        .max(
                                          projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearDaysMax
                                        )
                                        .optional(),
                                      hours: zod.coerce
                                        .number()
                                        .min(
                                          projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearHoursMin
                                        )
                                        .max(
                                          projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearHoursMax
                                        )
                                        .optional(),
                                      minutes: zod.coerce
                                        .number()
                                        .min(
                                          projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearMinutesMin
                                        )
                                        .max(
                                          projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearMinutesMax
                                        )
                                        .optional(),
                                      seconds: zod.coerce.number().optional(),
                                      milliseconds: zod.coerce
                                        .number()
                                        .min(
                                          projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearMillisecondsMin
                                        )
                                        .max(
                                          projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearMillisecondsMax
                                        )
                                        .optional(),
                                    }),
                                    isFreebie: zod.coerce.boolean().optional(),
                                    isWithAttachment: zod.coerce
                                      .boolean()
                                      .optional(),
                                  })
                                ),
                              })
                              .nullable(),
                            externalProjectManager: zod
                              .object({
                                company: zod.any().optional(),
                                contracts: zod.array(zod.any()).optional(),
                                firstName: zod.coerce
                                  .string()
                                  .max(
                                    projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemExternalProjectManagerFirstNameMax
                                  ),
                                lastName: zod.coerce
                                  .string()
                                  .max(
                                    projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemExternalProjectManagerLastNameMax
                                  ),
                                phoneNumber: zod.coerce.string().nullable(),
                                email: zod.coerce.string().email().nullable(),
                                description: zod.coerce.string(),
                                website: zod.coerce.string().url().nullable(),
                                fullName: zod.coerce.string(),
                                profilePicture: zod.coerce.string(),
                                relatedProjects: zod.array(zod.any()),
                                uuid: zod.coerce.string().uuid(),
                                address: zod.object({
                                  uuid: zod.coerce.string().uuid(),
                                  country: zod.coerce.string().optional(),
                                  city: zod.coerce.string().nullish(),
                                  street: zod.coerce.string().nullish(),
                                  postcode: zod.coerce.string().nullish(),
                                  lat: zod.coerce
                                    .number()
                                    .min(
                                      projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemExternalProjectManagerAddressLatMin
                                    )
                                    .max(
                                      projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemExternalProjectManagerAddressLatMax
                                    )
                                    .nullish(),
                                  lon: zod.coerce
                                    .number()
                                    .min(
                                      projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemExternalProjectManagerAddressLonMin
                                    )
                                    .max(
                                      projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemExternalProjectManagerAddressLonMax
                                    )
                                    .nullish(),
                                }),
                                createdAt: zod.coerce.date(),
                                updatedAt: zod.coerce.date(),
                              })
                              .nullable(),
                            people: zod
                              .array(
                                zod.object({
                                  company: zod.any().optional(),
                                  contracts: zod.array(zod.any()).optional(),
                                  firstName: zod.coerce
                                    .string()
                                    .max(
                                      projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemPeopleItemFirstNameMax
                                    ),
                                  lastName: zod.coerce
                                    .string()
                                    .max(
                                      projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemPeopleItemLastNameMax
                                    ),
                                  phoneNumber: zod.coerce.string().nullable(),
                                  email: zod.coerce.string().email().nullable(),
                                  description: zod.coerce.string(),
                                  website: zod.coerce.string().url().nullable(),
                                  fullName: zod.coerce.string(),
                                  profilePicture: zod.coerce.string(),
                                  relatedProjects: zod.array(zod.any()),
                                  uuid: zod.coerce.string().uuid(),
                                  address: zod.object({
                                    uuid: zod.coerce.string().uuid(),
                                    country: zod.coerce.string().optional(),
                                    city: zod.coerce.string().nullish(),
                                    street: zod.coerce.string().nullish(),
                                    postcode: zod.coerce.string().nullish(),
                                    lat: zod.coerce
                                      .number()
                                      .min(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemPeopleItemAddressLatMin
                                      )
                                      .max(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemPeopleItemAddressLatMax
                                      )
                                      .nullish(),
                                    lon: zod.coerce
                                      .number()
                                      .min(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemPeopleItemAddressLonMin
                                      )
                                      .max(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemPeopleItemAddressLonMax
                                      )
                                      .nullish(),
                                  }),
                                  createdAt: zod.coerce.date(),
                                  updatedAt: zod.coerce.date(),
                                })
                              )
                              .optional(),
                            minNumberOfWorkersOnSite: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemMinNumberOfWorkersOnSiteMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemMinNumberOfWorkersOnSiteMax
                              )
                              .nullable(),
                            maxNumberOfWorkersOnSite: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemMaxNumberOfWorkersOnSiteMin
                              ),
                            startDate: zod.coerce.date().optional(),
                            signDate: zod.coerce.date().optional(),
                            deadline: zod.coerce.date().optional(),
                            company: zod.any().optional(),
                            netPrice: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemNetPriceMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemNetPriceMax
                              )
                              .optional(),
                            tagUuid: zod.coerce.string().uuid(),
                            tag: zod
                              .object({
                                uuid: zod.coerce.string().uuid(),
                                parent: zod.any().nullish(),
                                children: zod.array(zod.any()).optional(),
                                name: zod.coerce
                                  .string()
                                  .max(
                                    projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemTagNameMax
                                  ),
                                companyType: zod.enum([
                                  'Client',
                                  'Contractor',
                                  'Purchase',
                                  'Surcharge',
                                  'Creditor',
                                ]),
                                serial: zod.coerce.string().optional(),
                                priority: zod.coerce.number().nullish(),
                                aliases: zod
                                  .array(
                                    zod.object({
                                      uuid: zod.coerce.string().uuid(),
                                      isAbbr: zod.coerce.boolean(),
                                      tag: zod.any(),
                                      nameAlias: zod.coerce
                                        .string()
                                        .min(1)
                                        .max(
                                          projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemTagAliasesItemNameAliasMax
                                        ),
                                      language: zod.coerce.string().optional(),
                                    })
                                  )
                                  .optional(),
                              })
                              .nullable(),
                            warrantyReserve: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemWarrantyReserveMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemWarrantyReserveMax
                              )
                              .nullable(),
                            umlage: zod
                              .object({
                                amount: zod.coerce
                                  .number()
                                  .min(
                                    projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemUmlageAmountMin
                                  )
                                  .max(
                                    projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemUmlageAmountMax
                                  )
                                  .optional(),
                                percent: zod.coerce.number().optional(),
                              })
                              .optional(),
                            skonto: zod
                              .object({
                                amount: zod.coerce
                                  .number()
                                  .min(
                                    projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemSkontoAmountMin
                                  )
                                  .max(
                                    projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemSkontoAmountMax
                                  )
                                  .optional(),
                                percent: zod.coerce.number().optional(),
                              })
                              .optional(),
                            attachments: zod.array(
                              zod.object({
                                name: zod.coerce
                                  .string()
                                  .max(
                                    projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemAttachmentsItemNameMax
                                  ),
                                path: zod.coerce
                                  .string()
                                  .max(
                                    projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemAttachmentsItemPathMax
                                  ),
                                mimeType: zod.coerce
                                  .string()
                                  .max(
                                    projectsControllerFindAllResponseDataItemAllocationsItemCompanyContractsItemAttachmentsItemMimeTypeMax
                                  )
                                  .optional(),
                                size: zod.coerce.number().optional(),
                                expireAt: zod.coerce.date().optional(),
                                fileUrl: zod.coerce.string(),
                                uuid: zod.coerce.string(),
                                createdAt: zod.coerce.date(),
                                updatedAt: zod.coerce.date(),
                              })
                            ),
                            createdAt: zod.coerce.date(),
                            updatedAt: zod.coerce.date(),
                            isClientContract: zod.coerce.boolean(),
                          })
                        )
                        .optional(),
                      relatedProjects: zod.array(zod.any()),
                      subContractorCost: zod
                        .array(
                          zod.object({
                            uuid: zod.coerce.string(),
                            project: zod.any(),
                            tag: zod.object({
                              uuid: zod.coerce.string().uuid(),
                              parent: zod.any().nullish(),
                              children: zod.array(zod.any()).optional(),
                              name: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemCompanySubContractorCostItemTagNameMax
                                ),
                              companyType: zod.enum([
                                'Client',
                                'Contractor',
                                'Purchase',
                                'Surcharge',
                                'Creditor',
                              ]),
                              serial: zod.coerce.string().optional(),
                              priority: zod.coerce.number().nullish(),
                              aliases: zod
                                .array(
                                  zod.object({
                                    uuid: zod.coerce.string().uuid(),
                                    isAbbr: zod.coerce.boolean(),
                                    tag: zod.any(),
                                    nameAlias: zod.coerce
                                      .string()
                                      .min(1)
                                      .max(
                                        projectsControllerFindAllResponseDataItemAllocationsItemCompanySubContractorCostItemTagAliasesItemNameAliasMax
                                      ),
                                    language: zod.coerce.string().optional(),
                                  })
                                )
                                .optional(),
                            }),
                            rowNumber: zod.coerce.string(),
                            sumSub: zod.coerce.number().nullable(),
                            createdAt: zod.coerce.date(),
                            updatedAt: zod.coerce.date(),
                            type: zod.coerce.string(),
                            items: zod.array(
                              zod.object({
                                uuid: zod.coerce.string(),
                                additionReduceCost: zod.any(),
                                rowNumber: zod.coerce.string(),
                                description: zod.coerce.string().optional(),
                                quantity: zod.coerce.number(),
                                unit: zod.coerce.string(),
                                unitPriceSub: zod.coerce.number(),
                                sumSub: zod.coerce.number(),
                                unitPriceGcPercent: zod.coerce
                                  .number()
                                  .min(
                                    projectsControllerFindAllResponseDataItemAllocationsItemCompanySubContractorCostItemItemsItemUnitPriceGcPercentMin
                                  )
                                  .max(
                                    projectsControllerFindAllResponseDataItemAllocationsItemCompanySubContractorCostItemItemsItemUnitPriceGcPercentMax
                                  ),
                                unitPriceGc: zod.coerce.number().optional(),
                                sumGc: zod.coerce.number(),
                                differenceGcSub: zod.coerce.number().nullish(),
                                instructedSum: zod.coerce.number(),
                                status: zod.enum([
                                  'open',
                                  'rejected',
                                  'instructed',
                                  'paid',
                                ]),
                                createdAt: zod.coerce.date(),
                                updatedAt: zod.coerce.date(),
                                additionDate: zod.coerce.date().nullable(),
                                additionTitle: zod.coerce.string().nullable(),
                                additionNumber: zod.coerce.string().nullable(),
                                authorizedAmount: zod.coerce
                                  .number()
                                  .nullable(),
                                remarks: zod.coerce.string().nullable(),
                              })
                            ),
                          })
                        )
                        .optional(),
                    })
                    .optional(),
                  isCompanyEditable: zod.coerce.boolean().optional(),
                  tag: zod.object({
                    uuid: zod.coerce.string().uuid(),
                    parent: zod.any().nullish(),
                    children: zod.array(zod.any()).optional(),
                    name: zod.coerce
                      .string()
                      .max(
                        projectsControllerFindAllResponseDataItemAllocationsItemTagNameMax
                      ),
                    companyType: zod.enum([
                      'Client',
                      'Contractor',
                      'Purchase',
                      'Surcharge',
                      'Creditor',
                    ]),
                    serial: zod.coerce.string().optional(),
                    priority: zod.coerce.number().nullish(),
                    aliases: zod
                      .array(
                        zod.object({
                          uuid: zod.coerce.string().uuid(),
                          isAbbr: zod.coerce.boolean(),
                          tag: zod.any(),
                          nameAlias: zod.coerce
                            .string()
                            .min(1)
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemTagAliasesItemNameAliasMax
                            ),
                          language: zod.coerce.string().optional(),
                        })
                      )
                      .optional(),
                  }),
                  isTagEditable: zod.coerce.boolean().optional(),
                  contract: zod
                    .object({
                      uuid: zod.coerce.string().uuid(),
                      description: zod.coerce.string().nullable(),
                      project: zod.any(),
                      internalProjectManager: zod
                        .object({
                          uuid: zod.coerce.string().uuid(),
                          email: zod.coerce.string().email(),
                          firstName: zod.coerce
                            .string()
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerFirstNameMax
                            ),
                          lastName: zod.coerce
                            .string()
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerLastNameMax
                            ),
                          privatePhoneNumber: zod.coerce.string().optional(),
                          workPhoneNumber: zod.coerce.string().optional(),
                          status: zod.enum(['Active', 'Terminated']),
                          permissions: zod
                            .array(
                              zod.enum(['admin', 'human-resource', 'user'])
                            )
                            .optional(),
                          createdAt: zod.coerce.date(),
                          updatedAt: zod.coerce.date(),
                          contracts: zod.array(
                            zod.object({
                              uuid: zod.coerce.string().uuid(),
                              user: zod.any(),
                              role: zod.object({
                                uuid: zod.coerce.string().uuid(),
                                name: zod.coerce.string(),
                              }),
                              department: zod.object({
                                uuid: zod.coerce.string().uuid(),
                                name: zod.coerce.string(),
                              }),
                              perHourSalary: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemPerHourSalaryMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemPerHourSalaryMax
                                )
                                .optional(),
                              monthlyDuration: zod
                                .object({
                                  days: zod.coerce
                                    .number()
                                    .min(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemMonthlyDurationDaysMin
                                    )
                                    .max(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemMonthlyDurationDaysMax
                                    )
                                    .optional(),
                                  hours: zod.coerce
                                    .number()
                                    .min(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemMonthlyDurationHoursMin
                                    )
                                    .max(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemMonthlyDurationHoursMax
                                    )
                                    .optional(),
                                  minutes: zod.coerce
                                    .number()
                                    .min(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemMonthlyDurationMinutesMin
                                    )
                                    .max(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemMonthlyDurationMinutesMax
                                    )
                                    .optional(),
                                  seconds: zod.coerce.number().optional(),
                                  milliseconds: zod.coerce
                                    .number()
                                    .min(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemMonthlyDurationMillisecondsMin
                                    )
                                    .max(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemMonthlyDurationMillisecondsMax
                                    )
                                    .optional(),
                                })
                                .optional(),
                              grossSalary: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemGrossSalaryMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemGrossSalaryMax
                                ),
                              type: zod.enum([
                                'full-time',
                                'part-time',
                                'hourly',
                              ]),
                              startDate: zod.coerce.date(),
                              endDate: zod.coerce.date().optional(),
                              vacationPerMonth: zod.object({
                                days: zod.coerce
                                  .number()
                                  .min(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemVacationPerMonthDaysMin
                                  )
                                  .max(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemVacationPerMonthDaysMax
                                  )
                                  .optional(),
                                hours: zod.coerce
                                  .number()
                                  .min(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemVacationPerMonthHoursMin
                                  )
                                  .max(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemVacationPerMonthHoursMax
                                  )
                                  .optional(),
                                minutes: zod.coerce
                                  .number()
                                  .min(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemVacationPerMonthMinutesMin
                                  )
                                  .max(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemVacationPerMonthMinutesMax
                                  )
                                  .optional(),
                                seconds: zod.coerce.number().optional(),
                                milliseconds: zod.coerce
                                  .number()
                                  .min(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemVacationPerMonthMillisecondsMin
                                  )
                                  .max(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemVacationPerMonthMillisecondsMax
                                  )
                                  .optional(),
                              }),
                              attachments: zod.array(
                                zod.object({
                                  name: zod.coerce
                                    .string()
                                    .max(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemAttachmentsItemNameMax
                                    ),
                                  path: zod.coerce
                                    .string()
                                    .max(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemAttachmentsItemPathMax
                                    ),
                                  mimeType: zod.coerce
                                    .string()
                                    .max(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemAttachmentsItemMimeTypeMax
                                    )
                                    .optional(),
                                  size: zod.coerce.number().optional(),
                                  expireAt: zod.coerce.date().optional(),
                                  fileUrl: zod.coerce.string(),
                                  uuid: zod.coerce.string(),
                                  createdAt: zod.coerce.date(),
                                  updatedAt: zod.coerce.date(),
                                })
                              ),
                              previousSalary: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemPreviousSalaryMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemPreviousSalaryMax
                                ),
                              previousPerHourSalary: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemPreviousPerHourSalaryMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemPreviousPerHourSalaryMax
                                ),
                              salaryDiff: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemSalaryDiffMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerContractsItemSalaryDiffMax
                                ),
                              isTypeChanged: zod.coerce.boolean(),
                              previousContractType: zod
                                .enum(['full-time', 'part-time', 'hourly'])
                                .optional(),
                              createdAt: zod.coerce.date(),
                              updatedAt: zod.coerce.date(),
                            })
                          ),
                          currentContract: zod
                            .object({
                              uuid: zod.coerce.string().uuid(),
                              user: zod.any(),
                              role: zod.object({
                                uuid: zod.coerce.string().uuid(),
                                name: zod.coerce.string(),
                              }),
                              department: zod.object({
                                uuid: zod.coerce.string().uuid(),
                                name: zod.coerce.string(),
                              }),
                              perHourSalary: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractPerHourSalaryMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractPerHourSalaryMax
                                )
                                .optional(),
                              monthlyDuration: zod
                                .object({
                                  days: zod.coerce
                                    .number()
                                    .min(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractMonthlyDurationDaysMin
                                    )
                                    .max(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractMonthlyDurationDaysMax
                                    )
                                    .optional(),
                                  hours: zod.coerce
                                    .number()
                                    .min(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractMonthlyDurationHoursMin
                                    )
                                    .max(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractMonthlyDurationHoursMax
                                    )
                                    .optional(),
                                  minutes: zod.coerce
                                    .number()
                                    .min(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractMonthlyDurationMinutesMin
                                    )
                                    .max(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractMonthlyDurationMinutesMax
                                    )
                                    .optional(),
                                  seconds: zod.coerce.number().optional(),
                                  milliseconds: zod.coerce
                                    .number()
                                    .min(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractMonthlyDurationMillisecondsMin
                                    )
                                    .max(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractMonthlyDurationMillisecondsMax
                                    )
                                    .optional(),
                                })
                                .optional(),
                              grossSalary: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractGrossSalaryMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractGrossSalaryMax
                                ),
                              type: zod.enum([
                                'full-time',
                                'part-time',
                                'hourly',
                              ]),
                              startDate: zod.coerce.date(),
                              endDate: zod.coerce.date().optional(),
                              vacationPerMonth: zod.object({
                                days: zod.coerce
                                  .number()
                                  .min(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractVacationPerMonthDaysMin
                                  )
                                  .max(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractVacationPerMonthDaysMax
                                  )
                                  .optional(),
                                hours: zod.coerce
                                  .number()
                                  .min(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractVacationPerMonthHoursMin
                                  )
                                  .max(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractVacationPerMonthHoursMax
                                  )
                                  .optional(),
                                minutes: zod.coerce
                                  .number()
                                  .min(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractVacationPerMonthMinutesMin
                                  )
                                  .max(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractVacationPerMonthMinutesMax
                                  )
                                  .optional(),
                                seconds: zod.coerce.number().optional(),
                                milliseconds: zod.coerce
                                  .number()
                                  .min(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractVacationPerMonthMillisecondsMin
                                  )
                                  .max(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractVacationPerMonthMillisecondsMax
                                  )
                                  .optional(),
                              }),
                              attachments: zod.array(
                                zod.object({
                                  name: zod.coerce
                                    .string()
                                    .max(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractAttachmentsItemNameMax
                                    ),
                                  path: zod.coerce
                                    .string()
                                    .max(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractAttachmentsItemPathMax
                                    ),
                                  mimeType: zod.coerce
                                    .string()
                                    .max(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractAttachmentsItemMimeTypeMax
                                    )
                                    .optional(),
                                  size: zod.coerce.number().optional(),
                                  expireAt: zod.coerce.date().optional(),
                                  fileUrl: zod.coerce.string(),
                                  uuid: zod.coerce.string(),
                                  createdAt: zod.coerce.date(),
                                  updatedAt: zod.coerce.date(),
                                })
                              ),
                              previousSalary: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractPreviousSalaryMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractPreviousSalaryMax
                                ),
                              previousPerHourSalary: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractPreviousPerHourSalaryMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractPreviousPerHourSalaryMax
                                ),
                              salaryDiff: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractSalaryDiffMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerCurrentContractSalaryDiffMax
                                ),
                              isTypeChanged: zod.coerce.boolean(),
                              previousContractType: zod
                                .enum(['full-time', 'part-time', 'hourly'])
                                .optional(),
                              createdAt: zod.coerce.date(),
                              updatedAt: zod.coerce.date(),
                            })
                            .optional(),
                          salaries: zod.array(
                            zod.object({
                              uuid: zod.coerce.string().uuid(),
                              user: zod.any(),
                              date: zod.coerce.date(),
                              paymentDate: zod.coerce.date().optional(),
                              perHour: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemPerHourMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemPerHourMax
                                )
                                .optional(),
                              monthlyDuration: zod
                                .object({
                                  days: zod.coerce
                                    .number()
                                    .min(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemMonthlyDurationDaysMin
                                    )
                                    .max(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemMonthlyDurationDaysMax
                                    )
                                    .optional(),
                                  hours: zod.coerce
                                    .number()
                                    .min(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemMonthlyDurationHoursMin
                                    )
                                    .max(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemMonthlyDurationHoursMax
                                    )
                                    .optional(),
                                  minutes: zod.coerce
                                    .number()
                                    .min(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemMonthlyDurationMinutesMin
                                    )
                                    .max(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemMonthlyDurationMinutesMax
                                    )
                                    .optional(),
                                  seconds: zod.coerce.number().optional(),
                                  milliseconds: zod.coerce
                                    .number()
                                    .min(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemMonthlyDurationMillisecondsMin
                                    )
                                    .max(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemMonthlyDurationMillisecondsMax
                                    )
                                    .optional(),
                                })
                                .optional(),
                              base: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemBaseMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemBaseMax
                                ),
                              bonus: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemBonusMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemBonusMax
                                )
                                .optional(),
                              paid: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemPaidMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerSalariesItemPaidMax
                                ),
                              type: zod.enum([
                                'full-time',
                                'part-time',
                                'hourly',
                              ]),
                              role: zod.object({
                                uuid: zod.coerce.string().uuid(),
                                name: zod.coerce.string(),
                              }),
                              department: zod.object({
                                uuid: zod.coerce.string().uuid(),
                                name: zod.coerce.string(),
                              }),
                              createdAt: zod.coerce.date(),
                              updatedAt: zod.coerce.date(),
                              isMultiContract: zod.coerce.boolean(),
                            })
                          ),
                          hireDate: zod.coerce.date().optional(),
                          profilePicture: zod.coerce.string(),
                          fullName: zod.coerce.string(),
                          leaveTypes: zod.array(
                            zod.object({
                              uuid: zod.coerce.string().uuid(),
                              name: zod.coerce
                                .string()
                                .min(1)
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerLeaveTypesItemNameMax
                                ),
                              description: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerLeaveTypesItemDescriptionMax
                                )
                                .optional(),
                              color: zod.coerce
                                .string()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerLeaveTypesItemColorMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerLeaveTypesItemColorMax
                                )
                                .regex(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerLeaveTypesItemColorRegExp
                                )
                                .optional(),
                              allowedPerYear: zod.object({
                                days: zod.coerce
                                  .number()
                                  .min(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerLeaveTypesItemAllowedPerYearDaysMin
                                  )
                                  .max(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerLeaveTypesItemAllowedPerYearDaysMax
                                  )
                                  .optional(),
                                hours: zod.coerce
                                  .number()
                                  .min(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerLeaveTypesItemAllowedPerYearHoursMin
                                  )
                                  .max(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerLeaveTypesItemAllowedPerYearHoursMax
                                  )
                                  .optional(),
                                minutes: zod.coerce
                                  .number()
                                  .min(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerLeaveTypesItemAllowedPerYearMinutesMin
                                  )
                                  .max(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerLeaveTypesItemAllowedPerYearMinutesMax
                                  )
                                  .optional(),
                                seconds: zod.coerce.number().optional(),
                                milliseconds: zod.coerce
                                  .number()
                                  .min(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerLeaveTypesItemAllowedPerYearMillisecondsMin
                                  )
                                  .max(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractInternalProjectManagerLeaveTypesItemAllowedPerYearMillisecondsMax
                                  )
                                  .optional(),
                              }),
                              isFreebie: zod.coerce.boolean().optional(),
                              isWithAttachment: zod.coerce.boolean().optional(),
                            })
                          ),
                        })
                        .nullable(),
                      externalProjectManager: zod
                        .object({
                          company: zod
                            .object({
                              uuid: zod.coerce.string().uuid(),
                              serial: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerCompanySerialMax
                                )
                                .nullish(),
                              name: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerCompanyNameMax
                                ),
                              address: zod
                                .object({
                                  uuid: zod.coerce.string().uuid(),
                                  country: zod.coerce.string().optional(),
                                  city: zod.coerce.string().nullish(),
                                  street: zod.coerce.string().nullish(),
                                  postcode: zod.coerce.string().nullish(),
                                  lat: zod.coerce
                                    .number()
                                    .min(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerCompanyAddressLatMin
                                    )
                                    .max(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerCompanyAddressLatMax
                                    )
                                    .nullish(),
                                  lon: zod.coerce
                                    .number()
                                    .min(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerCompanyAddressLonMin
                                    )
                                    .max(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerCompanyAddressLonMax
                                    )
                                    .nullish(),
                                })
                                .nullish(),
                              phoneNumber: zod.coerce.string().nullish(),
                              email: zod.coerce.string().email().nullish(),
                              website: zod.coerce.string().url().nullable(),
                              logo: zod.coerce.string().optional(),
                              companyType: zod.enum([
                                'Client',
                                'Contractor',
                                'Purchase',
                                'Surcharge',
                                'Creditor',
                              ]),
                              tag: zod
                                .object({
                                  uuid: zod.coerce.string().uuid(),
                                  parent: zod.any().nullish(),
                                  children: zod.array(zod.any()).optional(),
                                  name: zod.coerce
                                    .string()
                                    .max(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerCompanyTagNameMax
                                    ),
                                  companyType: zod.enum([
                                    'Client',
                                    'Contractor',
                                    'Purchase',
                                    'Surcharge',
                                    'Creditor',
                                  ]),
                                  serial: zod.coerce.string().optional(),
                                  priority: zod.coerce.number().nullish(),
                                  aliases: zod
                                    .array(
                                      zod.object({
                                        uuid: zod.coerce.string().uuid(),
                                        isAbbr: zod.coerce.boolean(),
                                        tag: zod.any(),
                                        nameAlias: zod.coerce
                                          .string()
                                          .min(1)
                                          .max(
                                            projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerCompanyTagAliasesItemNameAliasMax
                                          ),
                                        language: zod.coerce
                                          .string()
                                          .optional(),
                                      })
                                    )
                                    .optional(),
                                })
                                .nullish(),
                              rating: zod.coerce
                                .number()
                                .min(1)
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerCompanyRatingMax
                                )
                                .nullish(),
                              companySize: zod.coerce.number().min(1).nullish(),
                              maxDistanceToProject: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerCompanyMaxDistanceToProjectMin
                                )
                                .optional(),
                              createdAt: zod.coerce.date(),
                              updatedAt: zod.coerce.date(),
                              contracts: zod.array(zod.any()).optional(),
                              relatedProjects: zod.array(zod.any()),
                              subContractorCost: zod
                                .array(
                                  zod.object({
                                    uuid: zod.coerce.string(),
                                    project: zod.any(),
                                    tag: zod.object({
                                      uuid: zod.coerce.string().uuid(),
                                      parent: zod.any().nullish(),
                                      children: zod.array(zod.any()).optional(),
                                      name: zod.coerce
                                        .string()
                                        .max(
                                          projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerCompanySubContractorCostItemTagNameMax
                                        ),
                                      companyType: zod.enum([
                                        'Client',
                                        'Contractor',
                                        'Purchase',
                                        'Surcharge',
                                        'Creditor',
                                      ]),
                                      serial: zod.coerce.string().optional(),
                                      priority: zod.coerce.number().nullish(),
                                      aliases: zod
                                        .array(
                                          zod.object({
                                            uuid: zod.coerce.string().uuid(),
                                            isAbbr: zod.coerce.boolean(),
                                            tag: zod.any(),
                                            nameAlias: zod.coerce
                                              .string()
                                              .min(1)
                                              .max(
                                                projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerCompanySubContractorCostItemTagAliasesItemNameAliasMax
                                              ),
                                            language: zod.coerce
                                              .string()
                                              .optional(),
                                          })
                                        )
                                        .optional(),
                                    }),
                                    rowNumber: zod.coerce.string(),
                                    sumSub: zod.coerce.number().nullable(),
                                    createdAt: zod.coerce.date(),
                                    updatedAt: zod.coerce.date(),
                                    type: zod.coerce.string(),
                                    items: zod.array(
                                      zod.object({
                                        uuid: zod.coerce.string(),
                                        additionReduceCost: zod.any(),
                                        rowNumber: zod.coerce.string(),
                                        description: zod.coerce
                                          .string()
                                          .optional(),
                                        quantity: zod.coerce.number(),
                                        unit: zod.coerce.string(),
                                        unitPriceSub: zod.coerce.number(),
                                        sumSub: zod.coerce.number(),
                                        unitPriceGcPercent: zod.coerce
                                          .number()
                                          .min(
                                            projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerCompanySubContractorCostItemItemsItemUnitPriceGcPercentMin
                                          )
                                          .max(
                                            projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerCompanySubContractorCostItemItemsItemUnitPriceGcPercentMax
                                          ),
                                        unitPriceGc: zod.coerce
                                          .number()
                                          .optional(),
                                        sumGc: zod.coerce.number(),
                                        differenceGcSub: zod.coerce
                                          .number()
                                          .nullish(),
                                        instructedSum: zod.coerce.number(),
                                        status: zod.enum([
                                          'open',
                                          'rejected',
                                          'instructed',
                                          'paid',
                                        ]),
                                        createdAt: zod.coerce.date(),
                                        updatedAt: zod.coerce.date(),
                                        additionDate: zod.coerce
                                          .date()
                                          .nullable(),
                                        additionTitle: zod.coerce
                                          .string()
                                          .nullable(),
                                        additionNumber: zod.coerce
                                          .string()
                                          .nullable(),
                                        authorizedAmount: zod.coerce
                                          .number()
                                          .nullable(),
                                        remarks: zod.coerce.string().nullable(),
                                      })
                                    ),
                                  })
                                )
                                .optional(),
                            })
                            .optional(),
                          contracts: zod.array(zod.any()).optional(),
                          firstName: zod.coerce
                            .string()
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerFirstNameMax
                            ),
                          lastName: zod.coerce
                            .string()
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerLastNameMax
                            ),
                          phoneNumber: zod.coerce.string().nullable(),
                          email: zod.coerce.string().email().nullable(),
                          description: zod.coerce.string(),
                          website: zod.coerce.string().url().nullable(),
                          fullName: zod.coerce.string(),
                          profilePicture: zod.coerce.string(),
                          relatedProjects: zod.array(zod.any()),
                          uuid: zod.coerce.string().uuid(),
                          address: zod.object({
                            uuid: zod.coerce.string().uuid(),
                            country: zod.coerce.string().optional(),
                            city: zod.coerce.string().nullish(),
                            street: zod.coerce.string().nullish(),
                            postcode: zod.coerce.string().nullish(),
                            lat: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerAddressLatMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerAddressLatMax
                              )
                              .nullish(),
                            lon: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerAddressLonMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemAllocationsItemContractExternalProjectManagerAddressLonMax
                              )
                              .nullish(),
                          }),
                          createdAt: zod.coerce.date(),
                          updatedAt: zod.coerce.date(),
                        })
                        .nullable(),
                      people: zod
                        .array(
                          zod.object({
                            company: zod
                              .object({
                                uuid: zod.coerce.string().uuid(),
                                serial: zod.coerce
                                  .string()
                                  .max(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemCompanySerialMax
                                  )
                                  .nullish(),
                                name: zod.coerce
                                  .string()
                                  .max(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemCompanyNameMax
                                  ),
                                address: zod
                                  .object({
                                    uuid: zod.coerce.string().uuid(),
                                    country: zod.coerce.string().optional(),
                                    city: zod.coerce.string().nullish(),
                                    street: zod.coerce.string().nullish(),
                                    postcode: zod.coerce.string().nullish(),
                                    lat: zod.coerce
                                      .number()
                                      .min(
                                        projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemCompanyAddressLatMin
                                      )
                                      .max(
                                        projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemCompanyAddressLatMax
                                      )
                                      .nullish(),
                                    lon: zod.coerce
                                      .number()
                                      .min(
                                        projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemCompanyAddressLonMin
                                      )
                                      .max(
                                        projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemCompanyAddressLonMax
                                      )
                                      .nullish(),
                                  })
                                  .nullish(),
                                phoneNumber: zod.coerce.string().nullish(),
                                email: zod.coerce.string().email().nullish(),
                                website: zod.coerce.string().url().nullable(),
                                logo: zod.coerce.string().optional(),
                                companyType: zod.enum([
                                  'Client',
                                  'Contractor',
                                  'Purchase',
                                  'Surcharge',
                                  'Creditor',
                                ]),
                                tag: zod
                                  .object({
                                    uuid: zod.coerce.string().uuid(),
                                    parent: zod.any().nullish(),
                                    children: zod.array(zod.any()).optional(),
                                    name: zod.coerce
                                      .string()
                                      .max(
                                        projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemCompanyTagNameMax
                                      ),
                                    companyType: zod.enum([
                                      'Client',
                                      'Contractor',
                                      'Purchase',
                                      'Surcharge',
                                      'Creditor',
                                    ]),
                                    serial: zod.coerce.string().optional(),
                                    priority: zod.coerce.number().nullish(),
                                    aliases: zod
                                      .array(
                                        zod.object({
                                          uuid: zod.coerce.string().uuid(),
                                          isAbbr: zod.coerce.boolean(),
                                          tag: zod.any(),
                                          nameAlias: zod.coerce
                                            .string()
                                            .min(1)
                                            .max(
                                              projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemCompanyTagAliasesItemNameAliasMax
                                            ),
                                          language: zod.coerce
                                            .string()
                                            .optional(),
                                        })
                                      )
                                      .optional(),
                                  })
                                  .nullish(),
                                rating: zod.coerce
                                  .number()
                                  .min(1)
                                  .max(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemCompanyRatingMax
                                  )
                                  .nullish(),
                                companySize: zod.coerce
                                  .number()
                                  .min(1)
                                  .nullish(),
                                maxDistanceToProject: zod.coerce
                                  .number()
                                  .min(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemCompanyMaxDistanceToProjectMin
                                  )
                                  .optional(),
                                createdAt: zod.coerce.date(),
                                updatedAt: zod.coerce.date(),
                                contracts: zod.array(zod.any()).optional(),
                                relatedProjects: zod.array(zod.any()),
                                subContractorCost: zod
                                  .array(
                                    zod.object({
                                      uuid: zod.coerce.string(),
                                      project: zod.any(),
                                      tag: zod.object({
                                        uuid: zod.coerce.string().uuid(),
                                        parent: zod.any().nullish(),
                                        children: zod
                                          .array(zod.any())
                                          .optional(),
                                        name: zod.coerce
                                          .string()
                                          .max(
                                            projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemCompanySubContractorCostItemTagNameMax
                                          ),
                                        companyType: zod.enum([
                                          'Client',
                                          'Contractor',
                                          'Purchase',
                                          'Surcharge',
                                          'Creditor',
                                        ]),
                                        serial: zod.coerce.string().optional(),
                                        priority: zod.coerce.number().nullish(),
                                        aliases: zod
                                          .array(
                                            zod.object({
                                              uuid: zod.coerce.string().uuid(),
                                              isAbbr: zod.coerce.boolean(),
                                              tag: zod.any(),
                                              nameAlias: zod.coerce
                                                .string()
                                                .min(1)
                                                .max(
                                                  projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemCompanySubContractorCostItemTagAliasesItemNameAliasMax
                                                ),
                                              language: zod.coerce
                                                .string()
                                                .optional(),
                                            })
                                          )
                                          .optional(),
                                      }),
                                      rowNumber: zod.coerce.string(),
                                      sumSub: zod.coerce.number().nullable(),
                                      createdAt: zod.coerce.date(),
                                      updatedAt: zod.coerce.date(),
                                      type: zod.coerce.string(),
                                      items: zod.array(
                                        zod.object({
                                          uuid: zod.coerce.string(),
                                          additionReduceCost: zod.any(),
                                          rowNumber: zod.coerce.string(),
                                          description: zod.coerce
                                            .string()
                                            .optional(),
                                          quantity: zod.coerce.number(),
                                          unit: zod.coerce.string(),
                                          unitPriceSub: zod.coerce.number(),
                                          sumSub: zod.coerce.number(),
                                          unitPriceGcPercent: zod.coerce
                                            .number()
                                            .min(
                                              projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemCompanySubContractorCostItemItemsItemUnitPriceGcPercentMin
                                            )
                                            .max(
                                              projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemCompanySubContractorCostItemItemsItemUnitPriceGcPercentMax
                                            ),
                                          unitPriceGc: zod.coerce
                                            .number()
                                            .optional(),
                                          sumGc: zod.coerce.number(),
                                          differenceGcSub: zod.coerce
                                            .number()
                                            .nullish(),
                                          instructedSum: zod.coerce.number(),
                                          status: zod.enum([
                                            'open',
                                            'rejected',
                                            'instructed',
                                            'paid',
                                          ]),
                                          createdAt: zod.coerce.date(),
                                          updatedAt: zod.coerce.date(),
                                          additionDate: zod.coerce
                                            .date()
                                            .nullable(),
                                          additionTitle: zod.coerce
                                            .string()
                                            .nullable(),
                                          additionNumber: zod.coerce
                                            .string()
                                            .nullable(),
                                          authorizedAmount: zod.coerce
                                            .number()
                                            .nullable(),
                                          remarks: zod.coerce
                                            .string()
                                            .nullable(),
                                        })
                                      ),
                                    })
                                  )
                                  .optional(),
                              })
                              .optional(),
                            contracts: zod.array(zod.any()).optional(),
                            firstName: zod.coerce
                              .string()
                              .max(
                                projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemFirstNameMax
                              ),
                            lastName: zod.coerce
                              .string()
                              .max(
                                projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemLastNameMax
                              ),
                            phoneNumber: zod.coerce.string().nullable(),
                            email: zod.coerce.string().email().nullable(),
                            description: zod.coerce.string(),
                            website: zod.coerce.string().url().nullable(),
                            fullName: zod.coerce.string(),
                            profilePicture: zod.coerce.string(),
                            relatedProjects: zod.array(zod.any()),
                            uuid: zod.coerce.string().uuid(),
                            address: zod.object({
                              uuid: zod.coerce.string().uuid(),
                              country: zod.coerce.string().optional(),
                              city: zod.coerce.string().nullish(),
                              street: zod.coerce.string().nullish(),
                              postcode: zod.coerce.string().nullish(),
                              lat: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemAddressLatMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemAddressLatMax
                                )
                                .nullish(),
                              lon: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemAddressLonMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractPeopleItemAddressLonMax
                                )
                                .nullish(),
                            }),
                            createdAt: zod.coerce.date(),
                            updatedAt: zod.coerce.date(),
                          })
                        )
                        .optional(),
                      minNumberOfWorkersOnSite: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemAllocationsItemContractMinNumberOfWorkersOnSiteMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemAllocationsItemContractMinNumberOfWorkersOnSiteMax
                        )
                        .nullable(),
                      maxNumberOfWorkersOnSite: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemAllocationsItemContractMaxNumberOfWorkersOnSiteMin
                        ),
                      startDate: zod.coerce.date().optional(),
                      signDate: zod.coerce.date().optional(),
                      deadline: zod.coerce.date().optional(),
                      company: zod
                        .object({
                          uuid: zod.coerce.string().uuid(),
                          serial: zod.coerce
                            .string()
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemContractCompanySerialMax
                            )
                            .nullish(),
                          name: zod.coerce
                            .string()
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemContractCompanyNameMax
                            ),
                          address: zod
                            .object({
                              uuid: zod.coerce.string().uuid(),
                              country: zod.coerce.string().optional(),
                              city: zod.coerce.string().nullish(),
                              street: zod.coerce.string().nullish(),
                              postcode: zod.coerce.string().nullish(),
                              lat: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractCompanyAddressLatMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractCompanyAddressLatMax
                                )
                                .nullish(),
                              lon: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractCompanyAddressLonMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractCompanyAddressLonMax
                                )
                                .nullish(),
                            })
                            .nullish(),
                          phoneNumber: zod.coerce.string().nullish(),
                          email: zod.coerce.string().email().nullish(),
                          website: zod.coerce.string().url().nullable(),
                          logo: zod.coerce.string().optional(),
                          companyType: zod.enum([
                            'Client',
                            'Contractor',
                            'Purchase',
                            'Surcharge',
                            'Creditor',
                          ]),
                          tag: zod
                            .object({
                              uuid: zod.coerce.string().uuid(),
                              parent: zod.any().nullish(),
                              children: zod.array(zod.any()).optional(),
                              name: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemContractCompanyTagNameMax
                                ),
                              companyType: zod.enum([
                                'Client',
                                'Contractor',
                                'Purchase',
                                'Surcharge',
                                'Creditor',
                              ]),
                              serial: zod.coerce.string().optional(),
                              priority: zod.coerce.number().nullish(),
                              aliases: zod
                                .array(
                                  zod.object({
                                    uuid: zod.coerce.string().uuid(),
                                    isAbbr: zod.coerce.boolean(),
                                    tag: zod.any(),
                                    nameAlias: zod.coerce
                                      .string()
                                      .min(1)
                                      .max(
                                        projectsControllerFindAllResponseDataItemAllocationsItemContractCompanyTagAliasesItemNameAliasMax
                                      ),
                                    language: zod.coerce.string().optional(),
                                  })
                                )
                                .optional(),
                            })
                            .nullish(),
                          rating: zod.coerce
                            .number()
                            .min(1)
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemContractCompanyRatingMax
                            )
                            .nullish(),
                          companySize: zod.coerce.number().min(1).nullish(),
                          maxDistanceToProject: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemAllocationsItemContractCompanyMaxDistanceToProjectMin
                            )
                            .optional(),
                          createdAt: zod.coerce.date(),
                          updatedAt: zod.coerce.date(),
                          contracts: zod.array(zod.any()).optional(),
                          relatedProjects: zod.array(zod.any()),
                          subContractorCost: zod
                            .array(
                              zod.object({
                                uuid: zod.coerce.string(),
                                project: zod.any(),
                                tag: zod.object({
                                  uuid: zod.coerce.string().uuid(),
                                  parent: zod.any().nullish(),
                                  children: zod.array(zod.any()).optional(),
                                  name: zod.coerce
                                    .string()
                                    .max(
                                      projectsControllerFindAllResponseDataItemAllocationsItemContractCompanySubContractorCostItemTagNameMax
                                    ),
                                  companyType: zod.enum([
                                    'Client',
                                    'Contractor',
                                    'Purchase',
                                    'Surcharge',
                                    'Creditor',
                                  ]),
                                  serial: zod.coerce.string().optional(),
                                  priority: zod.coerce.number().nullish(),
                                  aliases: zod
                                    .array(
                                      zod.object({
                                        uuid: zod.coerce.string().uuid(),
                                        isAbbr: zod.coerce.boolean(),
                                        tag: zod.any(),
                                        nameAlias: zod.coerce
                                          .string()
                                          .min(1)
                                          .max(
                                            projectsControllerFindAllResponseDataItemAllocationsItemContractCompanySubContractorCostItemTagAliasesItemNameAliasMax
                                          ),
                                        language: zod.coerce
                                          .string()
                                          .optional(),
                                      })
                                    )
                                    .optional(),
                                }),
                                rowNumber: zod.coerce.string(),
                                sumSub: zod.coerce.number().nullable(),
                                createdAt: zod.coerce.date(),
                                updatedAt: zod.coerce.date(),
                                type: zod.coerce.string(),
                                items: zod.array(
                                  zod.object({
                                    uuid: zod.coerce.string(),
                                    additionReduceCost: zod.any(),
                                    rowNumber: zod.coerce.string(),
                                    description: zod.coerce.string().optional(),
                                    quantity: zod.coerce.number(),
                                    unit: zod.coerce.string(),
                                    unitPriceSub: zod.coerce.number(),
                                    sumSub: zod.coerce.number(),
                                    unitPriceGcPercent: zod.coerce
                                      .number()
                                      .min(
                                        projectsControllerFindAllResponseDataItemAllocationsItemContractCompanySubContractorCostItemItemsItemUnitPriceGcPercentMin
                                      )
                                      .max(
                                        projectsControllerFindAllResponseDataItemAllocationsItemContractCompanySubContractorCostItemItemsItemUnitPriceGcPercentMax
                                      ),
                                    unitPriceGc: zod.coerce.number().optional(),
                                    sumGc: zod.coerce.number(),
                                    differenceGcSub: zod.coerce
                                      .number()
                                      .nullish(),
                                    instructedSum: zod.coerce.number(),
                                    status: zod.enum([
                                      'open',
                                      'rejected',
                                      'instructed',
                                      'paid',
                                    ]),
                                    createdAt: zod.coerce.date(),
                                    updatedAt: zod.coerce.date(),
                                    additionDate: zod.coerce.date().nullable(),
                                    additionTitle: zod.coerce
                                      .string()
                                      .nullable(),
                                    additionNumber: zod.coerce
                                      .string()
                                      .nullable(),
                                    authorizedAmount: zod.coerce
                                      .number()
                                      .nullable(),
                                    remarks: zod.coerce.string().nullable(),
                                  })
                                ),
                              })
                            )
                            .optional(),
                        })
                        .optional(),
                      netPrice: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemAllocationsItemContractNetPriceMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemAllocationsItemContractNetPriceMax
                        )
                        .optional(),
                      tagUuid: zod.coerce.string().uuid(),
                      tag: zod
                        .object({
                          uuid: zod.coerce.string().uuid(),
                          parent: zod.any().nullish(),
                          children: zod.array(zod.any()).optional(),
                          name: zod.coerce
                            .string()
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemContractTagNameMax
                            ),
                          companyType: zod.enum([
                            'Client',
                            'Contractor',
                            'Purchase',
                            'Surcharge',
                            'Creditor',
                          ]),
                          serial: zod.coerce.string().optional(),
                          priority: zod.coerce.number().nullish(),
                          aliases: zod
                            .array(
                              zod.object({
                                uuid: zod.coerce.string().uuid(),
                                isAbbr: zod.coerce.boolean(),
                                tag: zod.any(),
                                nameAlias: zod.coerce
                                  .string()
                                  .min(1)
                                  .max(
                                    projectsControllerFindAllResponseDataItemAllocationsItemContractTagAliasesItemNameAliasMax
                                  ),
                                language: zod.coerce.string().optional(),
                              })
                            )
                            .optional(),
                        })
                        .nullable(),
                      warrantyReserve: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemAllocationsItemContractWarrantyReserveMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemAllocationsItemContractWarrantyReserveMax
                        )
                        .nullable(),
                      umlage: zod
                        .object({
                          amount: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemAllocationsItemContractUmlageAmountMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemContractUmlageAmountMax
                            )
                            .optional(),
                          percent: zod.coerce.number().optional(),
                        })
                        .optional(),
                      skonto: zod
                        .object({
                          amount: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemAllocationsItemContractSkontoAmountMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemContractSkontoAmountMax
                            )
                            .optional(),
                          percent: zod.coerce.number().optional(),
                        })
                        .optional(),
                      attachments: zod.array(
                        zod.object({
                          name: zod.coerce
                            .string()
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemContractAttachmentsItemNameMax
                            ),
                          path: zod.coerce
                            .string()
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemContractAttachmentsItemPathMax
                            ),
                          mimeType: zod.coerce
                            .string()
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemContractAttachmentsItemMimeTypeMax
                            )
                            .optional(),
                          size: zod.coerce.number().optional(),
                          expireAt: zod.coerce.date().optional(),
                          fileUrl: zod.coerce.string(),
                          uuid: zod.coerce.string(),
                          createdAt: zod.coerce.date(),
                          updatedAt: zod.coerce.date(),
                        })
                      ),
                      createdAt: zod.coerce.date(),
                      updatedAt: zod.coerce.date(),
                      isClientContract: zod.coerce.boolean(),
                    })
                    .optional(),
                  isContractEditable: zod.coerce.boolean().optional(),
                  remarks: zod.coerce.string().optional(),
                  isRemarksEditable: zod.coerce.boolean().optional(),
                  projectManagers: zod.array(
                    zod.object({
                      uuid: zod.coerce.string().uuid(),
                      email: zod.coerce.string().email(),
                      firstName: zod.coerce
                        .string()
                        .max(
                          projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemFirstNameMax
                        ),
                      lastName: zod.coerce
                        .string()
                        .max(
                          projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemLastNameMax
                        ),
                      privatePhoneNumber: zod.coerce.string().optional(),
                      workPhoneNumber: zod.coerce.string().optional(),
                      status: zod.enum(['Active', 'Terminated']),
                      permissions: zod
                        .array(zod.enum(['admin', 'human-resource', 'user']))
                        .optional(),
                      createdAt: zod.coerce.date(),
                      updatedAt: zod.coerce.date(),
                      contracts: zod.array(
                        zod.object({
                          uuid: zod.coerce.string().uuid(),
                          user: zod.any(),
                          role: zod.object({
                            uuid: zod.coerce.string().uuid(),
                            name: zod.coerce.string(),
                          }),
                          department: zod.object({
                            uuid: zod.coerce.string().uuid(),
                            name: zod.coerce.string(),
                          }),
                          perHourSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemPerHourSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemPerHourSalaryMax
                            )
                            .optional(),
                          monthlyDuration: zod
                            .object({
                              days: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemMonthlyDurationDaysMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemMonthlyDurationDaysMax
                                )
                                .optional(),
                              hours: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemMonthlyDurationHoursMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemMonthlyDurationHoursMax
                                )
                                .optional(),
                              minutes: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemMonthlyDurationMinutesMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemMonthlyDurationMinutesMax
                                )
                                .optional(),
                              seconds: zod.coerce.number().optional(),
                              milliseconds: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemMonthlyDurationMillisecondsMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemMonthlyDurationMillisecondsMax
                                )
                                .optional(),
                            })
                            .optional(),
                          grossSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemGrossSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemGrossSalaryMax
                            ),
                          type: zod.enum(['full-time', 'part-time', 'hourly']),
                          startDate: zod.coerce.date(),
                          endDate: zod.coerce.date().optional(),
                          vacationPerMonth: zod.object({
                            days: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemVacationPerMonthDaysMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemVacationPerMonthDaysMax
                              )
                              .optional(),
                            hours: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemVacationPerMonthHoursMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemVacationPerMonthHoursMax
                              )
                              .optional(),
                            minutes: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemVacationPerMonthMinutesMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemVacationPerMonthMinutesMax
                              )
                              .optional(),
                            seconds: zod.coerce.number().optional(),
                            milliseconds: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemVacationPerMonthMillisecondsMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemVacationPerMonthMillisecondsMax
                              )
                              .optional(),
                          }),
                          attachments: zod.array(
                            zod.object({
                              name: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemAttachmentsItemNameMax
                                ),
                              path: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemAttachmentsItemPathMax
                                ),
                              mimeType: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemAttachmentsItemMimeTypeMax
                                )
                                .optional(),
                              size: zod.coerce.number().optional(),
                              expireAt: zod.coerce.date().optional(),
                              fileUrl: zod.coerce.string(),
                              uuid: zod.coerce.string(),
                              createdAt: zod.coerce.date(),
                              updatedAt: zod.coerce.date(),
                            })
                          ),
                          previousSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemPreviousSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemPreviousSalaryMax
                            ),
                          previousPerHourSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemPreviousPerHourSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemPreviousPerHourSalaryMax
                            ),
                          salaryDiff: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemSalaryDiffMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemContractsItemSalaryDiffMax
                            ),
                          isTypeChanged: zod.coerce.boolean(),
                          previousContractType: zod
                            .enum(['full-time', 'part-time', 'hourly'])
                            .optional(),
                          createdAt: zod.coerce.date(),
                          updatedAt: zod.coerce.date(),
                        })
                      ),
                      currentContract: zod
                        .object({
                          uuid: zod.coerce.string().uuid(),
                          user: zod.any(),
                          role: zod.object({
                            uuid: zod.coerce.string().uuid(),
                            name: zod.coerce.string(),
                          }),
                          department: zod.object({
                            uuid: zod.coerce.string().uuid(),
                            name: zod.coerce.string(),
                          }),
                          perHourSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractPerHourSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractPerHourSalaryMax
                            )
                            .optional(),
                          monthlyDuration: zod
                            .object({
                              days: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractMonthlyDurationDaysMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractMonthlyDurationDaysMax
                                )
                                .optional(),
                              hours: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractMonthlyDurationHoursMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractMonthlyDurationHoursMax
                                )
                                .optional(),
                              minutes: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractMonthlyDurationMinutesMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractMonthlyDurationMinutesMax
                                )
                                .optional(),
                              seconds: zod.coerce.number().optional(),
                              milliseconds: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractMonthlyDurationMillisecondsMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractMonthlyDurationMillisecondsMax
                                )
                                .optional(),
                            })
                            .optional(),
                          grossSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractGrossSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractGrossSalaryMax
                            ),
                          type: zod.enum(['full-time', 'part-time', 'hourly']),
                          startDate: zod.coerce.date(),
                          endDate: zod.coerce.date().optional(),
                          vacationPerMonth: zod.object({
                            days: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractVacationPerMonthDaysMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractVacationPerMonthDaysMax
                              )
                              .optional(),
                            hours: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractVacationPerMonthHoursMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractVacationPerMonthHoursMax
                              )
                              .optional(),
                            minutes: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractVacationPerMonthMinutesMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractVacationPerMonthMinutesMax
                              )
                              .optional(),
                            seconds: zod.coerce.number().optional(),
                            milliseconds: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractVacationPerMonthMillisecondsMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractVacationPerMonthMillisecondsMax
                              )
                              .optional(),
                          }),
                          attachments: zod.array(
                            zod.object({
                              name: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractAttachmentsItemNameMax
                                ),
                              path: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractAttachmentsItemPathMax
                                ),
                              mimeType: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractAttachmentsItemMimeTypeMax
                                )
                                .optional(),
                              size: zod.coerce.number().optional(),
                              expireAt: zod.coerce.date().optional(),
                              fileUrl: zod.coerce.string(),
                              uuid: zod.coerce.string(),
                              createdAt: zod.coerce.date(),
                              updatedAt: zod.coerce.date(),
                            })
                          ),
                          previousSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractPreviousSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractPreviousSalaryMax
                            ),
                          previousPerHourSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractPreviousPerHourSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractPreviousPerHourSalaryMax
                            ),
                          salaryDiff: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractSalaryDiffMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemCurrentContractSalaryDiffMax
                            ),
                          isTypeChanged: zod.coerce.boolean(),
                          previousContractType: zod
                            .enum(['full-time', 'part-time', 'hourly'])
                            .optional(),
                          createdAt: zod.coerce.date(),
                          updatedAt: zod.coerce.date(),
                        })
                        .optional(),
                      salaries: zod.array(
                        zod.object({
                          uuid: zod.coerce.string().uuid(),
                          user: zod.any(),
                          date: zod.coerce.date(),
                          paymentDate: zod.coerce.date().optional(),
                          perHour: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemPerHourMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemPerHourMax
                            )
                            .optional(),
                          monthlyDuration: zod
                            .object({
                              days: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemMonthlyDurationDaysMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemMonthlyDurationDaysMax
                                )
                                .optional(),
                              hours: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemMonthlyDurationHoursMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemMonthlyDurationHoursMax
                                )
                                .optional(),
                              minutes: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemMonthlyDurationMinutesMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemMonthlyDurationMinutesMax
                                )
                                .optional(),
                              seconds: zod.coerce.number().optional(),
                              milliseconds: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemMonthlyDurationMillisecondsMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemMonthlyDurationMillisecondsMax
                                )
                                .optional(),
                            })
                            .optional(),
                          base: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemBaseMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemBaseMax
                            ),
                          bonus: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemBonusMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemBonusMax
                            )
                            .optional(),
                          paid: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemPaidMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemSalariesItemPaidMax
                            ),
                          type: zod.enum(['full-time', 'part-time', 'hourly']),
                          role: zod.object({
                            uuid: zod.coerce.string().uuid(),
                            name: zod.coerce.string(),
                          }),
                          department: zod.object({
                            uuid: zod.coerce.string().uuid(),
                            name: zod.coerce.string(),
                          }),
                          createdAt: zod.coerce.date(),
                          updatedAt: zod.coerce.date(),
                          isMultiContract: zod.coerce.boolean(),
                        })
                      ),
                      hireDate: zod.coerce.date().optional(),
                      profilePicture: zod.coerce.string(),
                      fullName: zod.coerce.string(),
                      leaveTypes: zod.array(
                        zod.object({
                          uuid: zod.coerce.string().uuid(),
                          name: zod.coerce
                            .string()
                            .min(1)
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemLeaveTypesItemNameMax
                            ),
                          description: zod.coerce
                            .string()
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemLeaveTypesItemDescriptionMax
                            )
                            .optional(),
                          color: zod.coerce
                            .string()
                            .min(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemLeaveTypesItemColorMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemLeaveTypesItemColorMax
                            )
                            .regex(
                              projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemLeaveTypesItemColorRegExp
                            )
                            .optional(),
                          allowedPerYear: zod.object({
                            days: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemLeaveTypesItemAllowedPerYearDaysMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemLeaveTypesItemAllowedPerYearDaysMax
                              )
                              .optional(),
                            hours: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemLeaveTypesItemAllowedPerYearHoursMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemLeaveTypesItemAllowedPerYearHoursMax
                              )
                              .optional(),
                            minutes: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemLeaveTypesItemAllowedPerYearMinutesMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemLeaveTypesItemAllowedPerYearMinutesMax
                              )
                              .optional(),
                            seconds: zod.coerce.number().optional(),
                            milliseconds: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemLeaveTypesItemAllowedPerYearMillisecondsMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemAllocationsItemProjectManagersItemLeaveTypesItemAllowedPerYearMillisecondsMax
                              )
                              .optional(),
                          }),
                          isFreebie: zod.coerce.boolean().optional(),
                          isWithAttachment: zod.coerce.boolean().optional(),
                        })
                      ),
                    })
                  ),
                  isProjectManagersEditable: zod.coerce.boolean().optional(),
                  preparations: zod
                    .object({
                      status: zod
                        .enum(['not-started', 'delayed', 'completed'])
                        .optional(),
                      date: zod.coerce.date().optional(),
                      isDateEditable: zod.coerce.boolean().optional(),
                    })
                    .optional(),
                  isPreparationsEditable: zod.coerce.boolean().optional(),
                  dispatch: zod
                    .object({
                      status: zod
                        .enum(['not-started', 'delayed', 'completed'])
                        .optional(),
                      date: zod.coerce.date().optional(),
                      isDateEditable: zod.coerce.boolean().optional(),
                    })
                    .optional(),
                  isDispatchEditable: zod.coerce.boolean().optional(),
                  response: zod
                    .object({
                      status: zod
                        .enum(['not-started', 'delayed', 'completed'])
                        .optional(),
                      date: zod.coerce.date().optional(),
                      isDateEditable: zod.coerce.boolean().optional(),
                    })
                    .optional(),
                  isResponseEditable: zod.coerce.boolean().optional(),
                  price: zod
                    .object({
                      status: zod
                        .enum(['not-started', 'delayed', 'completed'])
                        .optional(),
                      date: zod.coerce.date().optional(),
                      isDateEditable: zod.coerce.boolean().optional(),
                    })
                    .optional(),
                  isPriceEditable: zod.coerce.boolean().optional(),
                  start: zod
                    .object({
                      status: zod
                        .enum(['not-started', 'delayed', 'completed'])
                        .optional(),
                      date: zod.coerce.date().optional(),
                      isDateEditable: zod.coerce.boolean().optional(),
                    })
                    .optional(),
                  isStartEditable: zod.coerce.boolean().optional(),
                  agreedPrice: zod.coerce
                    .number()
                    .min(
                      projectsControllerFindAllResponseDataItemAllocationsItemAgreedPriceMin
                    )
                    .max(
                      projectsControllerFindAllResponseDataItemAllocationsItemAgreedPriceMax
                    )
                    .optional(),
                  isAgreedPriceEditable: zod.coerce.boolean().optional(),
                  initialBudget: zod.coerce
                    .number()
                    .min(
                      projectsControllerFindAllResponseDataItemAllocationsItemInitialBudgetMin
                    )
                    .max(
                      projectsControllerFindAllResponseDataItemAllocationsItemInitialBudgetMax
                    )
                    .optional(),
                  isInitialBudgetEditable: zod.coerce.boolean().optional(),
                  multipleFactor: zod.coerce
                    .number()
                    .min(
                      projectsControllerFindAllResponseDataItemAllocationsItemMultipleFactorMin
                    )
                    .optional(),
                  isMultipleFactorEditable: zod.coerce.boolean().optional(),
                  globalBudget: zod.coerce
                    .number()
                    .min(
                      projectsControllerFindAllResponseDataItemAllocationsItemGlobalBudgetMin
                    )
                    .max(
                      projectsControllerFindAllResponseDataItemAllocationsItemGlobalBudgetMax
                    )
                    .optional(),
                  isGlobalBudgetEditable: zod.coerce.boolean().optional(),
                  discount: zod
                    .object({
                      amount: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemAllocationsItemDiscountAmountMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemAllocationsItemDiscountAmountMax
                        )
                        .optional(),
                      percent: zod.coerce.number().optional(),
                    })
                    .optional(),
                  isDiscountEditable: zod.coerce.boolean().optional(),
                  tax: zod
                    .object({
                      amount: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemAllocationsItemTaxAmountMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemAllocationsItemTaxAmountMax
                        )
                        .optional(),
                      percent: zod.coerce.number().optional(),
                    })
                    .optional(),
                  isTaxEditable: zod.coerce.boolean().optional(),
                  status: zod
                    .enum([
                      'on-track',
                      'canceled',
                      'completed',
                      'delayed',
                      'not-started',
                    ])
                    .optional(),
                  isStatusEditable: zod.coerce.boolean().optional(),
                  uuid: zod.coerce.string(),
                  createdAt: zod.coerce.date(),
                  updatedAt: zod.coerce.date(),
                })
              )
              .optional(),
            milestones: zod
              .array(
                zod.object({
                  uuid: zod.coerce.string().uuid(),
                  date: zod.coerce.date(),
                  iconUrl: zod.coerce.string().nullable(),
                  description: zod.coerce.string().nullable(),
                  projectUuid: zod.coerce.string().uuid(),
                  createdAt: zod.coerce.date(),
                  updatedAt: zod.coerce.date(),
                  project: zod.any(),
                })
              )
              .optional(),
            roadmaps: zod
              .array(
                zod.object({
                  uuid: zod.coerce.string().uuid(),
                  startAt: zod.coerce.date(),
                  endAt: zod.coerce.date(),
                  isVisible: zod.coerce.boolean().optional(),
                  project: zod.any(),
                  tag: zod.object({
                    uuid: zod.coerce.string().uuid(),
                    parent: zod.any().nullish(),
                    children: zod.array(zod.any()).optional(),
                    name: zod.coerce
                      .string()
                      .max(
                        projectsControllerFindAllResponseDataItemRoadmapsItemTagNameMax
                      ),
                    companyType: zod.enum([
                      'Client',
                      'Contractor',
                      'Purchase',
                      'Surcharge',
                      'Creditor',
                    ]),
                    serial: zod.coerce.string().optional(),
                    priority: zod.coerce.number().nullish(),
                    aliases: zod
                      .array(
                        zod.object({
                          uuid: zod.coerce.string().uuid(),
                          isAbbr: zod.coerce.boolean(),
                          tag: zod.any(),
                          nameAlias: zod.coerce
                            .string()
                            .min(1)
                            .max(
                              projectsControllerFindAllResponseDataItemRoadmapsItemTagAliasesItemNameAliasMax
                            ),
                          language: zod.coerce.string().optional(),
                        })
                      )
                      .optional(),
                  }),
                  color: zod.coerce
                    .string()
                    .min(
                      projectsControllerFindAllResponseDataItemRoadmapsItemColorMin
                    )
                    .max(
                      projectsControllerFindAllResponseDataItemRoadmapsItemColorMax
                    )
                    .nullish(),
                  items: zod.array(
                    zod.object({
                      uuid: zod.coerce.string().uuid(),
                      color: zod.coerce
                        .string()
                        .min(
                          projectsControllerFindAllResponseDataItemRoadmapsItemItemsItemColorMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemRoadmapsItemItemsItemColorMax
                        )
                        .nullish(),
                      startAt: zod.coerce.date(),
                      endAt: zod.coerce.date(),
                      description: zod.coerce.string().nullable(),
                      roadmap: zod.any().optional(),
                    })
                  ),
                })
              )
              .optional(),
            contracts: zod
              .array(
                zod.object({
                  uuid: zod.coerce.string().uuid(),
                  description: zod.coerce.string().nullable(),
                  project: zod.any(),
                  internalProjectManager: zod
                    .object({
                      uuid: zod.coerce.string().uuid(),
                      email: zod.coerce.string().email(),
                      firstName: zod.coerce
                        .string()
                        .max(
                          projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerFirstNameMax
                        ),
                      lastName: zod.coerce
                        .string()
                        .max(
                          projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerLastNameMax
                        ),
                      privatePhoneNumber: zod.coerce.string().optional(),
                      workPhoneNumber: zod.coerce.string().optional(),
                      status: zod.enum(['Active', 'Terminated']),
                      permissions: zod
                        .array(zod.enum(['admin', 'human-resource', 'user']))
                        .optional(),
                      createdAt: zod.coerce.date(),
                      updatedAt: zod.coerce.date(),
                      contracts: zod.array(
                        zod.object({
                          uuid: zod.coerce.string().uuid(),
                          user: zod.any(),
                          role: zod.object({
                            uuid: zod.coerce.string().uuid(),
                            name: zod.coerce.string(),
                          }),
                          department: zod.object({
                            uuid: zod.coerce.string().uuid(),
                            name: zod.coerce.string(),
                          }),
                          perHourSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemPerHourSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemPerHourSalaryMax
                            )
                            .optional(),
                          monthlyDuration: zod
                            .object({
                              days: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemMonthlyDurationDaysMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemMonthlyDurationDaysMax
                                )
                                .optional(),
                              hours: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemMonthlyDurationHoursMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemMonthlyDurationHoursMax
                                )
                                .optional(),
                              minutes: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemMonthlyDurationMinutesMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemMonthlyDurationMinutesMax
                                )
                                .optional(),
                              seconds: zod.coerce.number().optional(),
                              milliseconds: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemMonthlyDurationMillisecondsMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemMonthlyDurationMillisecondsMax
                                )
                                .optional(),
                            })
                            .optional(),
                          grossSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemGrossSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemGrossSalaryMax
                            ),
                          type: zod.enum(['full-time', 'part-time', 'hourly']),
                          startDate: zod.coerce.date(),
                          endDate: zod.coerce.date().optional(),
                          vacationPerMonth: zod.object({
                            days: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemVacationPerMonthDaysMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemVacationPerMonthDaysMax
                              )
                              .optional(),
                            hours: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemVacationPerMonthHoursMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemVacationPerMonthHoursMax
                              )
                              .optional(),
                            minutes: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemVacationPerMonthMinutesMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemVacationPerMonthMinutesMax
                              )
                              .optional(),
                            seconds: zod.coerce.number().optional(),
                            milliseconds: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemVacationPerMonthMillisecondsMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemVacationPerMonthMillisecondsMax
                              )
                              .optional(),
                          }),
                          attachments: zod.array(
                            zod.object({
                              name: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemAttachmentsItemNameMax
                                ),
                              path: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemAttachmentsItemPathMax
                                ),
                              mimeType: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemAttachmentsItemMimeTypeMax
                                )
                                .optional(),
                              size: zod.coerce.number().optional(),
                              expireAt: zod.coerce.date().optional(),
                              fileUrl: zod.coerce.string(),
                              uuid: zod.coerce.string(),
                              createdAt: zod.coerce.date(),
                              updatedAt: zod.coerce.date(),
                            })
                          ),
                          previousSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemPreviousSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemPreviousSalaryMax
                            ),
                          previousPerHourSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemPreviousPerHourSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemPreviousPerHourSalaryMax
                            ),
                          salaryDiff: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemSalaryDiffMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerContractsItemSalaryDiffMax
                            ),
                          isTypeChanged: zod.coerce.boolean(),
                          previousContractType: zod
                            .enum(['full-time', 'part-time', 'hourly'])
                            .optional(),
                          createdAt: zod.coerce.date(),
                          updatedAt: zod.coerce.date(),
                        })
                      ),
                      currentContract: zod
                        .object({
                          uuid: zod.coerce.string().uuid(),
                          user: zod.any(),
                          role: zod.object({
                            uuid: zod.coerce.string().uuid(),
                            name: zod.coerce.string(),
                          }),
                          department: zod.object({
                            uuid: zod.coerce.string().uuid(),
                            name: zod.coerce.string(),
                          }),
                          perHourSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractPerHourSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractPerHourSalaryMax
                            )
                            .optional(),
                          monthlyDuration: zod
                            .object({
                              days: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractMonthlyDurationDaysMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractMonthlyDurationDaysMax
                                )
                                .optional(),
                              hours: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractMonthlyDurationHoursMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractMonthlyDurationHoursMax
                                )
                                .optional(),
                              minutes: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractMonthlyDurationMinutesMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractMonthlyDurationMinutesMax
                                )
                                .optional(),
                              seconds: zod.coerce.number().optional(),
                              milliseconds: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractMonthlyDurationMillisecondsMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractMonthlyDurationMillisecondsMax
                                )
                                .optional(),
                            })
                            .optional(),
                          grossSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractGrossSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractGrossSalaryMax
                            ),
                          type: zod.enum(['full-time', 'part-time', 'hourly']),
                          startDate: zod.coerce.date(),
                          endDate: zod.coerce.date().optional(),
                          vacationPerMonth: zod.object({
                            days: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractVacationPerMonthDaysMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractVacationPerMonthDaysMax
                              )
                              .optional(),
                            hours: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractVacationPerMonthHoursMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractVacationPerMonthHoursMax
                              )
                              .optional(),
                            minutes: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractVacationPerMonthMinutesMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractVacationPerMonthMinutesMax
                              )
                              .optional(),
                            seconds: zod.coerce.number().optional(),
                            milliseconds: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractVacationPerMonthMillisecondsMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractVacationPerMonthMillisecondsMax
                              )
                              .optional(),
                          }),
                          attachments: zod.array(
                            zod.object({
                              name: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractAttachmentsItemNameMax
                                ),
                              path: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractAttachmentsItemPathMax
                                ),
                              mimeType: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractAttachmentsItemMimeTypeMax
                                )
                                .optional(),
                              size: zod.coerce.number().optional(),
                              expireAt: zod.coerce.date().optional(),
                              fileUrl: zod.coerce.string(),
                              uuid: zod.coerce.string(),
                              createdAt: zod.coerce.date(),
                              updatedAt: zod.coerce.date(),
                            })
                          ),
                          previousSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractPreviousSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractPreviousSalaryMax
                            ),
                          previousPerHourSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractPreviousPerHourSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractPreviousPerHourSalaryMax
                            ),
                          salaryDiff: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractSalaryDiffMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerCurrentContractSalaryDiffMax
                            ),
                          isTypeChanged: zod.coerce.boolean(),
                          previousContractType: zod
                            .enum(['full-time', 'part-time', 'hourly'])
                            .optional(),
                          createdAt: zod.coerce.date(),
                          updatedAt: zod.coerce.date(),
                        })
                        .optional(),
                      salaries: zod.array(
                        zod.object({
                          uuid: zod.coerce.string().uuid(),
                          user: zod.any(),
                          date: zod.coerce.date(),
                          paymentDate: zod.coerce.date().optional(),
                          perHour: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemPerHourMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemPerHourMax
                            )
                            .optional(),
                          monthlyDuration: zod
                            .object({
                              days: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemMonthlyDurationDaysMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemMonthlyDurationDaysMax
                                )
                                .optional(),
                              hours: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemMonthlyDurationHoursMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemMonthlyDurationHoursMax
                                )
                                .optional(),
                              minutes: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemMonthlyDurationMinutesMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemMonthlyDurationMinutesMax
                                )
                                .optional(),
                              seconds: zod.coerce.number().optional(),
                              milliseconds: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemMonthlyDurationMillisecondsMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemMonthlyDurationMillisecondsMax
                                )
                                .optional(),
                            })
                            .optional(),
                          base: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemBaseMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemBaseMax
                            ),
                          bonus: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemBonusMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemBonusMax
                            )
                            .optional(),
                          paid: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemPaidMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerSalariesItemPaidMax
                            ),
                          type: zod.enum(['full-time', 'part-time', 'hourly']),
                          role: zod.object({
                            uuid: zod.coerce.string().uuid(),
                            name: zod.coerce.string(),
                          }),
                          department: zod.object({
                            uuid: zod.coerce.string().uuid(),
                            name: zod.coerce.string(),
                          }),
                          createdAt: zod.coerce.date(),
                          updatedAt: zod.coerce.date(),
                          isMultiContract: zod.coerce.boolean(),
                        })
                      ),
                      hireDate: zod.coerce.date().optional(),
                      profilePicture: zod.coerce.string(),
                      fullName: zod.coerce.string(),
                      leaveTypes: zod.array(
                        zod.object({
                          uuid: zod.coerce.string().uuid(),
                          name: zod.coerce
                            .string()
                            .min(1)
                            .max(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerLeaveTypesItemNameMax
                            ),
                          description: zod.coerce
                            .string()
                            .max(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerLeaveTypesItemDescriptionMax
                            )
                            .optional(),
                          color: zod.coerce
                            .string()
                            .min(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerLeaveTypesItemColorMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerLeaveTypesItemColorMax
                            )
                            .regex(
                              projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerLeaveTypesItemColorRegExp
                            )
                            .optional(),
                          allowedPerYear: zod.object({
                            days: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearDaysMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearDaysMax
                              )
                              .optional(),
                            hours: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearHoursMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearHoursMax
                              )
                              .optional(),
                            minutes: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearMinutesMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearMinutesMax
                              )
                              .optional(),
                            seconds: zod.coerce.number().optional(),
                            milliseconds: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearMillisecondsMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemContractsItemInternalProjectManagerLeaveTypesItemAllowedPerYearMillisecondsMax
                              )
                              .optional(),
                          }),
                          isFreebie: zod.coerce.boolean().optional(),
                          isWithAttachment: zod.coerce.boolean().optional(),
                        })
                      ),
                    })
                    .nullable(),
                  externalProjectManager: zod
                    .object({
                      company: zod
                        .object({
                          uuid: zod.coerce.string().uuid(),
                          serial: zod.coerce
                            .string()
                            .max(
                              projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerCompanySerialMax
                            )
                            .nullish(),
                          name: zod.coerce
                            .string()
                            .max(
                              projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerCompanyNameMax
                            ),
                          address: zod
                            .object({
                              uuid: zod.coerce.string().uuid(),
                              country: zod.coerce.string().optional(),
                              city: zod.coerce.string().nullish(),
                              street: zod.coerce.string().nullish(),
                              postcode: zod.coerce.string().nullish(),
                              lat: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerCompanyAddressLatMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerCompanyAddressLatMax
                                )
                                .nullish(),
                              lon: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerCompanyAddressLonMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerCompanyAddressLonMax
                                )
                                .nullish(),
                            })
                            .nullish(),
                          phoneNumber: zod.coerce.string().nullish(),
                          email: zod.coerce.string().email().nullish(),
                          website: zod.coerce.string().url().nullable(),
                          logo: zod.coerce.string().optional(),
                          companyType: zod.enum([
                            'Client',
                            'Contractor',
                            'Purchase',
                            'Surcharge',
                            'Creditor',
                          ]),
                          tag: zod
                            .object({
                              uuid: zod.coerce.string().uuid(),
                              parent: zod.any().nullish(),
                              children: zod.array(zod.any()).optional(),
                              name: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerCompanyTagNameMax
                                ),
                              companyType: zod.enum([
                                'Client',
                                'Contractor',
                                'Purchase',
                                'Surcharge',
                                'Creditor',
                              ]),
                              serial: zod.coerce.string().optional(),
                              priority: zod.coerce.number().nullish(),
                              aliases: zod
                                .array(
                                  zod.object({
                                    uuid: zod.coerce.string().uuid(),
                                    isAbbr: zod.coerce.boolean(),
                                    tag: zod.any(),
                                    nameAlias: zod.coerce
                                      .string()
                                      .min(1)
                                      .max(
                                        projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerCompanyTagAliasesItemNameAliasMax
                                      ),
                                    language: zod.coerce.string().optional(),
                                  })
                                )
                                .optional(),
                            })
                            .nullish(),
                          rating: zod.coerce
                            .number()
                            .min(1)
                            .max(
                              projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerCompanyRatingMax
                            )
                            .nullish(),
                          companySize: zod.coerce.number().min(1).nullish(),
                          maxDistanceToProject: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerCompanyMaxDistanceToProjectMin
                            )
                            .optional(),
                          createdAt: zod.coerce.date(),
                          updatedAt: zod.coerce.date(),
                          contracts: zod.array(zod.any()).optional(),
                          relatedProjects: zod.array(zod.any()),
                          subContractorCost: zod
                            .array(
                              zod.object({
                                uuid: zod.coerce.string(),
                                project: zod.any(),
                                tag: zod.object({
                                  uuid: zod.coerce.string().uuid(),
                                  parent: zod.any().nullish(),
                                  children: zod.array(zod.any()).optional(),
                                  name: zod.coerce
                                    .string()
                                    .max(
                                      projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerCompanySubContractorCostItemTagNameMax
                                    ),
                                  companyType: zod.enum([
                                    'Client',
                                    'Contractor',
                                    'Purchase',
                                    'Surcharge',
                                    'Creditor',
                                  ]),
                                  serial: zod.coerce.string().optional(),
                                  priority: zod.coerce.number().nullish(),
                                  aliases: zod
                                    .array(
                                      zod.object({
                                        uuid: zod.coerce.string().uuid(),
                                        isAbbr: zod.coerce.boolean(),
                                        tag: zod.any(),
                                        nameAlias: zod.coerce
                                          .string()
                                          .min(1)
                                          .max(
                                            projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerCompanySubContractorCostItemTagAliasesItemNameAliasMax
                                          ),
                                        language: zod.coerce
                                          .string()
                                          .optional(),
                                      })
                                    )
                                    .optional(),
                                }),
                                rowNumber: zod.coerce.string(),
                                sumSub: zod.coerce.number().nullable(),
                                createdAt: zod.coerce.date(),
                                updatedAt: zod.coerce.date(),
                                type: zod.coerce.string(),
                                items: zod.array(
                                  zod.object({
                                    uuid: zod.coerce.string(),
                                    additionReduceCost: zod.any(),
                                    rowNumber: zod.coerce.string(),
                                    description: zod.coerce.string().optional(),
                                    quantity: zod.coerce.number(),
                                    unit: zod.coerce.string(),
                                    unitPriceSub: zod.coerce.number(),
                                    sumSub: zod.coerce.number(),
                                    unitPriceGcPercent: zod.coerce
                                      .number()
                                      .min(
                                        projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerCompanySubContractorCostItemItemsItemUnitPriceGcPercentMin
                                      )
                                      .max(
                                        projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerCompanySubContractorCostItemItemsItemUnitPriceGcPercentMax
                                      ),
                                    unitPriceGc: zod.coerce.number().optional(),
                                    sumGc: zod.coerce.number(),
                                    differenceGcSub: zod.coerce
                                      .number()
                                      .nullish(),
                                    instructedSum: zod.coerce.number(),
                                    status: zod.enum([
                                      'open',
                                      'rejected',
                                      'instructed',
                                      'paid',
                                    ]),
                                    createdAt: zod.coerce.date(),
                                    updatedAt: zod.coerce.date(),
                                    additionDate: zod.coerce.date().nullable(),
                                    additionTitle: zod.coerce
                                      .string()
                                      .nullable(),
                                    additionNumber: zod.coerce
                                      .string()
                                      .nullable(),
                                    authorizedAmount: zod.coerce
                                      .number()
                                      .nullable(),
                                    remarks: zod.coerce.string().nullable(),
                                  })
                                ),
                              })
                            )
                            .optional(),
                        })
                        .optional(),
                      contracts: zod.array(zod.any()).optional(),
                      firstName: zod.coerce
                        .string()
                        .max(
                          projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerFirstNameMax
                        ),
                      lastName: zod.coerce
                        .string()
                        .max(
                          projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerLastNameMax
                        ),
                      phoneNumber: zod.coerce.string().nullable(),
                      email: zod.coerce.string().email().nullable(),
                      description: zod.coerce.string(),
                      website: zod.coerce.string().url().nullable(),
                      fullName: zod.coerce.string(),
                      profilePicture: zod.coerce.string(),
                      relatedProjects: zod.array(zod.any()),
                      uuid: zod.coerce.string().uuid(),
                      address: zod.object({
                        uuid: zod.coerce.string().uuid(),
                        country: zod.coerce.string().optional(),
                        city: zod.coerce.string().nullish(),
                        street: zod.coerce.string().nullish(),
                        postcode: zod.coerce.string().nullish(),
                        lat: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerAddressLatMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerAddressLatMax
                          )
                          .nullish(),
                        lon: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerAddressLonMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemContractsItemExternalProjectManagerAddressLonMax
                          )
                          .nullish(),
                      }),
                      createdAt: zod.coerce.date(),
                      updatedAt: zod.coerce.date(),
                    })
                    .nullable(),
                  people: zod
                    .array(
                      zod.object({
                        company: zod
                          .object({
                            uuid: zod.coerce.string().uuid(),
                            serial: zod.coerce
                              .string()
                              .max(
                                projectsControllerFindAllResponseDataItemContractsItemPeopleItemCompanySerialMax
                              )
                              .nullish(),
                            name: zod.coerce
                              .string()
                              .max(
                                projectsControllerFindAllResponseDataItemContractsItemPeopleItemCompanyNameMax
                              ),
                            address: zod
                              .object({
                                uuid: zod.coerce.string().uuid(),
                                country: zod.coerce.string().optional(),
                                city: zod.coerce.string().nullish(),
                                street: zod.coerce.string().nullish(),
                                postcode: zod.coerce.string().nullish(),
                                lat: zod.coerce
                                  .number()
                                  .min(
                                    projectsControllerFindAllResponseDataItemContractsItemPeopleItemCompanyAddressLatMin
                                  )
                                  .max(
                                    projectsControllerFindAllResponseDataItemContractsItemPeopleItemCompanyAddressLatMax
                                  )
                                  .nullish(),
                                lon: zod.coerce
                                  .number()
                                  .min(
                                    projectsControllerFindAllResponseDataItemContractsItemPeopleItemCompanyAddressLonMin
                                  )
                                  .max(
                                    projectsControllerFindAllResponseDataItemContractsItemPeopleItemCompanyAddressLonMax
                                  )
                                  .nullish(),
                              })
                              .nullish(),
                            phoneNumber: zod.coerce.string().nullish(),
                            email: zod.coerce.string().email().nullish(),
                            website: zod.coerce.string().url().nullable(),
                            logo: zod.coerce.string().optional(),
                            companyType: zod.enum([
                              'Client',
                              'Contractor',
                              'Purchase',
                              'Surcharge',
                              'Creditor',
                            ]),
                            tag: zod
                              .object({
                                uuid: zod.coerce.string().uuid(),
                                parent: zod.any().nullish(),
                                children: zod.array(zod.any()).optional(),
                                name: zod.coerce
                                  .string()
                                  .max(
                                    projectsControllerFindAllResponseDataItemContractsItemPeopleItemCompanyTagNameMax
                                  ),
                                companyType: zod.enum([
                                  'Client',
                                  'Contractor',
                                  'Purchase',
                                  'Surcharge',
                                  'Creditor',
                                ]),
                                serial: zod.coerce.string().optional(),
                                priority: zod.coerce.number().nullish(),
                                aliases: zod
                                  .array(
                                    zod.object({
                                      uuid: zod.coerce.string().uuid(),
                                      isAbbr: zod.coerce.boolean(),
                                      tag: zod.any(),
                                      nameAlias: zod.coerce
                                        .string()
                                        .min(1)
                                        .max(
                                          projectsControllerFindAllResponseDataItemContractsItemPeopleItemCompanyTagAliasesItemNameAliasMax
                                        ),
                                      language: zod.coerce.string().optional(),
                                    })
                                  )
                                  .optional(),
                              })
                              .nullish(),
                            rating: zod.coerce
                              .number()
                              .min(1)
                              .max(
                                projectsControllerFindAllResponseDataItemContractsItemPeopleItemCompanyRatingMax
                              )
                              .nullish(),
                            companySize: zod.coerce.number().min(1).nullish(),
                            maxDistanceToProject: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemContractsItemPeopleItemCompanyMaxDistanceToProjectMin
                              )
                              .optional(),
                            createdAt: zod.coerce.date(),
                            updatedAt: zod.coerce.date(),
                            contracts: zod.array(zod.any()).optional(),
                            relatedProjects: zod.array(zod.any()),
                            subContractorCost: zod
                              .array(
                                zod.object({
                                  uuid: zod.coerce.string(),
                                  project: zod.any(),
                                  tag: zod.object({
                                    uuid: zod.coerce.string().uuid(),
                                    parent: zod.any().nullish(),
                                    children: zod.array(zod.any()).optional(),
                                    name: zod.coerce
                                      .string()
                                      .max(
                                        projectsControllerFindAllResponseDataItemContractsItemPeopleItemCompanySubContractorCostItemTagNameMax
                                      ),
                                    companyType: zod.enum([
                                      'Client',
                                      'Contractor',
                                      'Purchase',
                                      'Surcharge',
                                      'Creditor',
                                    ]),
                                    serial: zod.coerce.string().optional(),
                                    priority: zod.coerce.number().nullish(),
                                    aliases: zod
                                      .array(
                                        zod.object({
                                          uuid: zod.coerce.string().uuid(),
                                          isAbbr: zod.coerce.boolean(),
                                          tag: zod.any(),
                                          nameAlias: zod.coerce
                                            .string()
                                            .min(1)
                                            .max(
                                              projectsControllerFindAllResponseDataItemContractsItemPeopleItemCompanySubContractorCostItemTagAliasesItemNameAliasMax
                                            ),
                                          language: zod.coerce
                                            .string()
                                            .optional(),
                                        })
                                      )
                                      .optional(),
                                  }),
                                  rowNumber: zod.coerce.string(),
                                  sumSub: zod.coerce.number().nullable(),
                                  createdAt: zod.coerce.date(),
                                  updatedAt: zod.coerce.date(),
                                  type: zod.coerce.string(),
                                  items: zod.array(
                                    zod.object({
                                      uuid: zod.coerce.string(),
                                      additionReduceCost: zod.any(),
                                      rowNumber: zod.coerce.string(),
                                      description: zod.coerce
                                        .string()
                                        .optional(),
                                      quantity: zod.coerce.number(),
                                      unit: zod.coerce.string(),
                                      unitPriceSub: zod.coerce.number(),
                                      sumSub: zod.coerce.number(),
                                      unitPriceGcPercent: zod.coerce
                                        .number()
                                        .min(
                                          projectsControllerFindAllResponseDataItemContractsItemPeopleItemCompanySubContractorCostItemItemsItemUnitPriceGcPercentMin
                                        )
                                        .max(
                                          projectsControllerFindAllResponseDataItemContractsItemPeopleItemCompanySubContractorCostItemItemsItemUnitPriceGcPercentMax
                                        ),
                                      unitPriceGc: zod.coerce
                                        .number()
                                        .optional(),
                                      sumGc: zod.coerce.number(),
                                      differenceGcSub: zod.coerce
                                        .number()
                                        .nullish(),
                                      instructedSum: zod.coerce.number(),
                                      status: zod.enum([
                                        'open',
                                        'rejected',
                                        'instructed',
                                        'paid',
                                      ]),
                                      createdAt: zod.coerce.date(),
                                      updatedAt: zod.coerce.date(),
                                      additionDate: zod.coerce
                                        .date()
                                        .nullable(),
                                      additionTitle: zod.coerce
                                        .string()
                                        .nullable(),
                                      additionNumber: zod.coerce
                                        .string()
                                        .nullable(),
                                      authorizedAmount: zod.coerce
                                        .number()
                                        .nullable(),
                                      remarks: zod.coerce.string().nullable(),
                                    })
                                  ),
                                })
                              )
                              .optional(),
                          })
                          .optional(),
                        contracts: zod.array(zod.any()).optional(),
                        firstName: zod.coerce
                          .string()
                          .max(
                            projectsControllerFindAllResponseDataItemContractsItemPeopleItemFirstNameMax
                          ),
                        lastName: zod.coerce
                          .string()
                          .max(
                            projectsControllerFindAllResponseDataItemContractsItemPeopleItemLastNameMax
                          ),
                        phoneNumber: zod.coerce.string().nullable(),
                        email: zod.coerce.string().email().nullable(),
                        description: zod.coerce.string(),
                        website: zod.coerce.string().url().nullable(),
                        fullName: zod.coerce.string(),
                        profilePicture: zod.coerce.string(),
                        relatedProjects: zod.array(zod.any()),
                        uuid: zod.coerce.string().uuid(),
                        address: zod.object({
                          uuid: zod.coerce.string().uuid(),
                          country: zod.coerce.string().optional(),
                          city: zod.coerce.string().nullish(),
                          street: zod.coerce.string().nullish(),
                          postcode: zod.coerce.string().nullish(),
                          lat: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemContractsItemPeopleItemAddressLatMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemContractsItemPeopleItemAddressLatMax
                            )
                            .nullish(),
                          lon: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemContractsItemPeopleItemAddressLonMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemContractsItemPeopleItemAddressLonMax
                            )
                            .nullish(),
                        }),
                        createdAt: zod.coerce.date(),
                        updatedAt: zod.coerce.date(),
                      })
                    )
                    .optional(),
                  minNumberOfWorkersOnSite: zod.coerce
                    .number()
                    .min(
                      projectsControllerFindAllResponseDataItemContractsItemMinNumberOfWorkersOnSiteMin
                    )
                    .max(
                      projectsControllerFindAllResponseDataItemContractsItemMinNumberOfWorkersOnSiteMax
                    )
                    .nullable(),
                  maxNumberOfWorkersOnSite: zod.coerce
                    .number()
                    .min(
                      projectsControllerFindAllResponseDataItemContractsItemMaxNumberOfWorkersOnSiteMin
                    ),
                  startDate: zod.coerce.date().optional(),
                  signDate: zod.coerce.date().optional(),
                  deadline: zod.coerce.date().optional(),
                  company: zod
                    .object({
                      uuid: zod.coerce.string().uuid(),
                      serial: zod.coerce
                        .string()
                        .max(
                          projectsControllerFindAllResponseDataItemContractsItemCompanySerialMax
                        )
                        .nullish(),
                      name: zod.coerce
                        .string()
                        .max(
                          projectsControllerFindAllResponseDataItemContractsItemCompanyNameMax
                        ),
                      address: zod
                        .object({
                          uuid: zod.coerce.string().uuid(),
                          country: zod.coerce.string().optional(),
                          city: zod.coerce.string().nullish(),
                          street: zod.coerce.string().nullish(),
                          postcode: zod.coerce.string().nullish(),
                          lat: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemContractsItemCompanyAddressLatMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemContractsItemCompanyAddressLatMax
                            )
                            .nullish(),
                          lon: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemContractsItemCompanyAddressLonMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemContractsItemCompanyAddressLonMax
                            )
                            .nullish(),
                        })
                        .nullish(),
                      phoneNumber: zod.coerce.string().nullish(),
                      email: zod.coerce.string().email().nullish(),
                      website: zod.coerce.string().url().nullable(),
                      logo: zod.coerce.string().optional(),
                      companyType: zod.enum([
                        'Client',
                        'Contractor',
                        'Purchase',
                        'Surcharge',
                        'Creditor',
                      ]),
                      tag: zod
                        .object({
                          uuid: zod.coerce.string().uuid(),
                          parent: zod.any().nullish(),
                          children: zod.array(zod.any()).optional(),
                          name: zod.coerce
                            .string()
                            .max(
                              projectsControllerFindAllResponseDataItemContractsItemCompanyTagNameMax
                            ),
                          companyType: zod.enum([
                            'Client',
                            'Contractor',
                            'Purchase',
                            'Surcharge',
                            'Creditor',
                          ]),
                          serial: zod.coerce.string().optional(),
                          priority: zod.coerce.number().nullish(),
                          aliases: zod
                            .array(
                              zod.object({
                                uuid: zod.coerce.string().uuid(),
                                isAbbr: zod.coerce.boolean(),
                                tag: zod.any(),
                                nameAlias: zod.coerce
                                  .string()
                                  .min(1)
                                  .max(
                                    projectsControllerFindAllResponseDataItemContractsItemCompanyTagAliasesItemNameAliasMax
                                  ),
                                language: zod.coerce.string().optional(),
                              })
                            )
                            .optional(),
                        })
                        .nullish(),
                      rating: zod.coerce
                        .number()
                        .min(1)
                        .max(
                          projectsControllerFindAllResponseDataItemContractsItemCompanyRatingMax
                        )
                        .nullish(),
                      companySize: zod.coerce.number().min(1).nullish(),
                      maxDistanceToProject: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemContractsItemCompanyMaxDistanceToProjectMin
                        )
                        .optional(),
                      createdAt: zod.coerce.date(),
                      updatedAt: zod.coerce.date(),
                      contracts: zod.array(zod.any()).optional(),
                      relatedProjects: zod.array(zod.any()),
                      subContractorCost: zod
                        .array(
                          zod.object({
                            uuid: zod.coerce.string(),
                            project: zod.any(),
                            tag: zod.object({
                              uuid: zod.coerce.string().uuid(),
                              parent: zod.any().nullish(),
                              children: zod.array(zod.any()).optional(),
                              name: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemContractsItemCompanySubContractorCostItemTagNameMax
                                ),
                              companyType: zod.enum([
                                'Client',
                                'Contractor',
                                'Purchase',
                                'Surcharge',
                                'Creditor',
                              ]),
                              serial: zod.coerce.string().optional(),
                              priority: zod.coerce.number().nullish(),
                              aliases: zod
                                .array(
                                  zod.object({
                                    uuid: zod.coerce.string().uuid(),
                                    isAbbr: zod.coerce.boolean(),
                                    tag: zod.any(),
                                    nameAlias: zod.coerce
                                      .string()
                                      .min(1)
                                      .max(
                                        projectsControllerFindAllResponseDataItemContractsItemCompanySubContractorCostItemTagAliasesItemNameAliasMax
                                      ),
                                    language: zod.coerce.string().optional(),
                                  })
                                )
                                .optional(),
                            }),
                            rowNumber: zod.coerce.string(),
                            sumSub: zod.coerce.number().nullable(),
                            createdAt: zod.coerce.date(),
                            updatedAt: zod.coerce.date(),
                            type: zod.coerce.string(),
                            items: zod.array(
                              zod.object({
                                uuid: zod.coerce.string(),
                                additionReduceCost: zod.any(),
                                rowNumber: zod.coerce.string(),
                                description: zod.coerce.string().optional(),
                                quantity: zod.coerce.number(),
                                unit: zod.coerce.string(),
                                unitPriceSub: zod.coerce.number(),
                                sumSub: zod.coerce.number(),
                                unitPriceGcPercent: zod.coerce
                                  .number()
                                  .min(
                                    projectsControllerFindAllResponseDataItemContractsItemCompanySubContractorCostItemItemsItemUnitPriceGcPercentMin
                                  )
                                  .max(
                                    projectsControllerFindAllResponseDataItemContractsItemCompanySubContractorCostItemItemsItemUnitPriceGcPercentMax
                                  ),
                                unitPriceGc: zod.coerce.number().optional(),
                                sumGc: zod.coerce.number(),
                                differenceGcSub: zod.coerce.number().nullish(),
                                instructedSum: zod.coerce.number(),
                                status: zod.enum([
                                  'open',
                                  'rejected',
                                  'instructed',
                                  'paid',
                                ]),
                                createdAt: zod.coerce.date(),
                                updatedAt: zod.coerce.date(),
                                additionDate: zod.coerce.date().nullable(),
                                additionTitle: zod.coerce.string().nullable(),
                                additionNumber: zod.coerce.string().nullable(),
                                authorizedAmount: zod.coerce
                                  .number()
                                  .nullable(),
                                remarks: zod.coerce.string().nullable(),
                              })
                            ),
                          })
                        )
                        .optional(),
                    })
                    .optional(),
                  netPrice: zod.coerce
                    .number()
                    .min(
                      projectsControllerFindAllResponseDataItemContractsItemNetPriceMin
                    )
                    .max(
                      projectsControllerFindAllResponseDataItemContractsItemNetPriceMax
                    )
                    .optional(),
                  tagUuid: zod.coerce.string().uuid(),
                  tag: zod
                    .object({
                      uuid: zod.coerce.string().uuid(),
                      parent: zod.any().nullish(),
                      children: zod.array(zod.any()).optional(),
                      name: zod.coerce
                        .string()
                        .max(
                          projectsControllerFindAllResponseDataItemContractsItemTagNameMax
                        ),
                      companyType: zod.enum([
                        'Client',
                        'Contractor',
                        'Purchase',
                        'Surcharge',
                        'Creditor',
                      ]),
                      serial: zod.coerce.string().optional(),
                      priority: zod.coerce.number().nullish(),
                      aliases: zod
                        .array(
                          zod.object({
                            uuid: zod.coerce.string().uuid(),
                            isAbbr: zod.coerce.boolean(),
                            tag: zod.any(),
                            nameAlias: zod.coerce
                              .string()
                              .min(1)
                              .max(
                                projectsControllerFindAllResponseDataItemContractsItemTagAliasesItemNameAliasMax
                              ),
                            language: zod.coerce.string().optional(),
                          })
                        )
                        .optional(),
                    })
                    .nullable(),
                  warrantyReserve: zod.coerce
                    .number()
                    .min(
                      projectsControllerFindAllResponseDataItemContractsItemWarrantyReserveMin
                    )
                    .max(
                      projectsControllerFindAllResponseDataItemContractsItemWarrantyReserveMax
                    )
                    .nullable(),
                  umlage: zod
                    .object({
                      amount: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemContractsItemUmlageAmountMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemContractsItemUmlageAmountMax
                        )
                        .optional(),
                      percent: zod.coerce.number().optional(),
                    })
                    .optional(),
                  skonto: zod
                    .object({
                      amount: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemContractsItemSkontoAmountMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemContractsItemSkontoAmountMax
                        )
                        .optional(),
                      percent: zod.coerce.number().optional(),
                    })
                    .optional(),
                  attachments: zod.array(
                    zod.object({
                      name: zod.coerce
                        .string()
                        .max(
                          projectsControllerFindAllResponseDataItemContractsItemAttachmentsItemNameMax
                        ),
                      path: zod.coerce
                        .string()
                        .max(
                          projectsControllerFindAllResponseDataItemContractsItemAttachmentsItemPathMax
                        ),
                      mimeType: zod.coerce
                        .string()
                        .max(
                          projectsControllerFindAllResponseDataItemContractsItemAttachmentsItemMimeTypeMax
                        )
                        .optional(),
                      size: zod.coerce.number().optional(),
                      expireAt: zod.coerce.date().optional(),
                      fileUrl: zod.coerce.string(),
                      uuid: zod.coerce.string(),
                      createdAt: zod.coerce.date(),
                      updatedAt: zod.coerce.date(),
                    })
                  ),
                  createdAt: zod.coerce.date(),
                  updatedAt: zod.coerce.date(),
                  isClientContract: zod.coerce.boolean(),
                })
              )
              .optional(),
            clientContract: zod
              .object({
                uuid: zod.coerce.string().uuid(),
                description: zod.coerce.string().nullable(),
                project: zod.any(),
                internalProjectManager: zod
                  .object({
                    uuid: zod.coerce.string().uuid(),
                    email: zod.coerce.string().email(),
                    firstName: zod.coerce
                      .string()
                      .max(
                        projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerFirstNameMax
                      ),
                    lastName: zod.coerce
                      .string()
                      .max(
                        projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerLastNameMax
                      ),
                    privatePhoneNumber: zod.coerce.string().optional(),
                    workPhoneNumber: zod.coerce.string().optional(),
                    status: zod.enum(['Active', 'Terminated']),
                    permissions: zod
                      .array(zod.enum(['admin', 'human-resource', 'user']))
                      .optional(),
                    createdAt: zod.coerce.date(),
                    updatedAt: zod.coerce.date(),
                    contracts: zod.array(
                      zod.object({
                        uuid: zod.coerce.string().uuid(),
                        user: zod.any(),
                        role: zod.object({
                          uuid: zod.coerce.string().uuid(),
                          name: zod.coerce.string(),
                        }),
                        department: zod.object({
                          uuid: zod.coerce.string().uuid(),
                          name: zod.coerce.string(),
                        }),
                        perHourSalary: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemPerHourSalaryMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemPerHourSalaryMax
                          )
                          .optional(),
                        monthlyDuration: zod
                          .object({
                            days: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemMonthlyDurationDaysMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemMonthlyDurationDaysMax
                              )
                              .optional(),
                            hours: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemMonthlyDurationHoursMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemMonthlyDurationHoursMax
                              )
                              .optional(),
                            minutes: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemMonthlyDurationMinutesMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemMonthlyDurationMinutesMax
                              )
                              .optional(),
                            seconds: zod.coerce.number().optional(),
                            milliseconds: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemMonthlyDurationMillisecondsMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemMonthlyDurationMillisecondsMax
                              )
                              .optional(),
                          })
                          .optional(),
                        grossSalary: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemGrossSalaryMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemGrossSalaryMax
                          ),
                        type: zod.enum(['full-time', 'part-time', 'hourly']),
                        startDate: zod.coerce.date(),
                        endDate: zod.coerce.date().optional(),
                        vacationPerMonth: zod.object({
                          days: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemVacationPerMonthDaysMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemVacationPerMonthDaysMax
                            )
                            .optional(),
                          hours: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemVacationPerMonthHoursMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemVacationPerMonthHoursMax
                            )
                            .optional(),
                          minutes: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemVacationPerMonthMinutesMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemVacationPerMonthMinutesMax
                            )
                            .optional(),
                          seconds: zod.coerce.number().optional(),
                          milliseconds: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemVacationPerMonthMillisecondsMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemVacationPerMonthMillisecondsMax
                            )
                            .optional(),
                        }),
                        attachments: zod.array(
                          zod.object({
                            name: zod.coerce
                              .string()
                              .max(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemAttachmentsItemNameMax
                              ),
                            path: zod.coerce
                              .string()
                              .max(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemAttachmentsItemPathMax
                              ),
                            mimeType: zod.coerce
                              .string()
                              .max(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemAttachmentsItemMimeTypeMax
                              )
                              .optional(),
                            size: zod.coerce.number().optional(),
                            expireAt: zod.coerce.date().optional(),
                            fileUrl: zod.coerce.string(),
                            uuid: zod.coerce.string(),
                            createdAt: zod.coerce.date(),
                            updatedAt: zod.coerce.date(),
                          })
                        ),
                        previousSalary: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemPreviousSalaryMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemPreviousSalaryMax
                          ),
                        previousPerHourSalary: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemPreviousPerHourSalaryMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemPreviousPerHourSalaryMax
                          ),
                        salaryDiff: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemSalaryDiffMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerContractsItemSalaryDiffMax
                          ),
                        isTypeChanged: zod.coerce.boolean(),
                        previousContractType: zod
                          .enum(['full-time', 'part-time', 'hourly'])
                          .optional(),
                        createdAt: zod.coerce.date(),
                        updatedAt: zod.coerce.date(),
                      })
                    ),
                    currentContract: zod
                      .object({
                        uuid: zod.coerce.string().uuid(),
                        user: zod.any(),
                        role: zod.object({
                          uuid: zod.coerce.string().uuid(),
                          name: zod.coerce.string(),
                        }),
                        department: zod.object({
                          uuid: zod.coerce.string().uuid(),
                          name: zod.coerce.string(),
                        }),
                        perHourSalary: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractPerHourSalaryMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractPerHourSalaryMax
                          )
                          .optional(),
                        monthlyDuration: zod
                          .object({
                            days: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractMonthlyDurationDaysMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractMonthlyDurationDaysMax
                              )
                              .optional(),
                            hours: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractMonthlyDurationHoursMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractMonthlyDurationHoursMax
                              )
                              .optional(),
                            minutes: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractMonthlyDurationMinutesMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractMonthlyDurationMinutesMax
                              )
                              .optional(),
                            seconds: zod.coerce.number().optional(),
                            milliseconds: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractMonthlyDurationMillisecondsMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractMonthlyDurationMillisecondsMax
                              )
                              .optional(),
                          })
                          .optional(),
                        grossSalary: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractGrossSalaryMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractGrossSalaryMax
                          ),
                        type: zod.enum(['full-time', 'part-time', 'hourly']),
                        startDate: zod.coerce.date(),
                        endDate: zod.coerce.date().optional(),
                        vacationPerMonth: zod.object({
                          days: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractVacationPerMonthDaysMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractVacationPerMonthDaysMax
                            )
                            .optional(),
                          hours: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractVacationPerMonthHoursMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractVacationPerMonthHoursMax
                            )
                            .optional(),
                          minutes: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractVacationPerMonthMinutesMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractVacationPerMonthMinutesMax
                            )
                            .optional(),
                          seconds: zod.coerce.number().optional(),
                          milliseconds: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractVacationPerMonthMillisecondsMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractVacationPerMonthMillisecondsMax
                            )
                            .optional(),
                        }),
                        attachments: zod.array(
                          zod.object({
                            name: zod.coerce
                              .string()
                              .max(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractAttachmentsItemNameMax
                              ),
                            path: zod.coerce
                              .string()
                              .max(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractAttachmentsItemPathMax
                              ),
                            mimeType: zod.coerce
                              .string()
                              .max(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractAttachmentsItemMimeTypeMax
                              )
                              .optional(),
                            size: zod.coerce.number().optional(),
                            expireAt: zod.coerce.date().optional(),
                            fileUrl: zod.coerce.string(),
                            uuid: zod.coerce.string(),
                            createdAt: zod.coerce.date(),
                            updatedAt: zod.coerce.date(),
                          })
                        ),
                        previousSalary: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractPreviousSalaryMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractPreviousSalaryMax
                          ),
                        previousPerHourSalary: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractPreviousPerHourSalaryMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractPreviousPerHourSalaryMax
                          ),
                        salaryDiff: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractSalaryDiffMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerCurrentContractSalaryDiffMax
                          ),
                        isTypeChanged: zod.coerce.boolean(),
                        previousContractType: zod
                          .enum(['full-time', 'part-time', 'hourly'])
                          .optional(),
                        createdAt: zod.coerce.date(),
                        updatedAt: zod.coerce.date(),
                      })
                      .optional(),
                    salaries: zod.array(
                      zod.object({
                        uuid: zod.coerce.string().uuid(),
                        user: zod.any(),
                        date: zod.coerce.date(),
                        paymentDate: zod.coerce.date().optional(),
                        perHour: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemPerHourMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemPerHourMax
                          )
                          .optional(),
                        monthlyDuration: zod
                          .object({
                            days: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemMonthlyDurationDaysMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemMonthlyDurationDaysMax
                              )
                              .optional(),
                            hours: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemMonthlyDurationHoursMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemMonthlyDurationHoursMax
                              )
                              .optional(),
                            minutes: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemMonthlyDurationMinutesMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemMonthlyDurationMinutesMax
                              )
                              .optional(),
                            seconds: zod.coerce.number().optional(),
                            milliseconds: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemMonthlyDurationMillisecondsMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemMonthlyDurationMillisecondsMax
                              )
                              .optional(),
                          })
                          .optional(),
                        base: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemBaseMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemBaseMax
                          ),
                        bonus: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemBonusMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemBonusMax
                          )
                          .optional(),
                        paid: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemPaidMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerSalariesItemPaidMax
                          ),
                        type: zod.enum(['full-time', 'part-time', 'hourly']),
                        role: zod.object({
                          uuid: zod.coerce.string().uuid(),
                          name: zod.coerce.string(),
                        }),
                        department: zod.object({
                          uuid: zod.coerce.string().uuid(),
                          name: zod.coerce.string(),
                        }),
                        createdAt: zod.coerce.date(),
                        updatedAt: zod.coerce.date(),
                        isMultiContract: zod.coerce.boolean(),
                      })
                    ),
                    hireDate: zod.coerce.date().optional(),
                    profilePicture: zod.coerce.string(),
                    fullName: zod.coerce.string(),
                    leaveTypes: zod.array(
                      zod.object({
                        uuid: zod.coerce.string().uuid(),
                        name: zod.coerce
                          .string()
                          .min(1)
                          .max(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerLeaveTypesItemNameMax
                          ),
                        description: zod.coerce
                          .string()
                          .max(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerLeaveTypesItemDescriptionMax
                          )
                          .optional(),
                        color: zod.coerce
                          .string()
                          .min(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerLeaveTypesItemColorMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerLeaveTypesItemColorMax
                          )
                          .regex(
                            projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerLeaveTypesItemColorRegExp
                          )
                          .optional(),
                        allowedPerYear: zod.object({
                          days: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerLeaveTypesItemAllowedPerYearDaysMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerLeaveTypesItemAllowedPerYearDaysMax
                            )
                            .optional(),
                          hours: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerLeaveTypesItemAllowedPerYearHoursMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerLeaveTypesItemAllowedPerYearHoursMax
                            )
                            .optional(),
                          minutes: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerLeaveTypesItemAllowedPerYearMinutesMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerLeaveTypesItemAllowedPerYearMinutesMax
                            )
                            .optional(),
                          seconds: zod.coerce.number().optional(),
                          milliseconds: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerLeaveTypesItemAllowedPerYearMillisecondsMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemClientContractInternalProjectManagerLeaveTypesItemAllowedPerYearMillisecondsMax
                            )
                            .optional(),
                        }),
                        isFreebie: zod.coerce.boolean().optional(),
                        isWithAttachment: zod.coerce.boolean().optional(),
                      })
                    ),
                  })
                  .nullable(),
                externalProjectManager: zod
                  .object({
                    company: zod
                      .object({
                        uuid: zod.coerce.string().uuid(),
                        serial: zod.coerce
                          .string()
                          .max(
                            projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerCompanySerialMax
                          )
                          .nullish(),
                        name: zod.coerce
                          .string()
                          .max(
                            projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerCompanyNameMax
                          ),
                        address: zod
                          .object({
                            uuid: zod.coerce.string().uuid(),
                            country: zod.coerce.string().optional(),
                            city: zod.coerce.string().nullish(),
                            street: zod.coerce.string().nullish(),
                            postcode: zod.coerce.string().nullish(),
                            lat: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerCompanyAddressLatMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerCompanyAddressLatMax
                              )
                              .nullish(),
                            lon: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerCompanyAddressLonMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerCompanyAddressLonMax
                              )
                              .nullish(),
                          })
                          .nullish(),
                        phoneNumber: zod.coerce.string().nullish(),
                        email: zod.coerce.string().email().nullish(),
                        website: zod.coerce.string().url().nullable(),
                        logo: zod.coerce.string().optional(),
                        companyType: zod.enum([
                          'Client',
                          'Contractor',
                          'Purchase',
                          'Surcharge',
                          'Creditor',
                        ]),
                        tag: zod
                          .object({
                            uuid: zod.coerce.string().uuid(),
                            parent: zod.any().nullish(),
                            children: zod.array(zod.any()).optional(),
                            name: zod.coerce
                              .string()
                              .max(
                                projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerCompanyTagNameMax
                              ),
                            companyType: zod.enum([
                              'Client',
                              'Contractor',
                              'Purchase',
                              'Surcharge',
                              'Creditor',
                            ]),
                            serial: zod.coerce.string().optional(),
                            priority: zod.coerce.number().nullish(),
                            aliases: zod
                              .array(
                                zod.object({
                                  uuid: zod.coerce.string().uuid(),
                                  isAbbr: zod.coerce.boolean(),
                                  tag: zod.any(),
                                  nameAlias: zod.coerce
                                    .string()
                                    .min(1)
                                    .max(
                                      projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerCompanyTagAliasesItemNameAliasMax
                                    ),
                                  language: zod.coerce.string().optional(),
                                })
                              )
                              .optional(),
                          })
                          .nullish(),
                        rating: zod.coerce
                          .number()
                          .min(1)
                          .max(
                            projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerCompanyRatingMax
                          )
                          .nullish(),
                        companySize: zod.coerce.number().min(1).nullish(),
                        maxDistanceToProject: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerCompanyMaxDistanceToProjectMin
                          )
                          .optional(),
                        createdAt: zod.coerce.date(),
                        updatedAt: zod.coerce.date(),
                        contracts: zod.array(zod.any()).optional(),
                        relatedProjects: zod.array(zod.any()),
                        subContractorCost: zod
                          .array(
                            zod.object({
                              uuid: zod.coerce.string(),
                              project: zod.any(),
                              tag: zod.object({
                                uuid: zod.coerce.string().uuid(),
                                parent: zod.any().nullish(),
                                children: zod.array(zod.any()).optional(),
                                name: zod.coerce
                                  .string()
                                  .max(
                                    projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerCompanySubContractorCostItemTagNameMax
                                  ),
                                companyType: zod.enum([
                                  'Client',
                                  'Contractor',
                                  'Purchase',
                                  'Surcharge',
                                  'Creditor',
                                ]),
                                serial: zod.coerce.string().optional(),
                                priority: zod.coerce.number().nullish(),
                                aliases: zod
                                  .array(
                                    zod.object({
                                      uuid: zod.coerce.string().uuid(),
                                      isAbbr: zod.coerce.boolean(),
                                      tag: zod.any(),
                                      nameAlias: zod.coerce
                                        .string()
                                        .min(1)
                                        .max(
                                          projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerCompanySubContractorCostItemTagAliasesItemNameAliasMax
                                        ),
                                      language: zod.coerce.string().optional(),
                                    })
                                  )
                                  .optional(),
                              }),
                              rowNumber: zod.coerce.string(),
                              sumSub: zod.coerce.number().nullable(),
                              createdAt: zod.coerce.date(),
                              updatedAt: zod.coerce.date(),
                              type: zod.coerce.string(),
                              items: zod.array(
                                zod.object({
                                  uuid: zod.coerce.string(),
                                  additionReduceCost: zod.any(),
                                  rowNumber: zod.coerce.string(),
                                  description: zod.coerce.string().optional(),
                                  quantity: zod.coerce.number(),
                                  unit: zod.coerce.string(),
                                  unitPriceSub: zod.coerce.number(),
                                  sumSub: zod.coerce.number(),
                                  unitPriceGcPercent: zod.coerce
                                    .number()
                                    .min(
                                      projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerCompanySubContractorCostItemItemsItemUnitPriceGcPercentMin
                                    )
                                    .max(
                                      projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerCompanySubContractorCostItemItemsItemUnitPriceGcPercentMax
                                    ),
                                  unitPriceGc: zod.coerce.number().optional(),
                                  sumGc: zod.coerce.number(),
                                  differenceGcSub: zod.coerce
                                    .number()
                                    .nullish(),
                                  instructedSum: zod.coerce.number(),
                                  status: zod.enum([
                                    'open',
                                    'rejected',
                                    'instructed',
                                    'paid',
                                  ]),
                                  createdAt: zod.coerce.date(),
                                  updatedAt: zod.coerce.date(),
                                  additionDate: zod.coerce.date().nullable(),
                                  additionTitle: zod.coerce.string().nullable(),
                                  additionNumber: zod.coerce
                                    .string()
                                    .nullable(),
                                  authorizedAmount: zod.coerce
                                    .number()
                                    .nullable(),
                                  remarks: zod.coerce.string().nullable(),
                                })
                              ),
                            })
                          )
                          .optional(),
                      })
                      .optional(),
                    contracts: zod.array(zod.any()).optional(),
                    firstName: zod.coerce
                      .string()
                      .max(
                        projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerFirstNameMax
                      ),
                    lastName: zod.coerce
                      .string()
                      .max(
                        projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerLastNameMax
                      ),
                    phoneNumber: zod.coerce.string().nullable(),
                    email: zod.coerce.string().email().nullable(),
                    description: zod.coerce.string(),
                    website: zod.coerce.string().url().nullable(),
                    fullName: zod.coerce.string(),
                    profilePicture: zod.coerce.string(),
                    relatedProjects: zod.array(zod.any()),
                    uuid: zod.coerce.string().uuid(),
                    address: zod.object({
                      uuid: zod.coerce.string().uuid(),
                      country: zod.coerce.string().optional(),
                      city: zod.coerce.string().nullish(),
                      street: zod.coerce.string().nullish(),
                      postcode: zod.coerce.string().nullish(),
                      lat: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerAddressLatMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerAddressLatMax
                        )
                        .nullish(),
                      lon: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerAddressLonMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemClientContractExternalProjectManagerAddressLonMax
                        )
                        .nullish(),
                    }),
                    createdAt: zod.coerce.date(),
                    updatedAt: zod.coerce.date(),
                  })
                  .nullable(),
                people: zod
                  .array(
                    zod.object({
                      company: zod
                        .object({
                          uuid: zod.coerce.string().uuid(),
                          serial: zod.coerce
                            .string()
                            .max(
                              projectsControllerFindAllResponseDataItemClientContractPeopleItemCompanySerialMax
                            )
                            .nullish(),
                          name: zod.coerce
                            .string()
                            .max(
                              projectsControllerFindAllResponseDataItemClientContractPeopleItemCompanyNameMax
                            ),
                          address: zod
                            .object({
                              uuid: zod.coerce.string().uuid(),
                              country: zod.coerce.string().optional(),
                              city: zod.coerce.string().nullish(),
                              street: zod.coerce.string().nullish(),
                              postcode: zod.coerce.string().nullish(),
                              lat: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemClientContractPeopleItemCompanyAddressLatMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemClientContractPeopleItemCompanyAddressLatMax
                                )
                                .nullish(),
                              lon: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemClientContractPeopleItemCompanyAddressLonMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemClientContractPeopleItemCompanyAddressLonMax
                                )
                                .nullish(),
                            })
                            .nullish(),
                          phoneNumber: zod.coerce.string().nullish(),
                          email: zod.coerce.string().email().nullish(),
                          website: zod.coerce.string().url().nullable(),
                          logo: zod.coerce.string().optional(),
                          companyType: zod.enum([
                            'Client',
                            'Contractor',
                            'Purchase',
                            'Surcharge',
                            'Creditor',
                          ]),
                          tag: zod
                            .object({
                              uuid: zod.coerce.string().uuid(),
                              parent: zod.any().nullish(),
                              children: zod.array(zod.any()).optional(),
                              name: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemClientContractPeopleItemCompanyTagNameMax
                                ),
                              companyType: zod.enum([
                                'Client',
                                'Contractor',
                                'Purchase',
                                'Surcharge',
                                'Creditor',
                              ]),
                              serial: zod.coerce.string().optional(),
                              priority: zod.coerce.number().nullish(),
                              aliases: zod
                                .array(
                                  zod.object({
                                    uuid: zod.coerce.string().uuid(),
                                    isAbbr: zod.coerce.boolean(),
                                    tag: zod.any(),
                                    nameAlias: zod.coerce
                                      .string()
                                      .min(1)
                                      .max(
                                        projectsControllerFindAllResponseDataItemClientContractPeopleItemCompanyTagAliasesItemNameAliasMax
                                      ),
                                    language: zod.coerce.string().optional(),
                                  })
                                )
                                .optional(),
                            })
                            .nullish(),
                          rating: zod.coerce
                            .number()
                            .min(1)
                            .max(
                              projectsControllerFindAllResponseDataItemClientContractPeopleItemCompanyRatingMax
                            )
                            .nullish(),
                          companySize: zod.coerce.number().min(1).nullish(),
                          maxDistanceToProject: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemClientContractPeopleItemCompanyMaxDistanceToProjectMin
                            )
                            .optional(),
                          createdAt: zod.coerce.date(),
                          updatedAt: zod.coerce.date(),
                          contracts: zod.array(zod.any()).optional(),
                          relatedProjects: zod.array(zod.any()),
                          subContractorCost: zod
                            .array(
                              zod.object({
                                uuid: zod.coerce.string(),
                                project: zod.any(),
                                tag: zod.object({
                                  uuid: zod.coerce.string().uuid(),
                                  parent: zod.any().nullish(),
                                  children: zod.array(zod.any()).optional(),
                                  name: zod.coerce
                                    .string()
                                    .max(
                                      projectsControllerFindAllResponseDataItemClientContractPeopleItemCompanySubContractorCostItemTagNameMax
                                    ),
                                  companyType: zod.enum([
                                    'Client',
                                    'Contractor',
                                    'Purchase',
                                    'Surcharge',
                                    'Creditor',
                                  ]),
                                  serial: zod.coerce.string().optional(),
                                  priority: zod.coerce.number().nullish(),
                                  aliases: zod
                                    .array(
                                      zod.object({
                                        uuid: zod.coerce.string().uuid(),
                                        isAbbr: zod.coerce.boolean(),
                                        tag: zod.any(),
                                        nameAlias: zod.coerce
                                          .string()
                                          .min(1)
                                          .max(
                                            projectsControllerFindAllResponseDataItemClientContractPeopleItemCompanySubContractorCostItemTagAliasesItemNameAliasMax
                                          ),
                                        language: zod.coerce
                                          .string()
                                          .optional(),
                                      })
                                    )
                                    .optional(),
                                }),
                                rowNumber: zod.coerce.string(),
                                sumSub: zod.coerce.number().nullable(),
                                createdAt: zod.coerce.date(),
                                updatedAt: zod.coerce.date(),
                                type: zod.coerce.string(),
                                items: zod.array(
                                  zod.object({
                                    uuid: zod.coerce.string(),
                                    additionReduceCost: zod.any(),
                                    rowNumber: zod.coerce.string(),
                                    description: zod.coerce.string().optional(),
                                    quantity: zod.coerce.number(),
                                    unit: zod.coerce.string(),
                                    unitPriceSub: zod.coerce.number(),
                                    sumSub: zod.coerce.number(),
                                    unitPriceGcPercent: zod.coerce
                                      .number()
                                      .min(
                                        projectsControllerFindAllResponseDataItemClientContractPeopleItemCompanySubContractorCostItemItemsItemUnitPriceGcPercentMin
                                      )
                                      .max(
                                        projectsControllerFindAllResponseDataItemClientContractPeopleItemCompanySubContractorCostItemItemsItemUnitPriceGcPercentMax
                                      ),
                                    unitPriceGc: zod.coerce.number().optional(),
                                    sumGc: zod.coerce.number(),
                                    differenceGcSub: zod.coerce
                                      .number()
                                      .nullish(),
                                    instructedSum: zod.coerce.number(),
                                    status: zod.enum([
                                      'open',
                                      'rejected',
                                      'instructed',
                                      'paid',
                                    ]),
                                    createdAt: zod.coerce.date(),
                                    updatedAt: zod.coerce.date(),
                                    additionDate: zod.coerce.date().nullable(),
                                    additionTitle: zod.coerce
                                      .string()
                                      .nullable(),
                                    additionNumber: zod.coerce
                                      .string()
                                      .nullable(),
                                    authorizedAmount: zod.coerce
                                      .number()
                                      .nullable(),
                                    remarks: zod.coerce.string().nullable(),
                                  })
                                ),
                              })
                            )
                            .optional(),
                        })
                        .optional(),
                      contracts: zod.array(zod.any()).optional(),
                      firstName: zod.coerce
                        .string()
                        .max(
                          projectsControllerFindAllResponseDataItemClientContractPeopleItemFirstNameMax
                        ),
                      lastName: zod.coerce
                        .string()
                        .max(
                          projectsControllerFindAllResponseDataItemClientContractPeopleItemLastNameMax
                        ),
                      phoneNumber: zod.coerce.string().nullable(),
                      email: zod.coerce.string().email().nullable(),
                      description: zod.coerce.string(),
                      website: zod.coerce.string().url().nullable(),
                      fullName: zod.coerce.string(),
                      profilePicture: zod.coerce.string(),
                      relatedProjects: zod.array(zod.any()),
                      uuid: zod.coerce.string().uuid(),
                      address: zod.object({
                        uuid: zod.coerce.string().uuid(),
                        country: zod.coerce.string().optional(),
                        city: zod.coerce.string().nullish(),
                        street: zod.coerce.string().nullish(),
                        postcode: zod.coerce.string().nullish(),
                        lat: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemClientContractPeopleItemAddressLatMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemClientContractPeopleItemAddressLatMax
                          )
                          .nullish(),
                        lon: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemClientContractPeopleItemAddressLonMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemClientContractPeopleItemAddressLonMax
                          )
                          .nullish(),
                      }),
                      createdAt: zod.coerce.date(),
                      updatedAt: zod.coerce.date(),
                    })
                  )
                  .optional(),
                minNumberOfWorkersOnSite: zod.coerce
                  .number()
                  .min(
                    projectsControllerFindAllResponseDataItemClientContractMinNumberOfWorkersOnSiteMin
                  )
                  .max(
                    projectsControllerFindAllResponseDataItemClientContractMinNumberOfWorkersOnSiteMax
                  )
                  .nullable(),
                maxNumberOfWorkersOnSite: zod.coerce
                  .number()
                  .min(
                    projectsControllerFindAllResponseDataItemClientContractMaxNumberOfWorkersOnSiteMin
                  ),
                startDate: zod.coerce.date().optional(),
                signDate: zod.coerce.date().optional(),
                deadline: zod.coerce.date().optional(),
                company: zod
                  .object({
                    uuid: zod.coerce.string().uuid(),
                    serial: zod.coerce
                      .string()
                      .max(
                        projectsControllerFindAllResponseDataItemClientContractCompanySerialMax
                      )
                      .nullish(),
                    name: zod.coerce
                      .string()
                      .max(
                        projectsControllerFindAllResponseDataItemClientContractCompanyNameMax
                      ),
                    address: zod
                      .object({
                        uuid: zod.coerce.string().uuid(),
                        country: zod.coerce.string().optional(),
                        city: zod.coerce.string().nullish(),
                        street: zod.coerce.string().nullish(),
                        postcode: zod.coerce.string().nullish(),
                        lat: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemClientContractCompanyAddressLatMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemClientContractCompanyAddressLatMax
                          )
                          .nullish(),
                        lon: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemClientContractCompanyAddressLonMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemClientContractCompanyAddressLonMax
                          )
                          .nullish(),
                      })
                      .nullish(),
                    phoneNumber: zod.coerce.string().nullish(),
                    email: zod.coerce.string().email().nullish(),
                    website: zod.coerce.string().url().nullable(),
                    logo: zod.coerce.string().optional(),
                    companyType: zod.enum([
                      'Client',
                      'Contractor',
                      'Purchase',
                      'Surcharge',
                      'Creditor',
                    ]),
                    tag: zod
                      .object({
                        uuid: zod.coerce.string().uuid(),
                        parent: zod.any().nullish(),
                        children: zod.array(zod.any()).optional(),
                        name: zod.coerce
                          .string()
                          .max(
                            projectsControllerFindAllResponseDataItemClientContractCompanyTagNameMax
                          ),
                        companyType: zod.enum([
                          'Client',
                          'Contractor',
                          'Purchase',
                          'Surcharge',
                          'Creditor',
                        ]),
                        serial: zod.coerce.string().optional(),
                        priority: zod.coerce.number().nullish(),
                        aliases: zod
                          .array(
                            zod.object({
                              uuid: zod.coerce.string().uuid(),
                              isAbbr: zod.coerce.boolean(),
                              tag: zod.any(),
                              nameAlias: zod.coerce
                                .string()
                                .min(1)
                                .max(
                                  projectsControllerFindAllResponseDataItemClientContractCompanyTagAliasesItemNameAliasMax
                                ),
                              language: zod.coerce.string().optional(),
                            })
                          )
                          .optional(),
                      })
                      .nullish(),
                    rating: zod.coerce
                      .number()
                      .min(1)
                      .max(
                        projectsControllerFindAllResponseDataItemClientContractCompanyRatingMax
                      )
                      .nullish(),
                    companySize: zod.coerce.number().min(1).nullish(),
                    maxDistanceToProject: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemClientContractCompanyMaxDistanceToProjectMin
                      )
                      .optional(),
                    createdAt: zod.coerce.date(),
                    updatedAt: zod.coerce.date(),
                    contracts: zod.array(zod.any()).optional(),
                    relatedProjects: zod.array(zod.any()),
                    subContractorCost: zod
                      .array(
                        zod.object({
                          uuid: zod.coerce.string(),
                          project: zod.any(),
                          tag: zod.object({
                            uuid: zod.coerce.string().uuid(),
                            parent: zod.any().nullish(),
                            children: zod.array(zod.any()).optional(),
                            name: zod.coerce
                              .string()
                              .max(
                                projectsControllerFindAllResponseDataItemClientContractCompanySubContractorCostItemTagNameMax
                              ),
                            companyType: zod.enum([
                              'Client',
                              'Contractor',
                              'Purchase',
                              'Surcharge',
                              'Creditor',
                            ]),
                            serial: zod.coerce.string().optional(),
                            priority: zod.coerce.number().nullish(),
                            aliases: zod
                              .array(
                                zod.object({
                                  uuid: zod.coerce.string().uuid(),
                                  isAbbr: zod.coerce.boolean(),
                                  tag: zod.any(),
                                  nameAlias: zod.coerce
                                    .string()
                                    .min(1)
                                    .max(
                                      projectsControllerFindAllResponseDataItemClientContractCompanySubContractorCostItemTagAliasesItemNameAliasMax
                                    ),
                                  language: zod.coerce.string().optional(),
                                })
                              )
                              .optional(),
                          }),
                          rowNumber: zod.coerce.string(),
                          sumSub: zod.coerce.number().nullable(),
                          createdAt: zod.coerce.date(),
                          updatedAt: zod.coerce.date(),
                          type: zod.coerce.string(),
                          items: zod.array(
                            zod.object({
                              uuid: zod.coerce.string(),
                              additionReduceCost: zod.any(),
                              rowNumber: zod.coerce.string(),
                              description: zod.coerce.string().optional(),
                              quantity: zod.coerce.number(),
                              unit: zod.coerce.string(),
                              unitPriceSub: zod.coerce.number(),
                              sumSub: zod.coerce.number(),
                              unitPriceGcPercent: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemClientContractCompanySubContractorCostItemItemsItemUnitPriceGcPercentMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemClientContractCompanySubContractorCostItemItemsItemUnitPriceGcPercentMax
                                ),
                              unitPriceGc: zod.coerce.number().optional(),
                              sumGc: zod.coerce.number(),
                              differenceGcSub: zod.coerce.number().nullish(),
                              instructedSum: zod.coerce.number(),
                              status: zod.enum([
                                'open',
                                'rejected',
                                'instructed',
                                'paid',
                              ]),
                              createdAt: zod.coerce.date(),
                              updatedAt: zod.coerce.date(),
                              additionDate: zod.coerce.date().nullable(),
                              additionTitle: zod.coerce.string().nullable(),
                              additionNumber: zod.coerce.string().nullable(),
                              authorizedAmount: zod.coerce.number().nullable(),
                              remarks: zod.coerce.string().nullable(),
                            })
                          ),
                        })
                      )
                      .optional(),
                  })
                  .optional(),
                netPrice: zod.coerce
                  .number()
                  .min(
                    projectsControllerFindAllResponseDataItemClientContractNetPriceMin
                  )
                  .max(
                    projectsControllerFindAllResponseDataItemClientContractNetPriceMax
                  )
                  .optional(),
                tagUuid: zod.coerce.string().uuid(),
                tag: zod
                  .object({
                    uuid: zod.coerce.string().uuid(),
                    parent: zod.any().nullish(),
                    children: zod.array(zod.any()).optional(),
                    name: zod.coerce
                      .string()
                      .max(
                        projectsControllerFindAllResponseDataItemClientContractTagNameMax
                      ),
                    companyType: zod.enum([
                      'Client',
                      'Contractor',
                      'Purchase',
                      'Surcharge',
                      'Creditor',
                    ]),
                    serial: zod.coerce.string().optional(),
                    priority: zod.coerce.number().nullish(),
                    aliases: zod
                      .array(
                        zod.object({
                          uuid: zod.coerce.string().uuid(),
                          isAbbr: zod.coerce.boolean(),
                          tag: zod.any(),
                          nameAlias: zod.coerce
                            .string()
                            .min(1)
                            .max(
                              projectsControllerFindAllResponseDataItemClientContractTagAliasesItemNameAliasMax
                            ),
                          language: zod.coerce.string().optional(),
                        })
                      )
                      .optional(),
                  })
                  .nullable(),
                warrantyReserve: zod.coerce
                  .number()
                  .min(
                    projectsControllerFindAllResponseDataItemClientContractWarrantyReserveMin
                  )
                  .max(
                    projectsControllerFindAllResponseDataItemClientContractWarrantyReserveMax
                  )
                  .nullable(),
                umlage: zod
                  .object({
                    amount: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemClientContractUmlageAmountMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemClientContractUmlageAmountMax
                      )
                      .optional(),
                    percent: zod.coerce.number().optional(),
                  })
                  .optional(),
                skonto: zod
                  .object({
                    amount: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemClientContractSkontoAmountMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemClientContractSkontoAmountMax
                      )
                      .optional(),
                    percent: zod.coerce.number().optional(),
                  })
                  .optional(),
                attachments: zod.array(
                  zod.object({
                    name: zod.coerce
                      .string()
                      .max(
                        projectsControllerFindAllResponseDataItemClientContractAttachmentsItemNameMax
                      ),
                    path: zod.coerce
                      .string()
                      .max(
                        projectsControllerFindAllResponseDataItemClientContractAttachmentsItemPathMax
                      ),
                    mimeType: zod.coerce
                      .string()
                      .max(
                        projectsControllerFindAllResponseDataItemClientContractAttachmentsItemMimeTypeMax
                      )
                      .optional(),
                    size: zod.coerce.number().optional(),
                    expireAt: zod.coerce.date().optional(),
                    fileUrl: zod.coerce.string(),
                    uuid: zod.coerce.string(),
                    createdAt: zod.coerce.date(),
                    updatedAt: zod.coerce.date(),
                  })
                ),
                createdAt: zod.coerce.date(),
                updatedAt: zod.coerce.date(),
                isClientContract: zod.coerce.boolean(),
              })
              .optional(),
            projectAssistant: zod.object({
              uuid: zod.coerce.string().uuid(),
              email: zod.coerce.string().email(),
              firstName: zod.coerce
                .string()
                .max(
                  projectsControllerFindAllResponseDataItemProjectAssistantFirstNameMax
                ),
              lastName: zod.coerce
                .string()
                .max(
                  projectsControllerFindAllResponseDataItemProjectAssistantLastNameMax
                ),
              privatePhoneNumber: zod.coerce.string().optional(),
              workPhoneNumber: zod.coerce.string().optional(),
              status: zod.enum(['Active', 'Terminated']),
              permissions: zod
                .array(zod.enum(['admin', 'human-resource', 'user']))
                .optional(),
              createdAt: zod.coerce.date(),
              updatedAt: zod.coerce.date(),
              contracts: zod.array(
                zod.object({
                  uuid: zod.coerce.string().uuid(),
                  user: zod.any(),
                  role: zod.object({
                    uuid: zod.coerce.string().uuid(),
                    name: zod.coerce.string(),
                  }),
                  department: zod.object({
                    uuid: zod.coerce.string().uuid(),
                    name: zod.coerce.string(),
                  }),
                  perHourSalary: zod.coerce
                    .number()
                    .min(
                      projectsControllerFindAllResponseDataItemProjectAssistantContractsItemPerHourSalaryMin
                    )
                    .max(
                      projectsControllerFindAllResponseDataItemProjectAssistantContractsItemPerHourSalaryMax
                    )
                    .optional(),
                  monthlyDuration: zod
                    .object({
                      days: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemProjectAssistantContractsItemMonthlyDurationDaysMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemProjectAssistantContractsItemMonthlyDurationDaysMax
                        )
                        .optional(),
                      hours: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemProjectAssistantContractsItemMonthlyDurationHoursMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemProjectAssistantContractsItemMonthlyDurationHoursMax
                        )
                        .optional(),
                      minutes: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemProjectAssistantContractsItemMonthlyDurationMinutesMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemProjectAssistantContractsItemMonthlyDurationMinutesMax
                        )
                        .optional(),
                      seconds: zod.coerce.number().optional(),
                      milliseconds: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemProjectAssistantContractsItemMonthlyDurationMillisecondsMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemProjectAssistantContractsItemMonthlyDurationMillisecondsMax
                        )
                        .optional(),
                    })
                    .optional(),
                  grossSalary: zod.coerce
                    .number()
                    .min(
                      projectsControllerFindAllResponseDataItemProjectAssistantContractsItemGrossSalaryMin
                    )
                    .max(
                      projectsControllerFindAllResponseDataItemProjectAssistantContractsItemGrossSalaryMax
                    ),
                  type: zod.enum(['full-time', 'part-time', 'hourly']),
                  startDate: zod.coerce.date(),
                  endDate: zod.coerce.date().optional(),
                  vacationPerMonth: zod.object({
                    days: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectAssistantContractsItemVacationPerMonthDaysMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectAssistantContractsItemVacationPerMonthDaysMax
                      )
                      .optional(),
                    hours: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectAssistantContractsItemVacationPerMonthHoursMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectAssistantContractsItemVacationPerMonthHoursMax
                      )
                      .optional(),
                    minutes: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectAssistantContractsItemVacationPerMonthMinutesMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectAssistantContractsItemVacationPerMonthMinutesMax
                      )
                      .optional(),
                    seconds: zod.coerce.number().optional(),
                    milliseconds: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectAssistantContractsItemVacationPerMonthMillisecondsMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectAssistantContractsItemVacationPerMonthMillisecondsMax
                      )
                      .optional(),
                  }),
                  attachments: zod.array(
                    zod.object({
                      name: zod.coerce
                        .string()
                        .max(
                          projectsControllerFindAllResponseDataItemProjectAssistantContractsItemAttachmentsItemNameMax
                        ),
                      path: zod.coerce
                        .string()
                        .max(
                          projectsControllerFindAllResponseDataItemProjectAssistantContractsItemAttachmentsItemPathMax
                        ),
                      mimeType: zod.coerce
                        .string()
                        .max(
                          projectsControllerFindAllResponseDataItemProjectAssistantContractsItemAttachmentsItemMimeTypeMax
                        )
                        .optional(),
                      size: zod.coerce.number().optional(),
                      expireAt: zod.coerce.date().optional(),
                      fileUrl: zod.coerce.string(),
                      uuid: zod.coerce.string(),
                      createdAt: zod.coerce.date(),
                      updatedAt: zod.coerce.date(),
                    })
                  ),
                  previousSalary: zod.coerce
                    .number()
                    .min(
                      projectsControllerFindAllResponseDataItemProjectAssistantContractsItemPreviousSalaryMin
                    )
                    .max(
                      projectsControllerFindAllResponseDataItemProjectAssistantContractsItemPreviousSalaryMax
                    ),
                  previousPerHourSalary: zod.coerce
                    .number()
                    .min(
                      projectsControllerFindAllResponseDataItemProjectAssistantContractsItemPreviousPerHourSalaryMin
                    )
                    .max(
                      projectsControllerFindAllResponseDataItemProjectAssistantContractsItemPreviousPerHourSalaryMax
                    ),
                  salaryDiff: zod.coerce
                    .number()
                    .min(
                      projectsControllerFindAllResponseDataItemProjectAssistantContractsItemSalaryDiffMin
                    )
                    .max(
                      projectsControllerFindAllResponseDataItemProjectAssistantContractsItemSalaryDiffMax
                    ),
                  isTypeChanged: zod.coerce.boolean(),
                  previousContractType: zod
                    .enum(['full-time', 'part-time', 'hourly'])
                    .optional(),
                  createdAt: zod.coerce.date(),
                  updatedAt: zod.coerce.date(),
                })
              ),
              currentContract: zod
                .object({
                  uuid: zod.coerce.string().uuid(),
                  user: zod.any(),
                  role: zod.object({
                    uuid: zod.coerce.string().uuid(),
                    name: zod.coerce.string(),
                  }),
                  department: zod.object({
                    uuid: zod.coerce.string().uuid(),
                    name: zod.coerce.string(),
                  }),
                  perHourSalary: zod.coerce
                    .number()
                    .min(
                      projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractPerHourSalaryMin
                    )
                    .max(
                      projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractPerHourSalaryMax
                    )
                    .optional(),
                  monthlyDuration: zod
                    .object({
                      days: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractMonthlyDurationDaysMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractMonthlyDurationDaysMax
                        )
                        .optional(),
                      hours: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractMonthlyDurationHoursMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractMonthlyDurationHoursMax
                        )
                        .optional(),
                      minutes: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractMonthlyDurationMinutesMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractMonthlyDurationMinutesMax
                        )
                        .optional(),
                      seconds: zod.coerce.number().optional(),
                      milliseconds: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractMonthlyDurationMillisecondsMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractMonthlyDurationMillisecondsMax
                        )
                        .optional(),
                    })
                    .optional(),
                  grossSalary: zod.coerce
                    .number()
                    .min(
                      projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractGrossSalaryMin
                    )
                    .max(
                      projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractGrossSalaryMax
                    ),
                  type: zod.enum(['full-time', 'part-time', 'hourly']),
                  startDate: zod.coerce.date(),
                  endDate: zod.coerce.date().optional(),
                  vacationPerMonth: zod.object({
                    days: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractVacationPerMonthDaysMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractVacationPerMonthDaysMax
                      )
                      .optional(),
                    hours: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractVacationPerMonthHoursMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractVacationPerMonthHoursMax
                      )
                      .optional(),
                    minutes: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractVacationPerMonthMinutesMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractVacationPerMonthMinutesMax
                      )
                      .optional(),
                    seconds: zod.coerce.number().optional(),
                    milliseconds: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractVacationPerMonthMillisecondsMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractVacationPerMonthMillisecondsMax
                      )
                      .optional(),
                  }),
                  attachments: zod.array(
                    zod.object({
                      name: zod.coerce
                        .string()
                        .max(
                          projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractAttachmentsItemNameMax
                        ),
                      path: zod.coerce
                        .string()
                        .max(
                          projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractAttachmentsItemPathMax
                        ),
                      mimeType: zod.coerce
                        .string()
                        .max(
                          projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractAttachmentsItemMimeTypeMax
                        )
                        .optional(),
                      size: zod.coerce.number().optional(),
                      expireAt: zod.coerce.date().optional(),
                      fileUrl: zod.coerce.string(),
                      uuid: zod.coerce.string(),
                      createdAt: zod.coerce.date(),
                      updatedAt: zod.coerce.date(),
                    })
                  ),
                  previousSalary: zod.coerce
                    .number()
                    .min(
                      projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractPreviousSalaryMin
                    )
                    .max(
                      projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractPreviousSalaryMax
                    ),
                  previousPerHourSalary: zod.coerce
                    .number()
                    .min(
                      projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractPreviousPerHourSalaryMin
                    )
                    .max(
                      projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractPreviousPerHourSalaryMax
                    ),
                  salaryDiff: zod.coerce
                    .number()
                    .min(
                      projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractSalaryDiffMin
                    )
                    .max(
                      projectsControllerFindAllResponseDataItemProjectAssistantCurrentContractSalaryDiffMax
                    ),
                  isTypeChanged: zod.coerce.boolean(),
                  previousContractType: zod
                    .enum(['full-time', 'part-time', 'hourly'])
                    .optional(),
                  createdAt: zod.coerce.date(),
                  updatedAt: zod.coerce.date(),
                })
                .optional(),
              salaries: zod.array(
                zod.object({
                  uuid: zod.coerce.string().uuid(),
                  user: zod.any(),
                  date: zod.coerce.date(),
                  paymentDate: zod.coerce.date().optional(),
                  perHour: zod.coerce
                    .number()
                    .min(
                      projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemPerHourMin
                    )
                    .max(
                      projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemPerHourMax
                    )
                    .optional(),
                  monthlyDuration: zod
                    .object({
                      days: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemMonthlyDurationDaysMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemMonthlyDurationDaysMax
                        )
                        .optional(),
                      hours: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemMonthlyDurationHoursMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemMonthlyDurationHoursMax
                        )
                        .optional(),
                      minutes: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemMonthlyDurationMinutesMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemMonthlyDurationMinutesMax
                        )
                        .optional(),
                      seconds: zod.coerce.number().optional(),
                      milliseconds: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemMonthlyDurationMillisecondsMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemMonthlyDurationMillisecondsMax
                        )
                        .optional(),
                    })
                    .optional(),
                  base: zod.coerce
                    .number()
                    .min(
                      projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemBaseMin
                    )
                    .max(
                      projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemBaseMax
                    ),
                  bonus: zod.coerce
                    .number()
                    .min(
                      projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemBonusMin
                    )
                    .max(
                      projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemBonusMax
                    )
                    .optional(),
                  paid: zod.coerce
                    .number()
                    .min(
                      projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemPaidMin
                    )
                    .max(
                      projectsControllerFindAllResponseDataItemProjectAssistantSalariesItemPaidMax
                    ),
                  type: zod.enum(['full-time', 'part-time', 'hourly']),
                  role: zod.object({
                    uuid: zod.coerce.string().uuid(),
                    name: zod.coerce.string(),
                  }),
                  department: zod.object({
                    uuid: zod.coerce.string().uuid(),
                    name: zod.coerce.string(),
                  }),
                  createdAt: zod.coerce.date(),
                  updatedAt: zod.coerce.date(),
                  isMultiContract: zod.coerce.boolean(),
                })
              ),
              hireDate: zod.coerce.date().optional(),
              profilePicture: zod.coerce.string(),
              fullName: zod.coerce.string(),
              leaveTypes: zod.array(
                zod.object({
                  uuid: zod.coerce.string().uuid(),
                  name: zod.coerce
                    .string()
                    .min(1)
                    .max(
                      projectsControllerFindAllResponseDataItemProjectAssistantLeaveTypesItemNameMax
                    ),
                  description: zod.coerce
                    .string()
                    .max(
                      projectsControllerFindAllResponseDataItemProjectAssistantLeaveTypesItemDescriptionMax
                    )
                    .optional(),
                  color: zod.coerce
                    .string()
                    .min(
                      projectsControllerFindAllResponseDataItemProjectAssistantLeaveTypesItemColorMin
                    )
                    .max(
                      projectsControllerFindAllResponseDataItemProjectAssistantLeaveTypesItemColorMax
                    )
                    .regex(
                      projectsControllerFindAllResponseDataItemProjectAssistantLeaveTypesItemColorRegExp
                    )
                    .optional(),
                  allowedPerYear: zod.object({
                    days: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectAssistantLeaveTypesItemAllowedPerYearDaysMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectAssistantLeaveTypesItemAllowedPerYearDaysMax
                      )
                      .optional(),
                    hours: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectAssistantLeaveTypesItemAllowedPerYearHoursMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectAssistantLeaveTypesItemAllowedPerYearHoursMax
                      )
                      .optional(),
                    minutes: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectAssistantLeaveTypesItemAllowedPerYearMinutesMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectAssistantLeaveTypesItemAllowedPerYearMinutesMax
                      )
                      .optional(),
                    seconds: zod.coerce.number().optional(),
                    milliseconds: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectAssistantLeaveTypesItemAllowedPerYearMillisecondsMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectAssistantLeaveTypesItemAllowedPerYearMillisecondsMax
                      )
                      .optional(),
                  }),
                  isFreebie: zod.coerce.boolean().optional(),
                  isWithAttachment: zod.coerce.boolean().optional(),
                })
              ),
            }),
            allocationSummaryParams: zod
              .object({
                uuid: zod.coerce.string().uuid(),
                projectUuid: zod.coerce.string().uuid(),
                project: zod.any().optional(),
                target: zod.coerce
                  .number()
                  .min(
                    projectsControllerFindAllResponseDataItemAllocationSummaryParamsTargetMin
                  )
                  .max(
                    projectsControllerFindAllResponseDataItemAllocationSummaryParamsTargetMax
                  )
                  .nullable(),
                multipleFactor: zod.coerce
                  .number()
                  .min(
                    projectsControllerFindAllResponseDataItemAllocationSummaryParamsMultipleFactorMin
                  )
                  .nullish(),
              })
              .optional(),
            allocationSummary: zod
              .object({
                projectUuid: zod.coerce.string().uuid(),
                project: zod.any().optional(),
                totalContract: zod.coerce
                  .number()
                  .min(
                    projectsControllerFindAllResponseDataItemAllocationSummaryTotalContractMin
                  )
                  .max(
                    projectsControllerFindAllResponseDataItemAllocationSummaryTotalContractMax
                  ),
                totalAllocation: zod.coerce
                  .number()
                  .min(
                    projectsControllerFindAllResponseDataItemAllocationSummaryTotalAllocationMin
                  )
                  .max(
                    projectsControllerFindAllResponseDataItemAllocationSummaryTotalAllocationMax
                  ),
                signedContractsPercent: zod.coerce
                  .number()
                  .min(
                    projectsControllerFindAllResponseDataItemAllocationSummarySignedContractsPercentMin
                  )
                  .max(
                    projectsControllerFindAllResponseDataItemAllocationSummarySignedContractsPercentMax
                  ),
                sumBudget: zod.coerce
                  .number()
                  .min(
                    projectsControllerFindAllResponseDataItemAllocationSummarySumBudgetMin
                  )
                  .max(
                    projectsControllerFindAllResponseDataItemAllocationSummarySumBudgetMax
                  ),
                sumPrice: zod.coerce
                  .number()
                  .min(
                    projectsControllerFindAllResponseDataItemAllocationSummarySumPriceMin
                  )
                  .max(
                    projectsControllerFindAllResponseDataItemAllocationSummarySumPriceMax
                  ),
                sumTax: zod.coerce
                  .number()
                  .min(
                    projectsControllerFindAllResponseDataItemAllocationSummarySumTaxMin
                  )
                  .max(
                    projectsControllerFindAllResponseDataItemAllocationSummarySumTaxMax
                  ),
                sumDiscount: zod.coerce
                  .number()
                  .min(
                    projectsControllerFindAllResponseDataItemAllocationSummarySumDiscountMin
                  )
                  .max(
                    projectsControllerFindAllResponseDataItemAllocationSummarySumDiscountMax
                  ),
                gAndAIncludingPm: zod.coerce
                  .number()
                  .min(
                    projectsControllerFindAllResponseDataItemAllocationSummaryGAndAIncludingPmMin
                  )
                  .max(
                    projectsControllerFindAllResponseDataItemAllocationSummaryGAndAIncludingPmMax
                  ),
                additionalCosts: zod.coerce
                  .number()
                  .min(
                    projectsControllerFindAllResponseDataItemAllocationSummaryAdditionalCostsMin
                  )
                  .max(
                    projectsControllerFindAllResponseDataItemAllocationSummaryAdditionalCostsMax
                  ),
                reducedCosts: zod.coerce
                  .number()
                  .min(
                    projectsControllerFindAllResponseDataItemAllocationSummaryReducedCostsMin
                  )
                  .max(
                    projectsControllerFindAllResponseDataItemAllocationSummaryReducedCostsMax
                  ),
                costOfSupplements: zod.coerce
                  .number()
                  .min(
                    projectsControllerFindAllResponseDataItemAllocationSummaryCostOfSupplementsMin
                  )
                  .max(
                    projectsControllerFindAllResponseDataItemAllocationSummaryCostOfSupplementsMax
                  ),
                warrantyReservePercent: zod.coerce
                  .number()
                  .min(
                    projectsControllerFindAllResponseDataItemAllocationSummaryWarrantyReservePercentMin
                  )
                  .max(
                    projectsControllerFindAllResponseDataItemAllocationSummaryWarrantyReservePercentMax
                  ),
                contractValueConstruction: zod.coerce
                  .number()
                  .min(
                    projectsControllerFindAllResponseDataItemAllocationSummaryContractValueConstructionMin
                  )
                  .max(
                    projectsControllerFindAllResponseDataItemAllocationSummaryContractValueConstructionMax
                  ),
                warrantyReserveAmount: zod.coerce
                  .number()
                  .min(
                    projectsControllerFindAllResponseDataItemAllocationSummaryWarrantyReserveAmountMin
                  )
                  .max(
                    projectsControllerFindAllResponseDataItemAllocationSummaryWarrantyReserveAmountMax
                  ),
                profitLoss: zod.coerce
                  .number()
                  .min(
                    projectsControllerFindAllResponseDataItemAllocationSummaryProfitLossMin
                  )
                  .max(
                    projectsControllerFindAllResponseDataItemAllocationSummaryProfitLossMax
                  ),
                target: zod.coerce
                  .number()
                  .min(
                    projectsControllerFindAllResponseDataItemAllocationSummaryTargetMin
                  )
                  .nullable(),
                contractValueSum: zod.coerce
                  .number()
                  .min(
                    projectsControllerFindAllResponseDataItemAllocationSummaryContractValueSumMin
                  )
                  .max(
                    projectsControllerFindAllResponseDataItemAllocationSummaryContractValueSumMax
                  ),
                costsIncludingGAndAAndWarrantyReserve: zod.coerce
                  .number()
                  .min(
                    projectsControllerFindAllResponseDataItemAllocationSummaryCostsIncludingGAndAAndWarrantyReserveMin
                  )
                  .max(
                    projectsControllerFindAllResponseDataItemAllocationSummaryCostsIncludingGAndAAndWarrantyReserveMax
                  ),
                contributionMarginWithoutDiscountAndTaxAmount: zod.coerce
                  .number()
                  .min(
                    projectsControllerFindAllResponseDataItemAllocationSummaryContributionMarginWithoutDiscountAndTaxAmountMin
                  )
                  .max(
                    projectsControllerFindAllResponseDataItemAllocationSummaryContributionMarginWithoutDiscountAndTaxAmountMax
                  ),
                contributionMarginWithoutDiscountAndTaxPercent: zod.coerce
                  .number()
                  .min(
                    projectsControllerFindAllResponseDataItemAllocationSummaryContributionMarginWithoutDiscountAndTaxPercentMin
                  )
                  .max(
                    projectsControllerFindAllResponseDataItemAllocationSummaryContributionMarginWithoutDiscountAndTaxPercentMax
                  ),
                contributionMarginWithDiscountAndTaxAmount: zod.coerce
                  .number()
                  .min(
                    projectsControllerFindAllResponseDataItemAllocationSummaryContributionMarginWithDiscountAndTaxAmountMin
                  )
                  .max(
                    projectsControllerFindAllResponseDataItemAllocationSummaryContributionMarginWithDiscountAndTaxAmountMax
                  ),
                contributionMarginWithDiscountAndTaxPercent: zod.coerce
                  .number()
                  .min(
                    projectsControllerFindAllResponseDataItemAllocationSummaryContributionMarginWithDiscountAndTaxPercentMin
                  )
                  .max(
                    projectsControllerFindAllResponseDataItemAllocationSummaryContributionMarginWithDiscountAndTaxPercentMax
                  ),
              })
              .optional(),
            creditorCosts: zod
              .array(
                zod.object({
                  uuid: zod.coerce.string(),
                  project: zod.any(),
                  description: zod.coerce.string(),
                  remark: zod.coerce.string().nullable(),
                  quantity: zod.coerce.number(),
                  unit: zod.enum(['day', 'month', 'year', 'each']),
                  unitPrice: zod.coerce.number(),
                  sum: zod.coerce.number(),
                  createdAt: zod.coerce.date(),
                  updatedAt: zod.coerce.date(),
                })
              )
              .optional(),
            surchargeCosts: zod
              .array(
                zod.object({
                  uuid: zod.coerce.string().uuid(),
                  project: zod.any(),
                  description: zod.coerce.string(),
                  remark: zod.coerce.string(),
                  quantity: zod.coerce.number(),
                  unit: zod.enum(['year', 'month', 'day']),
                  basis: zod.coerce.number(),
                  basisType: zod.enum([
                    'contract_value',
                    'contract_value_plus',
                  ]),
                  share: zod.coerce.number(),
                  perYearPercent: zod.coerce.number(),
                  unitPrice: zod.coerce.number(),
                  sum: zod.coerce.number(),
                  createdAt: zod.coerce.date(),
                  updatedAt: zod.coerce.date(),
                })
              )
              .optional(),
            salaryCosts: zod
              .array(
                zod.object({
                  uuid: zod.coerce.string().uuid(),
                  project: zod.any(),
                  user: zod
                    .object({
                      uuid: zod.coerce.string().uuid(),
                      email: zod.coerce.string().email(),
                      firstName: zod.coerce
                        .string()
                        .max(
                          projectsControllerFindAllResponseDataItemSalaryCostsItemUserFirstNameMax
                        ),
                      lastName: zod.coerce
                        .string()
                        .max(
                          projectsControllerFindAllResponseDataItemSalaryCostsItemUserLastNameMax
                        ),
                      privatePhoneNumber: zod.coerce.string().optional(),
                      workPhoneNumber: zod.coerce.string().optional(),
                      status: zod.enum(['Active', 'Terminated']),
                      permissions: zod
                        .array(zod.enum(['admin', 'human-resource', 'user']))
                        .optional(),
                      createdAt: zod.coerce.date(),
                      updatedAt: zod.coerce.date(),
                      contracts: zod.array(
                        zod.object({
                          uuid: zod.coerce.string().uuid(),
                          user: zod.any(),
                          role: zod.object({
                            uuid: zod.coerce.string().uuid(),
                            name: zod.coerce.string(),
                          }),
                          department: zod.object({
                            uuid: zod.coerce.string().uuid(),
                            name: zod.coerce.string(),
                          }),
                          perHourSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemPerHourSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemPerHourSalaryMax
                            )
                            .optional(),
                          monthlyDuration: zod
                            .object({
                              days: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemMonthlyDurationDaysMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemMonthlyDurationDaysMax
                                )
                                .optional(),
                              hours: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemMonthlyDurationHoursMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemMonthlyDurationHoursMax
                                )
                                .optional(),
                              minutes: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemMonthlyDurationMinutesMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemMonthlyDurationMinutesMax
                                )
                                .optional(),
                              seconds: zod.coerce.number().optional(),
                              milliseconds: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemMonthlyDurationMillisecondsMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemMonthlyDurationMillisecondsMax
                                )
                                .optional(),
                            })
                            .optional(),
                          grossSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemGrossSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemGrossSalaryMax
                            ),
                          type: zod.enum(['full-time', 'part-time', 'hourly']),
                          startDate: zod.coerce.date(),
                          endDate: zod.coerce.date().optional(),
                          vacationPerMonth: zod.object({
                            days: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemVacationPerMonthDaysMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemVacationPerMonthDaysMax
                              )
                              .optional(),
                            hours: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemVacationPerMonthHoursMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemVacationPerMonthHoursMax
                              )
                              .optional(),
                            minutes: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemVacationPerMonthMinutesMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemVacationPerMonthMinutesMax
                              )
                              .optional(),
                            seconds: zod.coerce.number().optional(),
                            milliseconds: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemVacationPerMonthMillisecondsMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemVacationPerMonthMillisecondsMax
                              )
                              .optional(),
                          }),
                          attachments: zod.array(
                            zod.object({
                              name: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemAttachmentsItemNameMax
                                ),
                              path: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemAttachmentsItemPathMax
                                ),
                              mimeType: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemAttachmentsItemMimeTypeMax
                                )
                                .optional(),
                              size: zod.coerce.number().optional(),
                              expireAt: zod.coerce.date().optional(),
                              fileUrl: zod.coerce.string(),
                              uuid: zod.coerce.string(),
                              createdAt: zod.coerce.date(),
                              updatedAt: zod.coerce.date(),
                            })
                          ),
                          previousSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemPreviousSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemPreviousSalaryMax
                            ),
                          previousPerHourSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemPreviousPerHourSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemPreviousPerHourSalaryMax
                            ),
                          salaryDiff: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemSalaryDiffMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserContractsItemSalaryDiffMax
                            ),
                          isTypeChanged: zod.coerce.boolean(),
                          previousContractType: zod
                            .enum(['full-time', 'part-time', 'hourly'])
                            .optional(),
                          createdAt: zod.coerce.date(),
                          updatedAt: zod.coerce.date(),
                        })
                      ),
                      currentContract: zod
                        .object({
                          uuid: zod.coerce.string().uuid(),
                          user: zod.any(),
                          role: zod.object({
                            uuid: zod.coerce.string().uuid(),
                            name: zod.coerce.string(),
                          }),
                          department: zod.object({
                            uuid: zod.coerce.string().uuid(),
                            name: zod.coerce.string(),
                          }),
                          perHourSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractPerHourSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractPerHourSalaryMax
                            )
                            .optional(),
                          monthlyDuration: zod
                            .object({
                              days: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractMonthlyDurationDaysMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractMonthlyDurationDaysMax
                                )
                                .optional(),
                              hours: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractMonthlyDurationHoursMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractMonthlyDurationHoursMax
                                )
                                .optional(),
                              minutes: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractMonthlyDurationMinutesMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractMonthlyDurationMinutesMax
                                )
                                .optional(),
                              seconds: zod.coerce.number().optional(),
                              milliseconds: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractMonthlyDurationMillisecondsMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractMonthlyDurationMillisecondsMax
                                )
                                .optional(),
                            })
                            .optional(),
                          grossSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractGrossSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractGrossSalaryMax
                            ),
                          type: zod.enum(['full-time', 'part-time', 'hourly']),
                          startDate: zod.coerce.date(),
                          endDate: zod.coerce.date().optional(),
                          vacationPerMonth: zod.object({
                            days: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractVacationPerMonthDaysMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractVacationPerMonthDaysMax
                              )
                              .optional(),
                            hours: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractVacationPerMonthHoursMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractVacationPerMonthHoursMax
                              )
                              .optional(),
                            minutes: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractVacationPerMonthMinutesMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractVacationPerMonthMinutesMax
                              )
                              .optional(),
                            seconds: zod.coerce.number().optional(),
                            milliseconds: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractVacationPerMonthMillisecondsMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractVacationPerMonthMillisecondsMax
                              )
                              .optional(),
                          }),
                          attachments: zod.array(
                            zod.object({
                              name: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractAttachmentsItemNameMax
                                ),
                              path: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractAttachmentsItemPathMax
                                ),
                              mimeType: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractAttachmentsItemMimeTypeMax
                                )
                                .optional(),
                              size: zod.coerce.number().optional(),
                              expireAt: zod.coerce.date().optional(),
                              fileUrl: zod.coerce.string(),
                              uuid: zod.coerce.string(),
                              createdAt: zod.coerce.date(),
                              updatedAt: zod.coerce.date(),
                            })
                          ),
                          previousSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractPreviousSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractPreviousSalaryMax
                            ),
                          previousPerHourSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractPreviousPerHourSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractPreviousPerHourSalaryMax
                            ),
                          salaryDiff: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractSalaryDiffMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserCurrentContractSalaryDiffMax
                            ),
                          isTypeChanged: zod.coerce.boolean(),
                          previousContractType: zod
                            .enum(['full-time', 'part-time', 'hourly'])
                            .optional(),
                          createdAt: zod.coerce.date(),
                          updatedAt: zod.coerce.date(),
                        })
                        .optional(),
                      salaries: zod.array(
                        zod.object({
                          uuid: zod.coerce.string().uuid(),
                          user: zod.any(),
                          date: zod.coerce.date(),
                          paymentDate: zod.coerce.date().optional(),
                          perHour: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemPerHourMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemPerHourMax
                            )
                            .optional(),
                          monthlyDuration: zod
                            .object({
                              days: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemMonthlyDurationDaysMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemMonthlyDurationDaysMax
                                )
                                .optional(),
                              hours: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemMonthlyDurationHoursMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemMonthlyDurationHoursMax
                                )
                                .optional(),
                              minutes: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemMonthlyDurationMinutesMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemMonthlyDurationMinutesMax
                                )
                                .optional(),
                              seconds: zod.coerce.number().optional(),
                              milliseconds: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemMonthlyDurationMillisecondsMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemMonthlyDurationMillisecondsMax
                                )
                                .optional(),
                            })
                            .optional(),
                          base: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemBaseMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemBaseMax
                            ),
                          bonus: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemBonusMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemBonusMax
                            )
                            .optional(),
                          paid: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemPaidMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserSalariesItemPaidMax
                            ),
                          type: zod.enum(['full-time', 'part-time', 'hourly']),
                          role: zod.object({
                            uuid: zod.coerce.string().uuid(),
                            name: zod.coerce.string(),
                          }),
                          department: zod.object({
                            uuid: zod.coerce.string().uuid(),
                            name: zod.coerce.string(),
                          }),
                          createdAt: zod.coerce.date(),
                          updatedAt: zod.coerce.date(),
                          isMultiContract: zod.coerce.boolean(),
                        })
                      ),
                      hireDate: zod.coerce.date().optional(),
                      profilePicture: zod.coerce.string(),
                      fullName: zod.coerce.string(),
                      leaveTypes: zod.array(
                        zod.object({
                          uuid: zod.coerce.string().uuid(),
                          name: zod.coerce
                            .string()
                            .min(1)
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserLeaveTypesItemNameMax
                            ),
                          description: zod.coerce
                            .string()
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserLeaveTypesItemDescriptionMax
                            )
                            .optional(),
                          color: zod.coerce
                            .string()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserLeaveTypesItemColorMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserLeaveTypesItemColorMax
                            )
                            .regex(
                              projectsControllerFindAllResponseDataItemSalaryCostsItemUserLeaveTypesItemColorRegExp
                            )
                            .optional(),
                          allowedPerYear: zod.object({
                            days: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemSalaryCostsItemUserLeaveTypesItemAllowedPerYearDaysMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemSalaryCostsItemUserLeaveTypesItemAllowedPerYearDaysMax
                              )
                              .optional(),
                            hours: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemSalaryCostsItemUserLeaveTypesItemAllowedPerYearHoursMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemSalaryCostsItemUserLeaveTypesItemAllowedPerYearHoursMax
                              )
                              .optional(),
                            minutes: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemSalaryCostsItemUserLeaveTypesItemAllowedPerYearMinutesMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemSalaryCostsItemUserLeaveTypesItemAllowedPerYearMinutesMax
                              )
                              .optional(),
                            seconds: zod.coerce.number().optional(),
                            milliseconds: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemSalaryCostsItemUserLeaveTypesItemAllowedPerYearMillisecondsMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemSalaryCostsItemUserLeaveTypesItemAllowedPerYearMillisecondsMax
                              )
                              .optional(),
                          }),
                          isFreebie: zod.coerce.boolean().optional(),
                          isWithAttachment: zod.coerce.boolean().optional(),
                        })
                      ),
                    })
                    .nullish(),
                  role: zod.coerce.string(),
                  abbr: zod.coerce.string(),
                  duration: zod.coerce.number(),
                  cost: zod.coerce.number(),
                  unit: zod.enum(['year', 'month', 'day']),
                  employmentRate: zod.coerce
                    .number()
                    .min(
                      projectsControllerFindAllResponseDataItemSalaryCostsItemEmploymentRateMin
                    )
                    .max(
                      projectsControllerFindAllResponseDataItemSalaryCostsItemEmploymentRateMax
                    ),
                  remarks: zod.coerce.string(),
                  sum: zod.coerce.number(),
                  orderIndex: zod.coerce
                    .number()
                    .min(1)
                    .max(
                      projectsControllerFindAllResponseDataItemSalaryCostsItemOrderIndexMax
                    ),
                  createdAt: zod.coerce.date(),
                  updatedAt: zod.coerce.date(),
                })
              )
              .optional(),
            salaryActuals: zod
              .array(
                zod.object({
                  uuid: zod.coerce.string().uuid(),
                  project: zod.any(),
                  user: zod
                    .object({
                      uuid: zod.coerce.string().uuid(),
                      email: zod.coerce.string().email(),
                      firstName: zod.coerce
                        .string()
                        .max(
                          projectsControllerFindAllResponseDataItemSalaryActualsItemUserFirstNameMax
                        ),
                      lastName: zod.coerce
                        .string()
                        .max(
                          projectsControllerFindAllResponseDataItemSalaryActualsItemUserLastNameMax
                        ),
                      privatePhoneNumber: zod.coerce.string().optional(),
                      workPhoneNumber: zod.coerce.string().optional(),
                      status: zod.enum(['Active', 'Terminated']),
                      permissions: zod
                        .array(zod.enum(['admin', 'human-resource', 'user']))
                        .optional(),
                      createdAt: zod.coerce.date(),
                      updatedAt: zod.coerce.date(),
                      contracts: zod.array(
                        zod.object({
                          uuid: zod.coerce.string().uuid(),
                          user: zod.any(),
                          role: zod.object({
                            uuid: zod.coerce.string().uuid(),
                            name: zod.coerce.string(),
                          }),
                          department: zod.object({
                            uuid: zod.coerce.string().uuid(),
                            name: zod.coerce.string(),
                          }),
                          perHourSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemPerHourSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemPerHourSalaryMax
                            )
                            .optional(),
                          monthlyDuration: zod
                            .object({
                              days: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemMonthlyDurationDaysMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemMonthlyDurationDaysMax
                                )
                                .optional(),
                              hours: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemMonthlyDurationHoursMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemMonthlyDurationHoursMax
                                )
                                .optional(),
                              minutes: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemMonthlyDurationMinutesMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemMonthlyDurationMinutesMax
                                )
                                .optional(),
                              seconds: zod.coerce.number().optional(),
                              milliseconds: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemMonthlyDurationMillisecondsMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemMonthlyDurationMillisecondsMax
                                )
                                .optional(),
                            })
                            .optional(),
                          grossSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemGrossSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemGrossSalaryMax
                            ),
                          type: zod.enum(['full-time', 'part-time', 'hourly']),
                          startDate: zod.coerce.date(),
                          endDate: zod.coerce.date().optional(),
                          vacationPerMonth: zod.object({
                            days: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemVacationPerMonthDaysMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemVacationPerMonthDaysMax
                              )
                              .optional(),
                            hours: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemVacationPerMonthHoursMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemVacationPerMonthHoursMax
                              )
                              .optional(),
                            minutes: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemVacationPerMonthMinutesMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemVacationPerMonthMinutesMax
                              )
                              .optional(),
                            seconds: zod.coerce.number().optional(),
                            milliseconds: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemVacationPerMonthMillisecondsMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemVacationPerMonthMillisecondsMax
                              )
                              .optional(),
                          }),
                          attachments: zod.array(
                            zod.object({
                              name: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemAttachmentsItemNameMax
                                ),
                              path: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemAttachmentsItemPathMax
                                ),
                              mimeType: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemAttachmentsItemMimeTypeMax
                                )
                                .optional(),
                              size: zod.coerce.number().optional(),
                              expireAt: zod.coerce.date().optional(),
                              fileUrl: zod.coerce.string(),
                              uuid: zod.coerce.string(),
                              createdAt: zod.coerce.date(),
                              updatedAt: zod.coerce.date(),
                            })
                          ),
                          previousSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemPreviousSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemPreviousSalaryMax
                            ),
                          previousPerHourSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemPreviousPerHourSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemPreviousPerHourSalaryMax
                            ),
                          salaryDiff: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemSalaryDiffMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserContractsItemSalaryDiffMax
                            ),
                          isTypeChanged: zod.coerce.boolean(),
                          previousContractType: zod
                            .enum(['full-time', 'part-time', 'hourly'])
                            .optional(),
                          createdAt: zod.coerce.date(),
                          updatedAt: zod.coerce.date(),
                        })
                      ),
                      currentContract: zod
                        .object({
                          uuid: zod.coerce.string().uuid(),
                          user: zod.any(),
                          role: zod.object({
                            uuid: zod.coerce.string().uuid(),
                            name: zod.coerce.string(),
                          }),
                          department: zod.object({
                            uuid: zod.coerce.string().uuid(),
                            name: zod.coerce.string(),
                          }),
                          perHourSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractPerHourSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractPerHourSalaryMax
                            )
                            .optional(),
                          monthlyDuration: zod
                            .object({
                              days: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractMonthlyDurationDaysMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractMonthlyDurationDaysMax
                                )
                                .optional(),
                              hours: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractMonthlyDurationHoursMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractMonthlyDurationHoursMax
                                )
                                .optional(),
                              minutes: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractMonthlyDurationMinutesMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractMonthlyDurationMinutesMax
                                )
                                .optional(),
                              seconds: zod.coerce.number().optional(),
                              milliseconds: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractMonthlyDurationMillisecondsMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractMonthlyDurationMillisecondsMax
                                )
                                .optional(),
                            })
                            .optional(),
                          grossSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractGrossSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractGrossSalaryMax
                            ),
                          type: zod.enum(['full-time', 'part-time', 'hourly']),
                          startDate: zod.coerce.date(),
                          endDate: zod.coerce.date().optional(),
                          vacationPerMonth: zod.object({
                            days: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractVacationPerMonthDaysMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractVacationPerMonthDaysMax
                              )
                              .optional(),
                            hours: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractVacationPerMonthHoursMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractVacationPerMonthHoursMax
                              )
                              .optional(),
                            minutes: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractVacationPerMonthMinutesMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractVacationPerMonthMinutesMax
                              )
                              .optional(),
                            seconds: zod.coerce.number().optional(),
                            milliseconds: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractVacationPerMonthMillisecondsMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractVacationPerMonthMillisecondsMax
                              )
                              .optional(),
                          }),
                          attachments: zod.array(
                            zod.object({
                              name: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractAttachmentsItemNameMax
                                ),
                              path: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractAttachmentsItemPathMax
                                ),
                              mimeType: zod.coerce
                                .string()
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractAttachmentsItemMimeTypeMax
                                )
                                .optional(),
                              size: zod.coerce.number().optional(),
                              expireAt: zod.coerce.date().optional(),
                              fileUrl: zod.coerce.string(),
                              uuid: zod.coerce.string(),
                              createdAt: zod.coerce.date(),
                              updatedAt: zod.coerce.date(),
                            })
                          ),
                          previousSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractPreviousSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractPreviousSalaryMax
                            ),
                          previousPerHourSalary: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractPreviousPerHourSalaryMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractPreviousPerHourSalaryMax
                            ),
                          salaryDiff: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractSalaryDiffMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserCurrentContractSalaryDiffMax
                            ),
                          isTypeChanged: zod.coerce.boolean(),
                          previousContractType: zod
                            .enum(['full-time', 'part-time', 'hourly'])
                            .optional(),
                          createdAt: zod.coerce.date(),
                          updatedAt: zod.coerce.date(),
                        })
                        .optional(),
                      salaries: zod.array(
                        zod.object({
                          uuid: zod.coerce.string().uuid(),
                          user: zod.any(),
                          date: zod.coerce.date(),
                          paymentDate: zod.coerce.date().optional(),
                          perHour: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemPerHourMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemPerHourMax
                            )
                            .optional(),
                          monthlyDuration: zod
                            .object({
                              days: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemMonthlyDurationDaysMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemMonthlyDurationDaysMax
                                )
                                .optional(),
                              hours: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemMonthlyDurationHoursMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemMonthlyDurationHoursMax
                                )
                                .optional(),
                              minutes: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemMonthlyDurationMinutesMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemMonthlyDurationMinutesMax
                                )
                                .optional(),
                              seconds: zod.coerce.number().optional(),
                              milliseconds: zod.coerce
                                .number()
                                .min(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemMonthlyDurationMillisecondsMin
                                )
                                .max(
                                  projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemMonthlyDurationMillisecondsMax
                                )
                                .optional(),
                            })
                            .optional(),
                          base: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemBaseMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemBaseMax
                            ),
                          bonus: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemBonusMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemBonusMax
                            )
                            .optional(),
                          paid: zod.coerce
                            .number()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemPaidMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserSalariesItemPaidMax
                            ),
                          type: zod.enum(['full-time', 'part-time', 'hourly']),
                          role: zod.object({
                            uuid: zod.coerce.string().uuid(),
                            name: zod.coerce.string(),
                          }),
                          department: zod.object({
                            uuid: zod.coerce.string().uuid(),
                            name: zod.coerce.string(),
                          }),
                          createdAt: zod.coerce.date(),
                          updatedAt: zod.coerce.date(),
                          isMultiContract: zod.coerce.boolean(),
                        })
                      ),
                      hireDate: zod.coerce.date().optional(),
                      profilePicture: zod.coerce.string(),
                      fullName: zod.coerce.string(),
                      leaveTypes: zod.array(
                        zod.object({
                          uuid: zod.coerce.string().uuid(),
                          name: zod.coerce
                            .string()
                            .min(1)
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserLeaveTypesItemNameMax
                            ),
                          description: zod.coerce
                            .string()
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserLeaveTypesItemDescriptionMax
                            )
                            .optional(),
                          color: zod.coerce
                            .string()
                            .min(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserLeaveTypesItemColorMin
                            )
                            .max(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserLeaveTypesItemColorMax
                            )
                            .regex(
                              projectsControllerFindAllResponseDataItemSalaryActualsItemUserLeaveTypesItemColorRegExp
                            )
                            .optional(),
                          allowedPerYear: zod.object({
                            days: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemSalaryActualsItemUserLeaveTypesItemAllowedPerYearDaysMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemSalaryActualsItemUserLeaveTypesItemAllowedPerYearDaysMax
                              )
                              .optional(),
                            hours: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemSalaryActualsItemUserLeaveTypesItemAllowedPerYearHoursMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemSalaryActualsItemUserLeaveTypesItemAllowedPerYearHoursMax
                              )
                              .optional(),
                            minutes: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemSalaryActualsItemUserLeaveTypesItemAllowedPerYearMinutesMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemSalaryActualsItemUserLeaveTypesItemAllowedPerYearMinutesMax
                              )
                              .optional(),
                            seconds: zod.coerce.number().optional(),
                            milliseconds: zod.coerce
                              .number()
                              .min(
                                projectsControllerFindAllResponseDataItemSalaryActualsItemUserLeaveTypesItemAllowedPerYearMillisecondsMin
                              )
                              .max(
                                projectsControllerFindAllResponseDataItemSalaryActualsItemUserLeaveTypesItemAllowedPerYearMillisecondsMax
                              )
                              .optional(),
                          }),
                          isFreebie: zod.coerce.boolean().optional(),
                          isWithAttachment: zod.coerce.boolean().optional(),
                        })
                      ),
                    })
                    .nullish(),
                  role: zod.coerce.string(),
                  employmentRate: zod.coerce
                    .number()
                    .min(
                      projectsControllerFindAllResponseDataItemSalaryActualsItemEmploymentRateMin
                    )
                    .max(
                      projectsControllerFindAllResponseDataItemSalaryActualsItemEmploymentRateMax
                    ),
                  date: zod.coerce.date(),
                  remarks: zod.coerce.string(),
                  createdAt: zod.coerce.date(),
                  updatedAt: zod.coerce.date(),
                })
              )
              .optional(),
            additionReduceCost: zod
              .array(
                zod.object({
                  uuid: zod.coerce.string(),
                  project: zod.any(),
                  tag: zod.object({
                    uuid: zod.coerce.string().uuid(),
                    parent: zod.any().nullish(),
                    children: zod.array(zod.any()).optional(),
                    name: zod.coerce
                      .string()
                      .max(
                        projectsControllerFindAllResponseDataItemAdditionReduceCostItemTagNameMax
                      ),
                    companyType: zod.enum([
                      'Client',
                      'Contractor',
                      'Purchase',
                      'Surcharge',
                      'Creditor',
                    ]),
                    serial: zod.coerce.string().optional(),
                    priority: zod.coerce.number().nullish(),
                    aliases: zod
                      .array(
                        zod.object({
                          uuid: zod.coerce.string().uuid(),
                          isAbbr: zod.coerce.boolean(),
                          tag: zod.any(),
                          nameAlias: zod.coerce
                            .string()
                            .min(1)
                            .max(
                              projectsControllerFindAllResponseDataItemAdditionReduceCostItemTagAliasesItemNameAliasMax
                            ),
                          language: zod.coerce.string().optional(),
                        })
                      )
                      .optional(),
                  }),
                  rowNumber: zod.coerce.string(),
                  sumSub: zod.coerce.number().nullable(),
                  createdAt: zod.coerce.date(),
                  updatedAt: zod.coerce.date(),
                  type: zod.coerce.string(),
                  items: zod.array(
                    zod.object({
                      uuid: zod.coerce.string(),
                      additionReduceCost: zod.any(),
                      rowNumber: zod.coerce.string(),
                      description: zod.coerce.string().optional(),
                      quantity: zod.coerce.number(),
                      unit: zod.coerce.string(),
                      unitPriceSub: zod.coerce.number(),
                      sumSub: zod.coerce.number(),
                      unitPriceGcPercent: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemAdditionReduceCostItemItemsItemUnitPriceGcPercentMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemAdditionReduceCostItemItemsItemUnitPriceGcPercentMax
                        ),
                      unitPriceGc: zod.coerce.number().optional(),
                      sumGc: zod.coerce.number(),
                      differenceGcSub: zod.coerce.number().nullish(),
                      instructedSum: zod.coerce.number(),
                      status: zod.enum([
                        'open',
                        'rejected',
                        'instructed',
                        'paid',
                      ]),
                      createdAt: zod.coerce.date(),
                      updatedAt: zod.coerce.date(),
                      additionDate: zod.coerce.date().nullable(),
                      additionTitle: zod.coerce.string().nullable(),
                      additionNumber: zod.coerce.string().nullable(),
                      authorizedAmount: zod.coerce.number().nullable(),
                      remarks: zod.coerce.string().nullable(),
                    })
                  ),
                })
              )
              .optional(),
            actualCost: zod
              .array(
                zod.object({
                  uuid: zod.coerce.string(),
                  project: zod.any(),
                  valueType: zod
                    .enum([
                      'salary',
                      'subcontractor',
                      'bgk',
                      'agk',
                      'agk_actual',
                      'insurance',
                      'financial-plan',
                    ])
                    .nullable(),
                  date: zod.coerce.date().nullable(),
                  actualValue: zod.coerce.number().nullable(),
                  createdAt: zod.coerce.date(),
                  updatedAt: zod.coerce.date(),
                })
              )
              .optional(),
            actualTargetPercent: zod
              .array(
                zod.object({
                  uuid: zod.coerce.string(),
                  project: zod.any(),
                  percentType: zod
                    .enum(['subcontractor', 'agk', 'agk_actual', 'insurance'])
                    .nullable(),
                  value: zod.coerce
                    .number()
                    .min(
                      projectsControllerFindAllResponseDataItemActualTargetPercentItemValueMin
                    )
                    .max(
                      projectsControllerFindAllResponseDataItemActualTargetPercentItemValueMax
                    )
                    .nullable(),
                  createdAt: zod.coerce.date(),
                  updatedAt: zod.coerce.date(),
                })
              )
              .optional(),
            grossProfitPercent: zod.coerce
              .number()
              .min(
                projectsControllerFindAllResponseDataItemGrossProfitPercentMin
              )
              .nullish(),
            financialPlans: zod
              .array(
                zod.object({
                  uuid: zod.coerce.string(),
                  invoiceType: zod.enum(['AR', 'SR']),
                  invoiceNumber: zod.coerce
                    .number()
                    .max(
                      projectsControllerFindAllResponseDataItemFinancialPlansItemInvoiceNumberMax
                    ),
                  date: zod.coerce.date(),
                  valueNet: zod.coerce.number(),
                  tax: zod.coerce
                    .number()
                    .max(
                      projectsControllerFindAllResponseDataItemFinancialPlansItemTaxMax
                    ),
                  valueGross: zod.coerce.number(),
                  valueNetCumulative: zod.coerce.number().nullable(),
                  valueGrossCumulative: zod.coerce.number().nullable(),
                  remark: zod.coerce.string().nullable(),
                  createdAt: zod.coerce.date(),
                  updatedAt: zod.coerce.date(),
                })
              )
              .optional(),
            totalAllocations: zod.coerce
              .number()
              .min(
                projectsControllerFindAllResponseDataItemTotalAllocationsMin
              ),
            projectManagers: zod.array(
              zod.object({
                uuid: zod.coerce.string().uuid(),
                email: zod.coerce.string().email(),
                firstName: zod.coerce
                  .string()
                  .max(
                    projectsControllerFindAllResponseDataItemProjectManagersItemFirstNameMax
                  ),
                lastName: zod.coerce
                  .string()
                  .max(
                    projectsControllerFindAllResponseDataItemProjectManagersItemLastNameMax
                  ),
                privatePhoneNumber: zod.coerce.string().optional(),
                workPhoneNumber: zod.coerce.string().optional(),
                status: zod.enum(['Active', 'Terminated']),
                permissions: zod
                  .array(zod.enum(['admin', 'human-resource', 'user']))
                  .optional(),
                createdAt: zod.coerce.date(),
                updatedAt: zod.coerce.date(),
                contracts: zod.array(
                  zod.object({
                    uuid: zod.coerce.string().uuid(),
                    user: zod.any(),
                    role: zod.object({
                      uuid: zod.coerce.string().uuid(),
                      name: zod.coerce.string(),
                    }),
                    department: zod.object({
                      uuid: zod.coerce.string().uuid(),
                      name: zod.coerce.string(),
                    }),
                    perHourSalary: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemPerHourSalaryMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemPerHourSalaryMax
                      )
                      .optional(),
                    monthlyDuration: zod
                      .object({
                        days: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemMonthlyDurationDaysMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemMonthlyDurationDaysMax
                          )
                          .optional(),
                        hours: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemMonthlyDurationHoursMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemMonthlyDurationHoursMax
                          )
                          .optional(),
                        minutes: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemMonthlyDurationMinutesMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemMonthlyDurationMinutesMax
                          )
                          .optional(),
                        seconds: zod.coerce.number().optional(),
                        milliseconds: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemMonthlyDurationMillisecondsMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemMonthlyDurationMillisecondsMax
                          )
                          .optional(),
                      })
                      .optional(),
                    grossSalary: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemGrossSalaryMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemGrossSalaryMax
                      ),
                    type: zod.enum(['full-time', 'part-time', 'hourly']),
                    startDate: zod.coerce.date(),
                    endDate: zod.coerce.date().optional(),
                    vacationPerMonth: zod.object({
                      days: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemVacationPerMonthDaysMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemVacationPerMonthDaysMax
                        )
                        .optional(),
                      hours: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemVacationPerMonthHoursMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemVacationPerMonthHoursMax
                        )
                        .optional(),
                      minutes: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemVacationPerMonthMinutesMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemVacationPerMonthMinutesMax
                        )
                        .optional(),
                      seconds: zod.coerce.number().optional(),
                      milliseconds: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemVacationPerMonthMillisecondsMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemVacationPerMonthMillisecondsMax
                        )
                        .optional(),
                    }),
                    attachments: zod.array(
                      zod.object({
                        name: zod.coerce
                          .string()
                          .max(
                            projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemAttachmentsItemNameMax
                          ),
                        path: zod.coerce
                          .string()
                          .max(
                            projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemAttachmentsItemPathMax
                          ),
                        mimeType: zod.coerce
                          .string()
                          .max(
                            projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemAttachmentsItemMimeTypeMax
                          )
                          .optional(),
                        size: zod.coerce.number().optional(),
                        expireAt: zod.coerce.date().optional(),
                        fileUrl: zod.coerce.string(),
                        uuid: zod.coerce.string(),
                        createdAt: zod.coerce.date(),
                        updatedAt: zod.coerce.date(),
                      })
                    ),
                    previousSalary: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemPreviousSalaryMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemPreviousSalaryMax
                      ),
                    previousPerHourSalary: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemPreviousPerHourSalaryMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemPreviousPerHourSalaryMax
                      ),
                    salaryDiff: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemSalaryDiffMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectManagersItemContractsItemSalaryDiffMax
                      ),
                    isTypeChanged: zod.coerce.boolean(),
                    previousContractType: zod
                      .enum(['full-time', 'part-time', 'hourly'])
                      .optional(),
                    createdAt: zod.coerce.date(),
                    updatedAt: zod.coerce.date(),
                  })
                ),
                currentContract: zod
                  .object({
                    uuid: zod.coerce.string().uuid(),
                    user: zod.any(),
                    role: zod.object({
                      uuid: zod.coerce.string().uuid(),
                      name: zod.coerce.string(),
                    }),
                    department: zod.object({
                      uuid: zod.coerce.string().uuid(),
                      name: zod.coerce.string(),
                    }),
                    perHourSalary: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractPerHourSalaryMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractPerHourSalaryMax
                      )
                      .optional(),
                    monthlyDuration: zod
                      .object({
                        days: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractMonthlyDurationDaysMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractMonthlyDurationDaysMax
                          )
                          .optional(),
                        hours: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractMonthlyDurationHoursMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractMonthlyDurationHoursMax
                          )
                          .optional(),
                        minutes: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractMonthlyDurationMinutesMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractMonthlyDurationMinutesMax
                          )
                          .optional(),
                        seconds: zod.coerce.number().optional(),
                        milliseconds: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractMonthlyDurationMillisecondsMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractMonthlyDurationMillisecondsMax
                          )
                          .optional(),
                      })
                      .optional(),
                    grossSalary: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractGrossSalaryMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractGrossSalaryMax
                      ),
                    type: zod.enum(['full-time', 'part-time', 'hourly']),
                    startDate: zod.coerce.date(),
                    endDate: zod.coerce.date().optional(),
                    vacationPerMonth: zod.object({
                      days: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractVacationPerMonthDaysMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractVacationPerMonthDaysMax
                        )
                        .optional(),
                      hours: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractVacationPerMonthHoursMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractVacationPerMonthHoursMax
                        )
                        .optional(),
                      minutes: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractVacationPerMonthMinutesMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractVacationPerMonthMinutesMax
                        )
                        .optional(),
                      seconds: zod.coerce.number().optional(),
                      milliseconds: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractVacationPerMonthMillisecondsMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractVacationPerMonthMillisecondsMax
                        )
                        .optional(),
                    }),
                    attachments: zod.array(
                      zod.object({
                        name: zod.coerce
                          .string()
                          .max(
                            projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractAttachmentsItemNameMax
                          ),
                        path: zod.coerce
                          .string()
                          .max(
                            projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractAttachmentsItemPathMax
                          ),
                        mimeType: zod.coerce
                          .string()
                          .max(
                            projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractAttachmentsItemMimeTypeMax
                          )
                          .optional(),
                        size: zod.coerce.number().optional(),
                        expireAt: zod.coerce.date().optional(),
                        fileUrl: zod.coerce.string(),
                        uuid: zod.coerce.string(),
                        createdAt: zod.coerce.date(),
                        updatedAt: zod.coerce.date(),
                      })
                    ),
                    previousSalary: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractPreviousSalaryMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractPreviousSalaryMax
                      ),
                    previousPerHourSalary: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractPreviousPerHourSalaryMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractPreviousPerHourSalaryMax
                      ),
                    salaryDiff: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractSalaryDiffMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectManagersItemCurrentContractSalaryDiffMax
                      ),
                    isTypeChanged: zod.coerce.boolean(),
                    previousContractType: zod
                      .enum(['full-time', 'part-time', 'hourly'])
                      .optional(),
                    createdAt: zod.coerce.date(),
                    updatedAt: zod.coerce.date(),
                  })
                  .optional(),
                salaries: zod.array(
                  zod.object({
                    uuid: zod.coerce.string().uuid(),
                    user: zod.any(),
                    date: zod.coerce.date(),
                    paymentDate: zod.coerce.date().optional(),
                    perHour: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemPerHourMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemPerHourMax
                      )
                      .optional(),
                    monthlyDuration: zod
                      .object({
                        days: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemMonthlyDurationDaysMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemMonthlyDurationDaysMax
                          )
                          .optional(),
                        hours: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemMonthlyDurationHoursMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemMonthlyDurationHoursMax
                          )
                          .optional(),
                        minutes: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemMonthlyDurationMinutesMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemMonthlyDurationMinutesMax
                          )
                          .optional(),
                        seconds: zod.coerce.number().optional(),
                        milliseconds: zod.coerce
                          .number()
                          .min(
                            projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemMonthlyDurationMillisecondsMin
                          )
                          .max(
                            projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemMonthlyDurationMillisecondsMax
                          )
                          .optional(),
                      })
                      .optional(),
                    base: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemBaseMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemBaseMax
                      ),
                    bonus: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemBonusMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemBonusMax
                      )
                      .optional(),
                    paid: zod.coerce
                      .number()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemPaidMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectManagersItemSalariesItemPaidMax
                      ),
                    type: zod.enum(['full-time', 'part-time', 'hourly']),
                    role: zod.object({
                      uuid: zod.coerce.string().uuid(),
                      name: zod.coerce.string(),
                    }),
                    department: zod.object({
                      uuid: zod.coerce.string().uuid(),
                      name: zod.coerce.string(),
                    }),
                    createdAt: zod.coerce.date(),
                    updatedAt: zod.coerce.date(),
                    isMultiContract: zod.coerce.boolean(),
                  })
                ),
                hireDate: zod.coerce.date().optional(),
                profilePicture: zod.coerce.string(),
                fullName: zod.coerce.string(),
                leaveTypes: zod.array(
                  zod.object({
                    uuid: zod.coerce.string().uuid(),
                    name: zod.coerce
                      .string()
                      .min(1)
                      .max(
                        projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemNameMax
                      ),
                    description: zod.coerce
                      .string()
                      .max(
                        projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemDescriptionMax
                      )
                      .optional(),
                    color: zod.coerce
                      .string()
                      .min(
                        projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemColorMin
                      )
                      .max(
                        projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemColorMax
                      )
                      .regex(
                        projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemColorRegExp
                      )
                      .optional(),
                    allowedPerYear: zod.object({
                      days: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemAllowedPerYearDaysMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemAllowedPerYearDaysMax
                        )
                        .optional(),
                      hours: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemAllowedPerYearHoursMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemAllowedPerYearHoursMax
                        )
                        .optional(),
                      minutes: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemAllowedPerYearMinutesMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemAllowedPerYearMinutesMax
                        )
                        .optional(),
                      seconds: zod.coerce.number().optional(),
                      milliseconds: zod.coerce
                        .number()
                        .min(
                          projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemAllowedPerYearMillisecondsMin
                        )
                        .max(
                          projectsControllerFindAllResponseDataItemProjectManagersItemLeaveTypesItemAllowedPerYearMillisecondsMax
                        )
                        .optional(),
                    }),
                    isFreebie: zod.coerce.boolean().optional(),
                    isWithAttachment: zod.coerce.boolean().optional(),
                  })
                ),
              })
            ),
            attachments: zod
              .array(
                zod.object({
                  name: zod.coerce
                    .string()
                    .max(
                      projectsControllerFindAllResponseDataItemAttachmentsItemNameMax
                    ),
                  path: zod.coerce
                    .string()
                    .max(
                      projectsControllerFindAllResponseDataItemAttachmentsItemPathMax
                    ),
                  mimeType: zod.coerce
                    .string()
                    .max(
                      projectsControllerFindAllResponseDataItemAttachmentsItemMimeTypeMax
                    )
                    .optional(),
                  size: zod.coerce.number().optional(),
                  expireAt: zod.coerce.date().optional(),
                  fileUrl: zod.coerce.string(),
                  uuid: zod.coerce.string(),
                  createdAt: zod.coerce.date(),
                  updatedAt: zod.coerce.date(),
                })
              )
              .optional(),
            uuid: zod.coerce.string(),
            name: zod.coerce.string(),
            address: zod.object({
              uuid: zod.coerce.string().uuid(),
              country: zod.coerce.string().optional(),
              city: zod.coerce.string().nullish(),
              street: zod.coerce.string().nullish(),
              postcode: zod.coerce.string().nullish(),
              lat: zod.coerce
                .number()
                .min(projectsControllerFindAllResponseDataItemAddressLatMin)
                .max(projectsControllerFindAllResponseDataItemAddressLatMax)
                .nullish(),
              lon: zod.coerce
                .number()
                .min(projectsControllerFindAllResponseDataItemAddressLonMin)
                .max(projectsControllerFindAllResponseDataItemAddressLonMax)
                .nullish(),
            }),
            createdAt: zod.coerce.date(),
            updatedAt: zod.coerce.date(),
          })
        )
        .optional(),
      meta: zod
        .object({
          select: zod.array(zod.enum(['uuid', 'name'])).optional(),
          filter: zod
            .object({
              name: zod.coerce
                .string()
                .or(zod.array(zod.coerce.string()))
                .optional(),
            })
            .optional(),
        })
        .optional(),
    })
  )
