import type React from 'react'
import {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useTransition,
} from 'react'
import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  type RefresherEventDetail,
  useIonLoading,
  useIonViewWillEnter,
} from '@ionic/react'
import './timer.css'
import { DateTime } from 'luxon'
import {
  type Project,
  type StartTimeTrackingDto,
  timeTrackingControllerStartBody,
  TimeTrackingType,
  useProjectsControllerFindAll,
  useTimeTrackingControllerBreak,
  useTimeTrackingControllerEnd,
  useTimeTrackingControllerLastHook,
  useTimeTrackingControllerStart,
  useTimeTrackingControllerWorkInfoHook,
  WorkStatus,
  WorkType,
} from '@/api'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  TimerButtonState,
  timerInitialState,
  timerReducer,
} from '@/reducers/timer.reducer'
import { useStopwatch, useTime } from 'react-timer-hook'
import {
  businessOutline,
  carOutline,
  homeOutline,
  locationOutline,
} from 'ionicons/icons'
import { Gradient, type GradientProps } from './gradient'
import { ProgressCircle } from './progress-circle'
import { TimerButton } from '@/components/tabs/timer-button'
import { UseFormRequestError } from '@/hooks/use-form-request-error'
import { FormErrorMessage } from '@/components/_form-error-message'
import type { IonRefresherCustomEvent } from '@ionic/core/dist/types/components'
import { useLocalNotification } from '@/hooks/use-local-notification'
import { NotificationActionType } from '@/enums/notification-action-type.enum'
import { NotificationId } from '@/enums/notification-id.enum'
import AppTypeahead from '@/AppTypeahead'
import type { TypeheadItem } from '@/contracts/typehead-item'
import { useBoolean } from 'usehooks-ts'

const formatTime = (
  hours: number = 0,
  minutes: number = 0,
  seconds: number = 0
) => {
  return `${String(hours ?? 0).padStart(2, '0')}:${String(minutes ?? 0).padStart(2, '0')}:${String(seconds ?? 0).padStart(2, '0')}`
}

const Timer: React.FC = () => {
  const [, startTransition] = useTransition()
  const {
    // seconds: daySeconds,
    minutes: dayMinutes,
    hours: dayHours,
  } = useTime({})
  const [loadingPresent, dismiss] = useIonLoading()
  const { value: isLastStateCalculated, setTrue, setFalse } = useBoolean(false)
  const [design, dispatch] = useReducer(timerReducer, timerInitialState)
  const {
    send: sendBreakReminder,
    cancel: cancelBreakReminder,
    getPending: getPendingNotifications,
  } = useLocalNotification({
    onListen: () => {
      // console.log(action) TODO: do something
    },
  })
  const timeTrackingControllerLastHook = useTimeTrackingControllerLastHook()

  const {
    seconds: workSeconds,
    minutes: workMinutes,
    hours: workHours,
    start: workStart,
    pause: workPause,
    reset: workReset,
    totalSeconds: workTotalSeconds,
  } = useStopwatch({
    autoStart: false,
  })

  const {
    seconds: breakSeconds,
    minutes: breakMinutes,
    hours: breakHours,
    start: breakStart,
    pause: breakPause,
    reset: breakReset,
    totalSeconds: breakTotalSeconds,
  } = useStopwatch({
    autoStart: false,
  })

  const {
    hours: lastSessionHours,
    minutes: lastSessionMinutes,
    seconds: lastSessionSeconds,
    pause: lastSessionPause,
    reset: lastSessionReset,
  } = useStopwatch({ autoStart: false })

  const { data: projects } = useProjectsControllerFindAll(
    {},
    {
      query: {
        select: (data) => {
          return data.data.map((d: Project) => {
            return {
              value: d,
              title: d.name,
            } as TypeheadItem<Project>
          })
        },
      },
    }
  )
  const { mutateAsync: timerStartHook } = useTimeTrackingControllerStart()
  const { mutateAsync: timerBreakHook } = useTimeTrackingControllerBreak()
  const { mutateAsync: timerEndHook } = useTimeTrackingControllerEnd()
  const timeTrackingControllerWorkInfoHook =
    useTimeTrackingControllerWorkInfoHook()
  const {
    control,
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    formState: { isValid, errors },
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    watch,
  } = useForm<StartTimeTrackingDto>({
    mode: 'onBlur',
    defaultValues: {
      type: WorkType.home_work,
    },
    resolver: zodResolver(timeTrackingControllerStartBody),
  })
  const handleFormRequestError = UseFormRequestError({
    setError,
  })
  const calculateLastState = useCallback(async () => {
    void loadingPresent()
    setFalse()
    const workInfo = await timeTrackingControllerWorkInfoHook()
    startTransition(() => {
      switch (workInfo.status) {
        case WorkStatus.NotStarted:
          setValue('type', workInfo.workType ?? WorkType.home_work, {
            shouldDirty: false,
            shouldTouch: false,
            shouldValidate: false,
          })
          if (workInfo?.project !== null) {
            setValue('project', workInfo?.project ?? '', {
              shouldDirty: false,
              shouldTouch: false,
              shouldValidate: false,
            })
          }
          dispatch({ type: TimerButtonState.Stopped })
          workReset(
            DateTime.now().plus(workInfo.workDuration).toJSDate(),
            false
          )
          breakReset(
            DateTime.now().plus(workInfo.breakDuration).toJSDate(),
            false
          )
          lastSessionReset(undefined, true)
          break
        case WorkStatus.OnWork:
          setValue('type', workInfo?.workType ?? WorkType.office_work, {
            shouldDirty: false,
            shouldTouch: false,
            shouldValidate: false,
          })
          setValue('project', workInfo?.project ?? { uuid: '' }, {
            shouldDirty: false,
            shouldTouch: false,
            shouldValidate: false,
          })
          dispatch({ type: TimerButtonState.Running })
          workReset(DateTime.now().plus(workInfo.workDuration).toJSDate(), true)
          breakReset(
            DateTime.now().plus(workInfo.breakDuration).toJSDate(),
            false
          )
          lastSessionReset(
            DateTime.now().plus(workInfo.workDuration).toJSDate(),
            true
          )
          break
        case WorkStatus.OnBreak:
          dispatch({ type: TimerButtonState.Paused })
          workReset(
            DateTime.now().plus(workInfo.workDuration).toJSDate(),
            false
          )
          breakReset(
            DateTime.now().plus(workInfo.breakDuration).toJSDate(),
            true
          )
          lastSessionReset(
            DateTime.now().plus(workInfo.breakDuration).toJSDate(),
            true
          )
          break
      }
      setTrue()
    })
    await handleBreakReminderNotification()
    void dismiss()
  }, [])

  const handleBreakReminderNotification = useCallback(async () => {
    const lastWork = await timeTrackingControllerLastHook()
    const { notifications = [] } = await getPendingNotifications()
    if (
      lastWork.timeType === TimeTrackingType.work &&
      lastWork.endAt === null
    ) {
      if (
        -1 ===
        notifications.findIndex(
          (notification) => notification.id === NotificationId.BREAK_REMINDER
        )
      ) {
        void sendBreakReminder({
          actionTypeId: NotificationActionType.BREAK_REMINDER,
          id: NotificationId.BREAK_REMINDER,
          title: 'Break Reminder',
          body: "Time for a break? You've been working for over 6 hours straight!",
          schedule: {
            at: DateTime.fromJSDate(lastWork.startAt)
              .plus({ hour: 6 })
              .toJSDate(),
            allowWhileIdle: true,
          },
          extra: lastWork,
        })
      }
    } else {
      const staleNotificationsBreakReminder = notifications.filter(
        (notification) =>
          // @ts-ignore
          notification.actionTypeId === NotificationActionType.BREAK_REMINDER
      )
      await Promise.all(
        staleNotificationsBreakReminder.map((notification) => {
          return cancelBreakReminder(notification.id)
        })
      )
    }
  }, [])

  useIonViewWillEnter(() => {
    void calculateLastState()
    void dismiss()
  }, [])

  const onStart = async () => {
    await trigger()
    if (!isValid) {
      return
    }
    void loadingPresent()
    const values: StartTimeTrackingDto = getValues()
    await timerStartHook({ data: values })
      .then(() => {
        dispatch({ type: TimerButtonState.Running })
        breakPause()
        workStart()
        lastSessionReset(undefined, true)
        clearErrors()
      })
      .catch(handleFormRequestError)
      .finally(() => {
        void handleBreakReminderNotification()
      })
    void dismiss()
  }

  const onStop = async () => {
    trigger()
    if (!isValid) {
      return
    }
    void loadingPresent()
    await timerEndHook()
      .then(() => {
        dispatch({ type: TimerButtonState.Stopped })
        workPause()
        breakPause()
        lastSessionPause()
        clearErrors()
      })
      .catch(handleFormRequestError)
      .finally(() => {
        dismiss()
      })
  }

  const onPause = async () => {
    await trigger()
    if (!isValid) {
      return
    }
    await loadingPresent()
    try {
      await timerBreakHook()
      dispatch({ type: TimerButtonState.Paused })
      workPause()
      breakStart()
      lastSessionReset(undefined, true)
      clearErrors()
    } catch (e) {
      handleFormRequestError(e)
    } finally {
      await dismiss()
    }
  }

  const expectedDurations = {
    workSeconds: 8 * 60 * 60,
    breakSeconds: 60 * 60,
  }
  const gradientWorking: GradientProps = {
    id: 'GradientWorking',
    startColor: '#00FF94',
    stopColor: '#C9D911',
  }
  const gradientBreak: GradientProps = {
    id: 'GradientBreak',
    startColor: '#4BFFD4',
    stopColor: '#2C82E7',
  }
  const strokeDashArray = 580

  const timerString = formatTime(
    lastSessionHours,
    lastSessionMinutes,
    lastSessionSeconds
  )

  const modal = useRef<HTMLIonModalElement>(null)
  const isRunning = design.stateValue === TimerButtonState.Running
  const [project, type] = watch(['project', 'type'])
  useEffect(() => {
    if (isRunning && isLastStateCalculated) {
      onStart().then()
    }
  }, [project, type])
  const handleRefresh = (
    event: IonRefresherCustomEvent<RefresherEventDetail>
  ) => {
    calculateLastState().then(() => {
      event.detail.complete()
    })
  }
  return (
    <IonPage className="timer">
      <IonHeader>
        <IonToolbar>
          <IonTitle className={'ion-text-center'}>Timer</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollX={false}>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <form id="timer-form">
          <IonGrid>
            <IonRow id={'project-selector'}>
              <IonCol>
                <Controller
                  name="project"
                  control={control}
                  render={({ field }) => (
                    <>
                      <IonList>
                        <IonItem
                          button={true}
                          detail={false}
                          id="select-project"
                          lines={'none'}
                          className={'radio-item'}
                        >
                          <IonLabel>Select Project</IonLabel>
                          <div slot="end" id="selected-fruits">
                            {(field.value as Project)?.name}
                          </div>
                        </IonItem>
                      </IonList>
                      <FormErrorMessage errors={errors} name={field.name} />
                      <IonModal trigger="select-project" ref={modal}>
                        <AppTypeahead
                          title="Select project"
                          items={projects ?? []}
                          selectedItems={field.value as Project}
                          onSelectionCancel={() => modal.current?.dismiss()}
                          compareWith={(c, v) => c?.uuid === v?.uuid}
                          onSelectionChange={(s) => {
                            field.onChange(s)
                            modal.current?.dismiss()
                          }}
                        />
                      </IonModal>
                    </>
                  )}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <>
                      <IonRadioGroup
                        onIonChange={field.onChange}
                        value={field.value}
                      >
                        <IonRow>
                          <IonCol>
                            <IonItem lines={'none'} className={'radio-item'}>
                              <IonIcon
                                icon={homeOutline}
                                className={'radio-icon'}
                                slot={'start'}
                              ></IonIcon>
                              <IonRadio
                                value={WorkType.home_work}
                                justify="space-between"
                                labelPlacement={'start'}
                              >
                                Home office
                              </IonRadio>
                            </IonItem>
                          </IonCol>
                          <IonCol>
                            <IonItem lines={'none'} className={'radio-item'}>
                              <IonIcon
                                icon={businessOutline}
                                className={'radio-icon'}
                                slot={'start'}
                              ></IonIcon>
                              <IonRadio
                                value={WorkType.office_work}
                                justify="space-between"
                              >
                                Main office
                              </IonRadio>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol>
                            <IonItem lines={'none'} className={'radio-item'}>
                              <IonIcon
                                icon={locationOutline}
                                slot={'start'}
                                className={'radio-icon'}
                              ></IonIcon>
                              <IonRadio
                                value={WorkType.site_work}
                                justify="space-between"
                              >
                                Project site
                              </IonRadio>
                            </IonItem>
                          </IonCol>
                          <IonCol>
                            <IonItem lines={'none'} className={'radio-item'}>
                              <IonIcon
                                icon={carOutline}
                                slot={'start'}
                                className={'radio-icon'}
                              ></IonIcon>
                              <IonRadio
                                value={WorkType.transit}
                                justify="space-between"
                              >
                                In transit
                              </IonRadio>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                      </IonRadioGroup>

                      <FormErrorMessage errors={errors} name={field.name} />
                    </>
                  )}
                />
              </IonCol>
            </IonRow>
            <IonRow className={'ion-justify-content-center'}>
              <IonCol size={'auto'}>
                <div id="start-button-container">
                  <div id="start-button-3" className={'start-concentric'}></div>
                  <div id="start-button-2" className={'start-concentric'}></div>
                  <div id="start-button-1" className={'start-concentric'}></div>
                  <TimerButton
                    currentState={design.stateValue}
                    handlePause={onPause}
                    handleStart={onStart}
                    handleStop={async () => {
                      await onStop()
                    }}
                    handleResume={async () => {
                      await onStart()
                    }}
                    timerString={timerString}
                  />
                  <svg
                    id="progress-bar"
                    className={'start-concentric'}
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                  >
                    <defs>
                      <Gradient {...gradientWorking}></Gradient>
                      <Gradient {...gradientBreak}></Gradient>
                    </defs>
                    <ProgressCircle
                      strokeDashArray={strokeDashArray}
                      strokeId={
                        design.stateValue === TimerButtonState.Paused
                          ? gradientBreak.id
                          : gradientWorking.id
                      }
                      maxValue={
                        design.stateValue === TimerButtonState.Paused
                          ? expectedDurations.breakSeconds
                          : expectedDurations.workSeconds
                      }
                      curValue={
                        design.stateValue === TimerButtonState.Paused
                          ? breakTotalSeconds
                          : workTotalSeconds
                      }
                    />
                  </svg>
                </div>
              </IonCol>
            </IonRow>
            <IonRow className={'ion-justify-content-center'}>
              <IonCol size={'auto'}>
                {DateTime.now().toFormat('cccc dd LLL yyyy')}
                {', '}
                {String(dayHours).padStart(2, '0')}:
                {String(dayMinutes).padStart(2, '0')}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonSegment
                  disabled={true}
                  style={{ background: 'transparent' }}
                >
                  <IonSegmentButton
                    id={'today-work-hours'}
                    className={'work-hours'}
                  >
                    <h2>
                      {String(workHours).padStart(2, '0')}:
                      {String(workMinutes).padStart(2, '0')}:
                      {String(workSeconds).padStart(2, '0')}
                    </h2>
                    <p>Today work hours</p>
                  </IonSegmentButton>
                  <IonSegmentButton
                    id={'today-break-hours'}
                    className={'work-hours'}
                  >
                    <h2>
                      {String(breakHours).padStart(2, '0')}:
                      {String(breakMinutes).padStart(2, '0')}:
                      {String(breakSeconds).padStart(2, '0')}
                    </h2>
                    <p>Today break hours</p>
                  </IonSegmentButton>
                </IonSegment>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonContent>
    </IonPage>
  )
}

export default Timer
