import type React from 'react'
import { useEffect, useState } from 'react'
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonLabel,
  IonPage,
  IonRippleEffect,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react'

import {
  authControllerResetPasswordBody,
  authControllerResetPasswordBodyPasswordMin,
  authControllerValidateResetPasswordParams,
  type ResetPasswordDto,
  useAuthControllerResetPasswordHook,
  useAuthControllerValidateResetPasswordHook,
} from '@/api'
import type { SubmitHandler } from 'react-hook-form'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { eyeOffOutline, eyeOutline, lockOpenOutline } from 'ionicons/icons'
import { FormErrorMessage } from '@/components/_form-error-message'
import zod from 'zod'
import { useParams } from 'react-router'

type resetPasswordForm = ResetPasswordDto & { confirmPassword: string }

export const SetPassword: React.FC = () => {
  const [show, setShow] = useState(false)
  const { token } = useParams<{ token: string }>()
  const router = useIonRouter()
  const resetPasswordHook = useAuthControllerResetPasswordHook()
  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid, errors, isSubmitting },
    setError,
    reset,
  } = useForm<resetPasswordForm>({
    mode: 'onChange',
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: zodResolver(
      authControllerResetPasswordBody
        .merge(
          zod.object({
            confirmPassword: zod
              .string()
              .min(authControllerResetPasswordBodyPasswordMin),
          })
        )
        .refine(
          ({ confirmPassword, password }: resetPasswordForm) => {
            return password === confirmPassword
          },
          {
            message: "Passwords don't match",
            path: ['confirmPassword'],
          }
        )
    ),
  })

  const validateResetPassword = useAuthControllerValidateResetPasswordHook()
  useEffect(() => {
    const { success } = authControllerValidateResetPasswordParams.safeParse({
      token,
    })
    if (!success) {
      return router.push('/login')
    }
    validateResetPassword({ token: token }).catch(() => {
      return router.push('/login')
    })
  }, [token])
  const onSubmit: SubmitHandler<resetPasswordForm> = async (data) => {
    try {
      await resetPasswordHook({ token }, { password: data.password })
      reset()
      router.push('/login')
      // Login successful
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      setError('password', {
        type: 'custom',
        message: 'Cannot set password. Please try again.',
      })
    }
  }
  return (
    <IonPage className="set-password">
      <IonHeader>
        <IonToolbar>
          <IonTitle size={'small'} className={'ion-text-center'}>
            Set Password
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={false} className={'ion-padding-horizontal'}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonLabel>
                  <h1>Set Password</h1>
                </IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonLabel>
                  <p>Please enter your credentials to continue.</p>
                </IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <>
                      <IonInput
                        value={field.value}
                        name={field.name}
                        placeholder="Password"
                        aria-label="Password"
                        type={show ? 'text' : 'password'}
                        className="custom-input"
                        onIonInput={field.onChange}
                        onIonBlur={field.onBlur}
                      >
                        <IonIcon
                          icon={lockOpenOutline}
                          slot="start"
                          className={'ion-padding-start'}
                        />
                        <IonButton
                          fill={'clear'}
                          slot={'end'}
                          size="small"
                          onClick={() => {
                            setShow((prevState) => !prevState)
                          }}
                          tabIndex={-1}
                          className="ion-no-padding ion-no-margin"
                        >
                          <IonIcon
                            icon={show ? eyeOutline : eyeOffOutline}
                            size="small"
                            slot={'icon-only'}
                            className={'ion-padding-end'}
                            color={'dark'}
                          />
                        </IonButton>
                      </IonInput>
                      <FormErrorMessage errors={errors} name={field.name} />
                    </>
                  )}
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <Controller
                  name="confirmPassword"
                  control={control}
                  render={({ field }) => (
                    <>
                      <IonInput
                        value={field.value}
                        name={field.name}
                        placeholder="Password"
                        aria-label="Password"
                        type={show ? 'text' : 'password'}
                        className="custom-input"
                        onIonInput={field.onChange}
                        onIonBlur={field.onBlur}
                      >
                        <IonIcon
                          icon={lockOpenOutline}
                          slot="start"
                          className={'ion-padding-start'}
                        />
                        <IonButton
                          fill={'clear'}
                          slot={'end'}
                          size="small"
                          onClick={() => {
                            setShow((prevState) => !prevState)
                          }}
                          tabIndex={-1}
                          className="ion-no-padding ion-no-margin"
                        >
                          <IonIcon
                            icon={show ? eyeOutline : eyeOffOutline}
                            size="small"
                            slot={'icon-only'}
                            className={'ion-padding-end'}
                            color={'dark'}
                          />
                        </IonButton>
                      </IonInput>
                      <FormErrorMessage errors={errors} name={field.name} />
                    </>
                  )}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonButton
            type="submit"
            expand="block"
            fill="clear"
            className="custom-item-button"
            mode="md"
            disabled={!isDirty || !isValid || isSubmitting}
          >
            <IonLabel>Set Password</IonLabel>
            <IonRippleEffect></IonRippleEffect>
          </IonButton>
        </form>
      </IonContent>
    </IonPage>
  )
}
