import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseInfiniteQueryResult,
  DefinedUseQueryResult,
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { useCallback } from 'react'
import type {
  ErrorDto,
  StartTimeTrackingDto,
  TimeTracking,
  TimeTrackingChangesPreviewResponseDto,
  TimeTrackingControllerDeletePathParameters,
  TimeTrackingControllerFindAll200,
  TimeTrackingControllerFindAllParams,
  TimeTrackingControllerFindPathParameters,
  TimeTrackingControllerUpdatePathParameters,
  TimeTrackingDto,
  WorkInformationResponseDto,
} from '../model'
import { useCustomInstance } from '../mutator/use-custom-instance'
import type { ErrorType } from '../mutator/use-custom-instance'

/**
 * @summary Start new time tracking record with selected type and project
 */
export const useTimeTrackingControllerStartHook = () => {
  const timeTrackingControllerStart = useCustomInstance<TimeTracking>()

  return useCallback(
    (startTimeTrackingDto: StartTimeTrackingDto) => {
      return timeTrackingControllerStart({
        url: `/app/time-tracking/start`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: startTimeTrackingDto,
      })
    },
    [timeTrackingControllerStart]
  )
}

export const useTimeTrackingControllerStartMutationOptions = <
  TError = ErrorType<ErrorDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerStartHook>>>,
    TError,
    { data: StartTimeTrackingDto },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerStartHook>>>,
  TError,
  { data: StartTimeTrackingDto },
  TContext
> => {
  const mutationKey = ['timeTrackingControllerStart']
  const { mutation: mutationOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } }

  const timeTrackingControllerStart = useTimeTrackingControllerStartHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerStartHook>>>,
    { data: StartTimeTrackingDto }
  > = (props) => {
    const { data } = props ?? {}

    return timeTrackingControllerStart(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type TimeTrackingControllerStartMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerStartHook>>>
>
export type TimeTrackingControllerStartMutationBody = StartTimeTrackingDto
export type TimeTrackingControllerStartMutationError = ErrorType<ErrorDto>

/**
 * @summary Start new time tracking record with selected type and project
 */
export const useTimeTrackingControllerStart = <
  TError = ErrorType<ErrorDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerStartHook>>>,
    TError,
    { data: StartTimeTrackingDto },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerStartHook>>>,
  TError,
  { data: StartTimeTrackingDto },
  TContext
> => {
  const mutationOptions = useTimeTrackingControllerStartMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Mark last started time tracking record ended
 */
export const useTimeTrackingControllerBreakHook = () => {
  const timeTrackingControllerBreak = useCustomInstance<TimeTracking>()

  return useCallback(() => {
    return timeTrackingControllerBreak({
      url: `/app/time-tracking/break`,
      method: 'POST',
    })
  }, [timeTrackingControllerBreak])
}

export const useTimeTrackingControllerBreakMutationOptions = <
  TError = ErrorType<ErrorDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerBreakHook>>>,
    TError,
    void,
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerBreakHook>>>,
  TError,
  void,
  TContext
> => {
  const mutationKey = ['timeTrackingControllerBreak']
  const { mutation: mutationOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } }

  const timeTrackingControllerBreak = useTimeTrackingControllerBreakHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerBreakHook>>>,
    void
  > = () => {
    return timeTrackingControllerBreak()
  }

  return { mutationFn, ...mutationOptions }
}

export type TimeTrackingControllerBreakMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerBreakHook>>>
>

export type TimeTrackingControllerBreakMutationError = ErrorType<ErrorDto>

/**
 * @summary Mark last started time tracking record ended
 */
export const useTimeTrackingControllerBreak = <
  TError = ErrorType<ErrorDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerBreakHook>>>,
    TError,
    void,
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerBreakHook>>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = useTimeTrackingControllerBreakMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Mark last started or broke time tracking as clock out item
 */
export const useTimeTrackingControllerEndHook = () => {
  const timeTrackingControllerEnd = useCustomInstance<TimeTracking>()

  return useCallback(() => {
    return timeTrackingControllerEnd({
      url: `/app/time-tracking/end`,
      method: 'POST',
    })
  }, [timeTrackingControllerEnd])
}

export const useTimeTrackingControllerEndMutationOptions = <
  TError = ErrorType<ErrorDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerEndHook>>>,
    TError,
    void,
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerEndHook>>>,
  TError,
  void,
  TContext
> => {
  const mutationKey = ['timeTrackingControllerEnd']
  const { mutation: mutationOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } }

  const timeTrackingControllerEnd = useTimeTrackingControllerEndHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerEndHook>>>,
    void
  > = () => {
    return timeTrackingControllerEnd()
  }

  return { mutationFn, ...mutationOptions }
}

export type TimeTrackingControllerEndMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerEndHook>>>
>

export type TimeTrackingControllerEndMutationError = ErrorType<ErrorDto>

/**
 * @summary Mark last started or broke time tracking as clock out item
 */
export const useTimeTrackingControllerEnd = <
  TError = ErrorType<ErrorDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerEndHook>>>,
    TError,
    void,
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerEndHook>>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = useTimeTrackingControllerEndMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Get last time tracking item
 */
export const useTimeTrackingControllerLastHook = () => {
  const timeTrackingControllerLast = useCustomInstance<TimeTracking>()

  return useCallback(() => {
    return timeTrackingControllerLast({
      url: `/app/time-tracking/last`,
      method: 'GET',
    })
  }, [timeTrackingControllerLast])
}

export const getTimeTrackingControllerLastQueryKey = () => {
  return [`/app/time-tracking/last`] as const
}

export const useTimeTrackingControllerLastInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>>
  >,
  TError = ErrorType<ErrorDto>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>>,
      TError,
      TData
    >
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getTimeTrackingControllerLastQueryKey()

  const timeTrackingControllerLast = useTimeTrackingControllerLastHook()

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>>
  > = () => timeTrackingControllerLast()

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type TimeTrackingControllerLastInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>>
>
export type TimeTrackingControllerLastInfiniteQueryError = ErrorType<ErrorDto>

export function useTimeTrackingControllerLastInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>>
  >,
  TError = ErrorType<ErrorDto>,
>(options: {
  query: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>
        >,
        TError,
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>
        >
      >,
      'initialData'
    >
}): DefinedUseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useTimeTrackingControllerLastInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>>
  >,
  TError = ErrorType<ErrorDto>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>
        >,
        TError,
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>
        >
      >,
      'initialData'
    >
}): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useTimeTrackingControllerLastInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>>
  >,
  TError = ErrorType<ErrorDto>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>>,
      TError,
      TData
    >
  >
}): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get last time tracking item
 */

export function useTimeTrackingControllerLastInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>>
  >,
  TError = ErrorType<ErrorDto>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>>,
      TError,
      TData
    >
  >
}): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
} {
  const queryOptions =
    useTimeTrackingControllerLastInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData, TError> }

  query.queryKey = queryOptions.queryKey

  return query
}

export const useTimeTrackingControllerLastQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>>,
      TError,
      TData
    >
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getTimeTrackingControllerLastQueryKey()

  const timeTrackingControllerLast = useTimeTrackingControllerLastHook()

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>>
  > = () => timeTrackingControllerLast()

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type TimeTrackingControllerLastQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>>
>
export type TimeTrackingControllerLastQueryError = ErrorType<ErrorDto>

export function useTimeTrackingControllerLast<
  TData = Awaited<
    ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>
        >,
        TError,
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>
        >
      >,
      'initialData'
    >
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useTimeTrackingControllerLast<
  TData = Awaited<
    ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>
        >,
        TError,
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>
        >
      >,
      'initialData'
    >
}): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useTimeTrackingControllerLast<
  TData = Awaited<
    ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>>,
      TError,
      TData
    >
  >
}): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get last time tracking item
 */

export function useTimeTrackingControllerLast<
  TData = Awaited<
    ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerLastHook>>>,
      TError,
      TData
    >
  >
}): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
} {
  const queryOptions = useTimeTrackingControllerLastQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Get last work information
 */
export const useTimeTrackingControllerWorkInfoHook = () => {
  const timeTrackingControllerWorkInfo =
    useCustomInstance<WorkInformationResponseDto>()

  return useCallback(() => {
    return timeTrackingControllerWorkInfo({
      url: `/app/time-tracking/work-info`,
      method: 'GET',
    })
  }, [timeTrackingControllerWorkInfo])
}

export const getTimeTrackingControllerWorkInfoQueryKey = () => {
  return [`/app/time-tracking/work-info`] as const
}

export const useTimeTrackingControllerWorkInfoInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
    >
  >,
  TError = ErrorType<ErrorDto>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
      >,
      TError,
      TData
    >
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getTimeTrackingControllerWorkInfoQueryKey()

  const timeTrackingControllerWorkInfo = useTimeTrackingControllerWorkInfoHook()

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
    >
  > = () => timeTrackingControllerWorkInfo()

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
    >,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type TimeTrackingControllerWorkInfoInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>>
>
export type TimeTrackingControllerWorkInfoInfiniteQueryError =
  ErrorType<ErrorDto>

export function useTimeTrackingControllerWorkInfoInfinite<
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
    >
  >,
  TError = ErrorType<ErrorDto>,
>(options: {
  query: Partial<
    UseInfiniteQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
      >,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
        >,
        TError,
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
        >
      >,
      'initialData'
    >
}): DefinedUseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useTimeTrackingControllerWorkInfoInfinite<
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
    >
  >,
  TError = ErrorType<ErrorDto>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
      >,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
        >,
        TError,
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
        >
      >,
      'initialData'
    >
}): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useTimeTrackingControllerWorkInfoInfinite<
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
    >
  >,
  TError = ErrorType<ErrorDto>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
      >,
      TError,
      TData
    >
  >
}): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get last work information
 */

export function useTimeTrackingControllerWorkInfoInfinite<
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
    >
  >,
  TError = ErrorType<ErrorDto>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
      >,
      TError,
      TData
    >
  >
}): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
} {
  const queryOptions =
    useTimeTrackingControllerWorkInfoInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData, TError> }

  query.queryKey = queryOptions.queryKey

  return query
}

export const useTimeTrackingControllerWorkInfoQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
      >,
      TError,
      TData
    >
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getTimeTrackingControllerWorkInfoQueryKey()

  const timeTrackingControllerWorkInfo = useTimeTrackingControllerWorkInfoHook()

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
    >
  > = () => timeTrackingControllerWorkInfo()

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
    >,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type TimeTrackingControllerWorkInfoQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>>
>
export type TimeTrackingControllerWorkInfoQueryError = ErrorType<ErrorDto>

export function useTimeTrackingControllerWorkInfo<
  TData = Awaited<
    ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
      >,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
        >,
        TError,
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
        >
      >,
      'initialData'
    >
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useTimeTrackingControllerWorkInfo<
  TData = Awaited<
    ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
      >,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
        >,
        TError,
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
        >
      >,
      'initialData'
    >
}): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useTimeTrackingControllerWorkInfo<
  TData = Awaited<
    ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
      >,
      TError,
      TData
    >
  >
}): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get last work information
 */

export function useTimeTrackingControllerWorkInfo<
  TData = Awaited<
    ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useTimeTrackingControllerWorkInfoHook>>
      >,
      TError,
      TData
    >
  >
}): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
} {
  const queryOptions = useTimeTrackingControllerWorkInfoQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Create new time tracking record
 */
export const useTimeTrackingControllerCreateHook = () => {
  const timeTrackingControllerCreate = useCustomInstance<TimeTracking>()

  return useCallback(
    (timeTrackingDto: TimeTrackingDto) => {
      return timeTrackingControllerCreate({
        url: `/app/time-tracking`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: timeTrackingDto,
      })
    },
    [timeTrackingControllerCreate]
  )
}

export const useTimeTrackingControllerCreateMutationOptions = <
  TError = ErrorType<ErrorDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerCreateHook>>>,
    TError,
    { data: TimeTrackingDto },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerCreateHook>>>,
  TError,
  { data: TimeTrackingDto },
  TContext
> => {
  const mutationKey = ['timeTrackingControllerCreate']
  const { mutation: mutationOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } }

  const timeTrackingControllerCreate = useTimeTrackingControllerCreateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerCreateHook>>>,
    { data: TimeTrackingDto }
  > = (props) => {
    const { data } = props ?? {}

    return timeTrackingControllerCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type TimeTrackingControllerCreateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerCreateHook>>>
>
export type TimeTrackingControllerCreateMutationBody = TimeTrackingDto
export type TimeTrackingControllerCreateMutationError = ErrorType<ErrorDto>

/**
 * @summary Create new time tracking record
 */
export const useTimeTrackingControllerCreate = <
  TError = ErrorType<ErrorDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerCreateHook>>>,
    TError,
    { data: TimeTrackingDto },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerCreateHook>>>,
  TError,
  { data: TimeTrackingDto },
  TContext
> => {
  const mutationOptions =
    useTimeTrackingControllerCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Get list of time tracking records for current logged-in user
 */
export const useTimeTrackingControllerFindAllHook = () => {
  const timeTrackingControllerFindAll =
    useCustomInstance<TimeTrackingControllerFindAll200>()

  return useCallback(
    (params?: TimeTrackingControllerFindAllParams) => {
      return timeTrackingControllerFindAll({
        url: `/app/time-tracking`,
        method: 'GET',
        params,
      })
    },
    [timeTrackingControllerFindAll]
  )
}

export const getTimeTrackingControllerFindAllQueryKey = (
  params?: TimeTrackingControllerFindAllParams
) => {
  return [`/app/time-tracking`, ...(params ? [params] : [])] as const
}

export const useTimeTrackingControllerFindAllInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
    >,
    TimeTrackingControllerFindAllParams['page']
  >,
  TError = ErrorType<ErrorDto>,
>(
  params?: TimeTrackingControllerFindAllParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
        >,
        TError,
        TData,
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
        >,
        QueryKey,
        TimeTrackingControllerFindAllParams['page']
      >
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getTimeTrackingControllerFindAllQueryKey(params)

  const timeTrackingControllerFindAll = useTimeTrackingControllerFindAllHook()

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
    >,
    QueryKey,
    TimeTrackingControllerFindAllParams['page']
  > = ({ pageParam }) =>
    timeTrackingControllerFindAll({
      ...params,
      page: pageParam || params?.['page'],
    })

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
    >,
    TError,
    TData,
    Awaited<
      ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
    >,
    QueryKey,
    TimeTrackingControllerFindAllParams['page']
  > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type TimeTrackingControllerFindAllInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>>
>
export type TimeTrackingControllerFindAllInfiniteQueryError =
  ErrorType<ErrorDto>

export function useTimeTrackingControllerFindAllInfinite<
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
    >,
    TimeTrackingControllerFindAllParams['page']
  >,
  TError = ErrorType<ErrorDto>,
>(
  params: undefined | TimeTrackingControllerFindAllParams,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
        >,
        TError,
        TData,
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
        >,
        QueryKey,
        TimeTrackingControllerFindAllParams['page']
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
          >,
          TError,
          Awaited<
            ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
          >,
          QueryKey
        >,
        'initialData'
      >
  }
): DefinedUseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useTimeTrackingControllerFindAllInfinite<
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
    >,
    TimeTrackingControllerFindAllParams['page']
  >,
  TError = ErrorType<ErrorDto>,
>(
  params?: TimeTrackingControllerFindAllParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
        >,
        TError,
        TData,
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
        >,
        QueryKey,
        TimeTrackingControllerFindAllParams['page']
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
          >,
          TError,
          Awaited<
            ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
          >,
          QueryKey
        >,
        'initialData'
      >
  }
): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useTimeTrackingControllerFindAllInfinite<
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
    >,
    TimeTrackingControllerFindAllParams['page']
  >,
  TError = ErrorType<ErrorDto>,
>(
  params?: TimeTrackingControllerFindAllParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
        >,
        TError,
        TData,
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
        >,
        QueryKey,
        TimeTrackingControllerFindAllParams['page']
      >
    >
  }
): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get list of time tracking records for current logged-in user
 */

export function useTimeTrackingControllerFindAllInfinite<
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
    >,
    TimeTrackingControllerFindAllParams['page']
  >,
  TError = ErrorType<ErrorDto>,
>(
  params?: TimeTrackingControllerFindAllParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
        >,
        TError,
        TData,
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
        >,
        QueryKey,
        TimeTrackingControllerFindAllParams['page']
      >
    >
  }
): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
} {
  const queryOptions = useTimeTrackingControllerFindAllInfiniteQueryOptions(
    params,
    options
  )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData, TError> }

  query.queryKey = queryOptions.queryKey

  return query
}

export const useTimeTrackingControllerFindAllQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  params?: TimeTrackingControllerFindAllParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
        >,
        TError,
        TData
      >
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getTimeTrackingControllerFindAllQueryKey(params)

  const timeTrackingControllerFindAll = useTimeTrackingControllerFindAllHook()

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>>
  > = () => timeTrackingControllerFindAll(params)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
    >,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type TimeTrackingControllerFindAllQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>>
>
export type TimeTrackingControllerFindAllQueryError = ErrorType<ErrorDto>

export function useTimeTrackingControllerFindAll<
  TData = Awaited<
    ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  params: undefined | TimeTrackingControllerFindAllParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
          >,
          TError,
          Awaited<
            ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
          >
        >,
        'initialData'
      >
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useTimeTrackingControllerFindAll<
  TData = Awaited<
    ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  params?: TimeTrackingControllerFindAllParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
          >,
          TError,
          Awaited<
            ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
          >
        >,
        'initialData'
      >
  }
): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useTimeTrackingControllerFindAll<
  TData = Awaited<
    ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  params?: TimeTrackingControllerFindAllParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
        >,
        TError,
        TData
      >
    >
  }
): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get list of time tracking records for current logged-in user
 */

export function useTimeTrackingControllerFindAll<
  TData = Awaited<
    ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  params?: TimeTrackingControllerFindAllParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerFindAllHook>>
        >,
        TError,
        TData
      >
    >
  }
): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
} {
  const queryOptions = useTimeTrackingControllerFindAllQueryOptions(
    params,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Preview new time tracking record changes
 */
export const useTimeTrackingControllerPreviewHook = () => {
  const timeTrackingControllerPreview =
    useCustomInstance<TimeTrackingChangesPreviewResponseDto>()

  return useCallback(
    (timeTrackingDto: TimeTrackingDto) => {
      return timeTrackingControllerPreview({
        url: `/app/time-tracking/preview`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: timeTrackingDto,
      })
    },
    [timeTrackingControllerPreview]
  )
}

export const useTimeTrackingControllerPreviewMutationOptions = <
  TError = ErrorType<ErrorDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useTimeTrackingControllerPreviewHook>>
    >,
    TError,
    { data: TimeTrackingDto },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerPreviewHook>>>,
  TError,
  { data: TimeTrackingDto },
  TContext
> => {
  const mutationKey = ['timeTrackingControllerPreview']
  const { mutation: mutationOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } }

  const timeTrackingControllerPreview = useTimeTrackingControllerPreviewHook()

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useTimeTrackingControllerPreviewHook>>
    >,
    { data: TimeTrackingDto }
  > = (props) => {
    const { data } = props ?? {}

    return timeTrackingControllerPreview(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type TimeTrackingControllerPreviewMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerPreviewHook>>>
>
export type TimeTrackingControllerPreviewMutationBody = TimeTrackingDto
export type TimeTrackingControllerPreviewMutationError = ErrorType<ErrorDto>

/**
 * @summary Preview new time tracking record changes
 */
export const useTimeTrackingControllerPreview = <
  TError = ErrorType<ErrorDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useTimeTrackingControllerPreviewHook>>
    >,
    TError,
    { data: TimeTrackingDto },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerPreviewHook>>>,
  TError,
  { data: TimeTrackingDto },
  TContext
> => {
  const mutationOptions =
    useTimeTrackingControllerPreviewMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Selected time tracking information
 */
export const useTimeTrackingControllerFindHook = () => {
  const timeTrackingControllerFind = useCustomInstance<TimeTracking>()

  return useCallback(
    ({ id }: TimeTrackingControllerFindPathParameters) => {
      return timeTrackingControllerFind({
        url: `/app/time-tracking/${encodeURIComponent(String(id))}`,
        method: 'GET',
      })
    },
    [timeTrackingControllerFind]
  )
}

export const getTimeTrackingControllerFindQueryKey = ({
  id,
}: TimeTrackingControllerFindPathParameters) => {
  return [`/app/time-tracking/${id}`] as const
}

export const useTimeTrackingControllerFindInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  { id }: TimeTrackingControllerFindPathParameters,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>
        >,
        TError,
        TData
      >
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getTimeTrackingControllerFindQueryKey({ id })

  const timeTrackingControllerFind = useTimeTrackingControllerFindHook()

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>>
  > = () => timeTrackingControllerFind({ id })

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type TimeTrackingControllerFindInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>>
>
export type TimeTrackingControllerFindInfiniteQueryError = ErrorType<ErrorDto>

export function useTimeTrackingControllerFindInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: TimeTrackingControllerFindPathParameters,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>
          >,
          TError,
          Awaited<
            ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>
          >
        >,
        'initialData'
      >
  }
): DefinedUseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useTimeTrackingControllerFindInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: TimeTrackingControllerFindPathParameters,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>
          >,
          TError,
          Awaited<
            ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>
          >
        >,
        'initialData'
      >
  }
): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useTimeTrackingControllerFindInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: TimeTrackingControllerFindPathParameters,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>
        >,
        TError,
        TData
      >
    >
  }
): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Selected time tracking information
 */

export function useTimeTrackingControllerFindInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  { id }: TimeTrackingControllerFindPathParameters,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>
        >,
        TError,
        TData
      >
    >
  }
): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
} {
  const queryOptions = useTimeTrackingControllerFindInfiniteQueryOptions(
    { id },
    options
  )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData, TError> }

  query.queryKey = queryOptions.queryKey

  return query
}

export const useTimeTrackingControllerFindQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  { id }: TimeTrackingControllerFindPathParameters,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>
        >,
        TError,
        TData
      >
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getTimeTrackingControllerFindQueryKey({ id })

  const timeTrackingControllerFind = useTimeTrackingControllerFindHook()

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>>
  > = () => timeTrackingControllerFind({ id })

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type TimeTrackingControllerFindQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>>
>
export type TimeTrackingControllerFindQueryError = ErrorType<ErrorDto>

export function useTimeTrackingControllerFind<
  TData = Awaited<
    ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: TimeTrackingControllerFindPathParameters,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>
          >,
          TError,
          Awaited<
            ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>
          >
        >,
        'initialData'
      >
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useTimeTrackingControllerFind<
  TData = Awaited<
    ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: TimeTrackingControllerFindPathParameters,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>
          >,
          TError,
          Awaited<
            ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>
          >
        >,
        'initialData'
      >
  }
): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useTimeTrackingControllerFind<
  TData = Awaited<
    ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: TimeTrackingControllerFindPathParameters,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>
        >,
        TError,
        TData
      >
    >
  }
): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Selected time tracking information
 */

export function useTimeTrackingControllerFind<
  TData = Awaited<
    ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  { id }: TimeTrackingControllerFindPathParameters,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useTimeTrackingControllerFindHook>>
        >,
        TError,
        TData
      >
    >
  }
): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
} {
  const queryOptions = useTimeTrackingControllerFindQueryOptions(
    { id },
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Update time tracking record
 */
export const useTimeTrackingControllerUpdateHook = () => {
  const timeTrackingControllerUpdate = useCustomInstance<TimeTracking>()

  return useCallback(
    (
      { id }: TimeTrackingControllerUpdatePathParameters,
      timeTrackingDto: TimeTrackingDto
    ) => {
      return timeTrackingControllerUpdate({
        url: `/app/time-tracking/${encodeURIComponent(String(id))}`,
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        data: timeTrackingDto,
      })
    },
    [timeTrackingControllerUpdate]
  )
}

export const useTimeTrackingControllerUpdateMutationOptions = <
  TError = ErrorType<ErrorDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerUpdateHook>>>,
    TError,
    {
      pathParams: TimeTrackingControllerUpdatePathParameters
      data: TimeTrackingDto
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerUpdateHook>>>,
  TError,
  {
    pathParams: TimeTrackingControllerUpdatePathParameters
    data: TimeTrackingDto
  },
  TContext
> => {
  const mutationKey = ['timeTrackingControllerUpdate']
  const { mutation: mutationOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } }

  const timeTrackingControllerUpdate = useTimeTrackingControllerUpdateHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerUpdateHook>>>,
    {
      pathParams: TimeTrackingControllerUpdatePathParameters
      data: TimeTrackingDto
    }
  > = (props) => {
    const { pathParams, data } = props ?? {}

    return timeTrackingControllerUpdate(pathParams, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type TimeTrackingControllerUpdateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerUpdateHook>>>
>
export type TimeTrackingControllerUpdateMutationBody = TimeTrackingDto
export type TimeTrackingControllerUpdateMutationError = ErrorType<ErrorDto>

/**
 * @summary Update time tracking record
 */
export const useTimeTrackingControllerUpdate = <
  TError = ErrorType<ErrorDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerUpdateHook>>>,
    TError,
    {
      pathParams: TimeTrackingControllerUpdatePathParameters
      data: TimeTrackingDto
    },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerUpdateHook>>>,
  TError,
  {
    pathParams: TimeTrackingControllerUpdatePathParameters
    data: TimeTrackingDto
  },
  TContext
> => {
  const mutationOptions =
    useTimeTrackingControllerUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Delete time tracking record
 */
export const useTimeTrackingControllerDeleteHook = () => {
  const timeTrackingControllerDelete = useCustomInstance<void>()

  return useCallback(
    ({ id }: TimeTrackingControllerDeletePathParameters) => {
      return timeTrackingControllerDelete({
        url: `/app/time-tracking/${encodeURIComponent(String(id))}`,
        method: 'DELETE',
      })
    },
    [timeTrackingControllerDelete]
  )
}

export const useTimeTrackingControllerDeleteMutationOptions = <
  TError = ErrorType<ErrorDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerDeleteHook>>>,
    TError,
    { pathParams: TimeTrackingControllerDeletePathParameters },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerDeleteHook>>>,
  TError,
  { pathParams: TimeTrackingControllerDeletePathParameters },
  TContext
> => {
  const mutationKey = ['timeTrackingControllerDelete']
  const { mutation: mutationOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } }

  const timeTrackingControllerDelete = useTimeTrackingControllerDeleteHook()

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerDeleteHook>>>,
    { pathParams: TimeTrackingControllerDeletePathParameters }
  > = (props) => {
    const { pathParams } = props ?? {}

    return timeTrackingControllerDelete(pathParams)
  }

  return { mutationFn, ...mutationOptions }
}

export type TimeTrackingControllerDeleteMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerDeleteHook>>>
>

export type TimeTrackingControllerDeleteMutationError = ErrorType<ErrorDto>

/**
 * @summary Delete time tracking record
 */
export const useTimeTrackingControllerDelete = <
  TError = ErrorType<ErrorDto>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerDeleteHook>>>,
    TError,
    { pathParams: TimeTrackingControllerDeletePathParameters },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useTimeTrackingControllerDeleteHook>>>,
  TError,
  { pathParams: TimeTrackingControllerDeletePathParameters },
  TContext
> => {
  const mutationOptions =
    useTimeTrackingControllerDeleteMutationOptions(options)

  return useMutation(mutationOptions)
}
