import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseInfiniteQueryResult,
  DefinedUseQueryResult,
  InfiniteData,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { useCallback } from 'react'
import type {
  ErrorDto,
  ProjectsControllerFindAll200,
  ProjectsControllerFindAllParams,
} from '../model'
import { useCustomInstance } from '../mutator/use-custom-instance'
import type { ErrorType } from '../mutator/use-custom-instance'

/**
 * @summary Get list of time tracking records for current logged-in user
 */
export const useProjectsControllerFindAllHook = () => {
  const projectsControllerFindAll =
    useCustomInstance<ProjectsControllerFindAll200>()

  return useCallback(
    (params?: ProjectsControllerFindAllParams) => {
      return projectsControllerFindAll({
        url: `/app/projects`,
        method: 'GET',
        params,
      })
    },
    [projectsControllerFindAll]
  )
}

export const getProjectsControllerFindAllQueryKey = (
  params?: ProjectsControllerFindAllParams
) => {
  return [`/app/projects`, ...(params ? [params] : [])] as const
}

export const useProjectsControllerFindAllInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>>,
    ProjectsControllerFindAllParams['page']
  >,
  TError = ErrorType<ErrorDto>,
>(
  params?: ProjectsControllerFindAllParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>
        >,
        TError,
        TData,
        Awaited<
          ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>
        >,
        QueryKey,
        ProjectsControllerFindAllParams['page']
      >
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getProjectsControllerFindAllQueryKey(params)

  const projectsControllerFindAll = useProjectsControllerFindAllHook()

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>>,
    QueryKey,
    ProjectsControllerFindAllParams['page']
  > = ({ pageParam }) =>
    projectsControllerFindAll({
      ...params,
      page: pageParam || params?.['page'],
    })

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>>,
    TError,
    TData,
    Awaited<ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>>,
    QueryKey,
    ProjectsControllerFindAllParams['page']
  > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type ProjectsControllerFindAllInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>>
>
export type ProjectsControllerFindAllInfiniteQueryError = ErrorType<ErrorDto>

export function useProjectsControllerFindAllInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>>,
    ProjectsControllerFindAllParams['page']
  >,
  TError = ErrorType<ErrorDto>,
>(
  params: undefined | ProjectsControllerFindAllParams,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>
        >,
        TError,
        TData,
        Awaited<
          ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>
        >,
        QueryKey,
        ProjectsControllerFindAllParams['page']
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>
          >,
          TError,
          Awaited<
            ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>
          >,
          QueryKey
        >,
        'initialData'
      >
  }
): DefinedUseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useProjectsControllerFindAllInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>>,
    ProjectsControllerFindAllParams['page']
  >,
  TError = ErrorType<ErrorDto>,
>(
  params?: ProjectsControllerFindAllParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>
        >,
        TError,
        TData,
        Awaited<
          ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>
        >,
        QueryKey,
        ProjectsControllerFindAllParams['page']
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>
          >,
          TError,
          Awaited<
            ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>
          >,
          QueryKey
        >,
        'initialData'
      >
  }
): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useProjectsControllerFindAllInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>>,
    ProjectsControllerFindAllParams['page']
  >,
  TError = ErrorType<ErrorDto>,
>(
  params?: ProjectsControllerFindAllParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>
        >,
        TError,
        TData,
        Awaited<
          ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>
        >,
        QueryKey,
        ProjectsControllerFindAllParams['page']
      >
    >
  }
): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get list of time tracking records for current logged-in user
 */

export function useProjectsControllerFindAllInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>>,
    ProjectsControllerFindAllParams['page']
  >,
  TError = ErrorType<ErrorDto>,
>(
  params?: ProjectsControllerFindAllParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>
        >,
        TError,
        TData,
        Awaited<
          ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>
        >,
        QueryKey,
        ProjectsControllerFindAllParams['page']
      >
    >
  }
): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
} {
  const queryOptions = useProjectsControllerFindAllInfiniteQueryOptions(
    params,
    options
  )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData, TError> }

  query.queryKey = queryOptions.queryKey

  return query
}

export const useProjectsControllerFindAllQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  params?: ProjectsControllerFindAllParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>
        >,
        TError,
        TData
      >
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getProjectsControllerFindAllQueryKey(params)

  const projectsControllerFindAll = useProjectsControllerFindAllHook()

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>>
  > = () => projectsControllerFindAll(params)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type ProjectsControllerFindAllQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>>
>
export type ProjectsControllerFindAllQueryError = ErrorType<ErrorDto>

export function useProjectsControllerFindAll<
  TData = Awaited<
    ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  params: undefined | ProjectsControllerFindAllParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>
          >,
          TError,
          Awaited<
            ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>
          >
        >,
        'initialData'
      >
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useProjectsControllerFindAll<
  TData = Awaited<
    ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  params?: ProjectsControllerFindAllParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>
          >,
          TError,
          Awaited<
            ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>
          >
        >,
        'initialData'
      >
  }
): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useProjectsControllerFindAll<
  TData = Awaited<
    ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  params?: ProjectsControllerFindAllParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>
        >,
        TError,
        TData
      >
    >
  }
): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get list of time tracking records for current logged-in user
 */

export function useProjectsControllerFindAll<
  TData = Awaited<
    ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  params?: ProjectsControllerFindAllParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useProjectsControllerFindAllHook>>
        >,
        TError,
        TData
      >
    >
  }
): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
} {
  const queryOptions = useProjectsControllerFindAllQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
  }

  query.queryKey = queryOptions.queryKey

  return query
}
