import type React from 'react'
import {
  IonAvatar,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSkeletonText,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react'

import styles from './profile.module.css'
import {
  atOutline,
  lockClosedOutline,
  logOutOutline,
  personOutline,
} from 'ionicons/icons'
import { useLogout } from '@/hooks/use-logout'
import { useAuthControllerGetProfile } from '@/api'
import ProfileChangePassword from '@/components/tabs/profile-change-password'
import { useRef } from 'react'

const Profile: React.FC = () => {
  const logout = useLogout()
  const router = useIonRouter()
  const { data: profile, isLoading } = useAuthControllerGetProfile()
  const modal = useRef(null)
  if (isLoading || profile == null) {
    return (
      <IonThumbnail slot="start">
        <IonSkeletonText animated={true}></IonSkeletonText>
      </IonThumbnail>
    )
  }
  const handleLogout = () => {
    logout()
    router.push('/login', 'back')
  }

  return (
    <IonPage className={styles.profile}>
      <IonHeader>
        <IonToolbar>
          <IonTitle className={'ion-text-center'}>Profile</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={false} scrollX={false}>
        <IonList>
          <IonItem>
            <IonGrid>
              <IonRow className={'ion-align-items-center'}>
                <IonCol size={'auto'}>
                  <IonAvatar>
                    <IonImg
                      alt="user image"
                      src={
                        'profilePicture' in profile
                          ? profile.profilePicture
                          : ''
                      }
                    ></IonImg>
                  </IonAvatar>
                </IonCol>
                <IonCol>
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <h2 className={'ion-no-margin'}>{profile.fullName}</h2>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol className={'custom-input-gray-light-03'}>
                        {profile.email}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
          <IonItemDivider>
            <IonLabel>ACCOUNT</IonLabel>
          </IonItemDivider>
          {profile.currentContract?.role.name && (
            <IonItem>
              <IonLabel>User Role</IonLabel>@
              {profile.currentContract?.role.name}
              <IonIcon icon={personOutline} size={'large'} slot={'start'} />
            </IonItem>
          )}
          <IonItem>
            <IonLabel>Email</IonLabel>
            {profile.email}
            <IonIcon icon={atOutline} size={'large'} slot={'start'} />
          </IonItem>
          <IonItem button={true} detail={true} id={'change-password'}>
            Change password
            <IonIcon icon={lockClosedOutline} size={'large'} slot={'start'} />
          </IonItem>
          <IonItemDivider>
            <IonLabel>SYSTEM</IonLabel>
          </IonItemDivider>
          <IonItem button={true} detail={true} onClick={handleLogout}>
            <IonLabel>Logout</IonLabel>
            <IonIcon
              icon={logOutOutline}
              size={'large'}
              slot={'start'}
            ></IonIcon>
          </IonItem>
        </IonList>
      </IonContent>
      <ProfileChangePassword triggerId={'change-password'} modalRef={modal} />
    </IonPage>
  )
}

export default Profile
