import { z as zod } from 'zod'

/**
 * @summary Login user into the platform
 */
export const authControllerSignInBody = zod.object({
  email: zod.coerce.string().email(),
  password: zod.coerce.string(),
})

/**
 * @summary Update current logged-in user profile information
 */
export const authControllerUpdateBodyPasswordMin = 6

export const authControllerUpdateBody = zod.object({
  password: zod.coerce
    .string()
    .min(authControllerUpdateBodyPasswordMin)
    .optional(),
})

/**
 * @summary Set new password using reset password token
 */
export const authControllerResetPasswordParams = zod.object({
  token: zod.coerce.string().uuid(),
})

export const authControllerResetPasswordBodyPasswordMin = 6

export const authControllerResetPasswordBody = zod.object({
  password: zod.coerce.string().min(authControllerResetPasswordBodyPasswordMin),
})

/**
 * @summary Validate reset password token is valid
 */
export const authControllerValidateResetPasswordParams = zod.object({
  token: zod.coerce.string().uuid(),
})
