import { z as zod } from 'zod'

/**
 * @summary Get work location insight
 */
export const reportControllerWorkLocationInsightParams = zod.object({
  from: zod.coerce.date(),
  to: zod.coerce.date(),
})

/**
 * @summary Get work project insight
 */
export const reportControllerWorkProjectInsightParams = zod.object({
  from: zod.coerce.date(),
  to: zod.coerce.date(),
})

/**
 * @summary Get work vs break insight
 */
export const reportControllerWorkVsBreakInsightParams = zod.object({
  from: zod.coerce.date(),
  to: zod.coerce.date(),
})

/**
 * @summary Get work history insight
 */
export const reportControllerGetWorkHistoryParams = zod.object({
  from: zod.coerce.date(),
  to: zod.coerce.date(),
})
