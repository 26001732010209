import { z as zod } from 'zod'

/**
 * @summary Start new time tracking record with selected type and project
 */
export const timeTrackingControllerStartBody = zod.object({
  type: zod.enum(['home_work', 'office_work', 'site_work', 'transit']),
  project: zod.object({
    uuid: zod.coerce.string().uuid(),
  }),
})

/**
 * @summary Create new time tracking record
 */
export const timeTrackingControllerCreateBody = zod.object({
  type: zod
    .enum(['home_work', 'office_work', 'site_work', 'transit'])
    .nullish(),
  project: zod
    .object({
      uuid: zod.coerce.string().uuid(),
    })
    .nullish(),
  startAt: zod.coerce.date(),
  endAt: zod.coerce.date(),
  timeType: zod.enum(['work', 'break', 'time-off']),
  isClockOut: zod.coerce.boolean().optional(),
})

/**
 * @summary Preview new time tracking record changes
 */
export const timeTrackingControllerPreviewBody = zod.object({
  type: zod
    .enum(['home_work', 'office_work', 'site_work', 'transit'])
    .nullish(),
  project: zod
    .object({
      uuid: zod.coerce.string().uuid(),
    })
    .nullish(),
  startAt: zod.coerce.date(),
  endAt: zod.coerce.date(),
  timeType: zod.enum(['work', 'break', 'time-off']),
  isClockOut: zod.coerce.boolean().optional(),
})

/**
 * @summary Selected time tracking information
 */
export const timeTrackingControllerFindParams = zod.object({
  id: zod.coerce.string().uuid(),
})

/**
 * @summary Update time tracking record
 */
export const timeTrackingControllerUpdateParams = zod.object({
  id: zod.coerce.string().uuid(),
})

export const timeTrackingControllerUpdateBody = zod.object({
  type: zod
    .enum(['home_work', 'office_work', 'site_work', 'transit'])
    .nullish(),
  project: zod
    .object({
      uuid: zod.coerce.string().uuid(),
    })
    .nullish(),
  startAt: zod.coerce.date(),
  endAt: zod.coerce.date(),
  timeType: zod.enum(['work', 'break', 'time-off']),
  isClockOut: zod.coerce.boolean().optional(),
})

/**
 * @summary Delete time tracking record
 */
export const timeTrackingControllerDeleteParams = zod.object({
  id: zod.coerce.string().uuid(),
})
