import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseInfiniteQueryResult,
  DefinedUseQueryResult,
  InfiniteData,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { useCallback } from 'react'
import type {
  ErrorDto,
  ReportControllerGetWorkHistoryPathParameters,
  ReportControllerWorkLocationInsightPathParameters,
  ReportControllerWorkProjectInsightPathParameters,
  ReportControllerWorkVsBreakInsightPathParameters,
  WorkLocationInsight,
  WorkProjectInsight,
  WorkRecords,
} from '../model'
import { useCustomInstance } from '../mutator/use-custom-instance'
import type { ErrorType } from '../mutator/use-custom-instance'

/**
 * @summary Get work location insight
 */
export const useReportControllerWorkLocationInsightHook = () => {
  const reportControllerWorkLocationInsight =
    useCustomInstance<WorkLocationInsight[]>()

  return useCallback(
    ({ from, to }: ReportControllerWorkLocationInsightPathParameters) => {
      return reportControllerWorkLocationInsight({
        url: `/app/report/work-location-insight/${encodeURIComponent(String(from))}/${encodeURIComponent(String(to))}`,
        method: 'GET',
      })
    },
    [reportControllerWorkLocationInsight]
  )
}

export const getReportControllerWorkLocationInsightQueryKey = ({
  from,
  to,
}: ReportControllerWorkLocationInsightPathParameters) => {
  return [`/app/report/work-location-insight/${from}/${to}`] as const
}

export const useReportControllerWorkLocationInsightInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerWorkLocationInsightHook>>
    >
  >,
  TError = ErrorType<ErrorDto>,
>(
  { from, to }: ReportControllerWorkLocationInsightPathParameters,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkLocationInsightHook>
          >
        >,
        TError,
        TData
      >
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getReportControllerWorkLocationInsightQueryKey({ from, to })

  const reportControllerWorkLocationInsight =
    useReportControllerWorkLocationInsightHook()

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerWorkLocationInsightHook>>
    >
  > = () => reportControllerWorkLocationInsight({ from, to })

  return {
    queryKey,
    queryFn,
    enabled: !!(from && to),
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerWorkLocationInsightHook>>
    >,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type ReportControllerWorkLocationInsightInfiniteQueryResult =
  NonNullable<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerWorkLocationInsightHook>>
    >
  >
export type ReportControllerWorkLocationInsightInfiniteQueryError =
  ErrorType<ErrorDto>

export function useReportControllerWorkLocationInsightInfinite<
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerWorkLocationInsightHook>>
    >
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: ReportControllerWorkLocationInsightPathParameters,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkLocationInsightHook>
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              ReturnType<typeof useReportControllerWorkLocationInsightHook>
            >
          >,
          TError,
          Awaited<
            ReturnType<
              ReturnType<typeof useReportControllerWorkLocationInsightHook>
            >
          >
        >,
        'initialData'
      >
  }
): DefinedUseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useReportControllerWorkLocationInsightInfinite<
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerWorkLocationInsightHook>>
    >
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: ReportControllerWorkLocationInsightPathParameters,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkLocationInsightHook>
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              ReturnType<typeof useReportControllerWorkLocationInsightHook>
            >
          >,
          TError,
          Awaited<
            ReturnType<
              ReturnType<typeof useReportControllerWorkLocationInsightHook>
            >
          >
        >,
        'initialData'
      >
  }
): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useReportControllerWorkLocationInsightInfinite<
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerWorkLocationInsightHook>>
    >
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: ReportControllerWorkLocationInsightPathParameters,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkLocationInsightHook>
          >
        >,
        TError,
        TData
      >
    >
  }
): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get work location insight
 */

export function useReportControllerWorkLocationInsightInfinite<
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerWorkLocationInsightHook>>
    >
  >,
  TError = ErrorType<ErrorDto>,
>(
  { from, to }: ReportControllerWorkLocationInsightPathParameters,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkLocationInsightHook>
          >
        >,
        TError,
        TData
      >
    >
  }
): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
} {
  const queryOptions =
    useReportControllerWorkLocationInsightInfiniteQueryOptions(
      { from, to },
      options
    )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData, TError> }

  query.queryKey = queryOptions.queryKey

  return query
}

export const useReportControllerWorkLocationInsightQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useReportControllerWorkLocationInsightHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  { from, to }: ReportControllerWorkLocationInsightPathParameters,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkLocationInsightHook>
          >
        >,
        TError,
        TData
      >
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getReportControllerWorkLocationInsightQueryKey({ from, to })

  const reportControllerWorkLocationInsight =
    useReportControllerWorkLocationInsightHook()

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerWorkLocationInsightHook>>
    >
  > = () => reportControllerWorkLocationInsight({ from, to })

  return {
    queryKey,
    queryFn,
    enabled: !!(from && to),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerWorkLocationInsightHook>>
    >,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type ReportControllerWorkLocationInsightQueryResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useReportControllerWorkLocationInsightHook>>
  >
>
export type ReportControllerWorkLocationInsightQueryError = ErrorType<ErrorDto>

export function useReportControllerWorkLocationInsight<
  TData = Awaited<
    ReturnType<ReturnType<typeof useReportControllerWorkLocationInsightHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: ReportControllerWorkLocationInsightPathParameters,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkLocationInsightHook>
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              ReturnType<typeof useReportControllerWorkLocationInsightHook>
            >
          >,
          TError,
          Awaited<
            ReturnType<
              ReturnType<typeof useReportControllerWorkLocationInsightHook>
            >
          >
        >,
        'initialData'
      >
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useReportControllerWorkLocationInsight<
  TData = Awaited<
    ReturnType<ReturnType<typeof useReportControllerWorkLocationInsightHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: ReportControllerWorkLocationInsightPathParameters,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkLocationInsightHook>
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              ReturnType<typeof useReportControllerWorkLocationInsightHook>
            >
          >,
          TError,
          Awaited<
            ReturnType<
              ReturnType<typeof useReportControllerWorkLocationInsightHook>
            >
          >
        >,
        'initialData'
      >
  }
): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useReportControllerWorkLocationInsight<
  TData = Awaited<
    ReturnType<ReturnType<typeof useReportControllerWorkLocationInsightHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: ReportControllerWorkLocationInsightPathParameters,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkLocationInsightHook>
          >
        >,
        TError,
        TData
      >
    >
  }
): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get work location insight
 */

export function useReportControllerWorkLocationInsight<
  TData = Awaited<
    ReturnType<ReturnType<typeof useReportControllerWorkLocationInsightHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  { from, to }: ReportControllerWorkLocationInsightPathParameters,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkLocationInsightHook>
          >
        >,
        TError,
        TData
      >
    >
  }
): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
} {
  const queryOptions = useReportControllerWorkLocationInsightQueryOptions(
    { from, to },
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Get work project insight
 */
export const useReportControllerWorkProjectInsightHook = () => {
  const reportControllerWorkProjectInsight =
    useCustomInstance<WorkProjectInsight[]>()

  return useCallback(
    ({ from, to }: ReportControllerWorkProjectInsightPathParameters) => {
      return reportControllerWorkProjectInsight({
        url: `/app/report/work-project-insight/${encodeURIComponent(String(from))}/${encodeURIComponent(String(to))}`,
        method: 'GET',
      })
    },
    [reportControllerWorkProjectInsight]
  )
}

export const getReportControllerWorkProjectInsightQueryKey = ({
  from,
  to,
}: ReportControllerWorkProjectInsightPathParameters) => {
  return [`/app/report/work-project-insight/${from}/${to}`] as const
}

export const useReportControllerWorkProjectInsightInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerWorkProjectInsightHook>>
    >
  >,
  TError = ErrorType<ErrorDto>,
>(
  { from, to }: ReportControllerWorkProjectInsightPathParameters,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkProjectInsightHook>
          >
        >,
        TError,
        TData
      >
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getReportControllerWorkProjectInsightQueryKey({ from, to })

  const reportControllerWorkProjectInsight =
    useReportControllerWorkProjectInsightHook()

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerWorkProjectInsightHook>>
    >
  > = () => reportControllerWorkProjectInsight({ from, to })

  return {
    queryKey,
    queryFn,
    enabled: !!(from && to),
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerWorkProjectInsightHook>>
    >,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type ReportControllerWorkProjectInsightInfiniteQueryResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useReportControllerWorkProjectInsightHook>>
  >
>
export type ReportControllerWorkProjectInsightInfiniteQueryError =
  ErrorType<ErrorDto>

export function useReportControllerWorkProjectInsightInfinite<
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerWorkProjectInsightHook>>
    >
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: ReportControllerWorkProjectInsightPathParameters,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkProjectInsightHook>
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              ReturnType<typeof useReportControllerWorkProjectInsightHook>
            >
          >,
          TError,
          Awaited<
            ReturnType<
              ReturnType<typeof useReportControllerWorkProjectInsightHook>
            >
          >
        >,
        'initialData'
      >
  }
): DefinedUseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useReportControllerWorkProjectInsightInfinite<
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerWorkProjectInsightHook>>
    >
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: ReportControllerWorkProjectInsightPathParameters,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkProjectInsightHook>
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              ReturnType<typeof useReportControllerWorkProjectInsightHook>
            >
          >,
          TError,
          Awaited<
            ReturnType<
              ReturnType<typeof useReportControllerWorkProjectInsightHook>
            >
          >
        >,
        'initialData'
      >
  }
): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useReportControllerWorkProjectInsightInfinite<
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerWorkProjectInsightHook>>
    >
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: ReportControllerWorkProjectInsightPathParameters,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkProjectInsightHook>
          >
        >,
        TError,
        TData
      >
    >
  }
): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get work project insight
 */

export function useReportControllerWorkProjectInsightInfinite<
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerWorkProjectInsightHook>>
    >
  >,
  TError = ErrorType<ErrorDto>,
>(
  { from, to }: ReportControllerWorkProjectInsightPathParameters,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkProjectInsightHook>
          >
        >,
        TError,
        TData
      >
    >
  }
): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
} {
  const queryOptions =
    useReportControllerWorkProjectInsightInfiniteQueryOptions(
      { from, to },
      options
    )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData, TError> }

  query.queryKey = queryOptions.queryKey

  return query
}

export const useReportControllerWorkProjectInsightQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useReportControllerWorkProjectInsightHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  { from, to }: ReportControllerWorkProjectInsightPathParameters,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkProjectInsightHook>
          >
        >,
        TError,
        TData
      >
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getReportControllerWorkProjectInsightQueryKey({ from, to })

  const reportControllerWorkProjectInsight =
    useReportControllerWorkProjectInsightHook()

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerWorkProjectInsightHook>>
    >
  > = () => reportControllerWorkProjectInsight({ from, to })

  return {
    queryKey,
    queryFn,
    enabled: !!(from && to),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerWorkProjectInsightHook>>
    >,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type ReportControllerWorkProjectInsightQueryResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useReportControllerWorkProjectInsightHook>>
  >
>
export type ReportControllerWorkProjectInsightQueryError = ErrorType<ErrorDto>

export function useReportControllerWorkProjectInsight<
  TData = Awaited<
    ReturnType<ReturnType<typeof useReportControllerWorkProjectInsightHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: ReportControllerWorkProjectInsightPathParameters,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkProjectInsightHook>
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              ReturnType<typeof useReportControllerWorkProjectInsightHook>
            >
          >,
          TError,
          Awaited<
            ReturnType<
              ReturnType<typeof useReportControllerWorkProjectInsightHook>
            >
          >
        >,
        'initialData'
      >
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useReportControllerWorkProjectInsight<
  TData = Awaited<
    ReturnType<ReturnType<typeof useReportControllerWorkProjectInsightHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: ReportControllerWorkProjectInsightPathParameters,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkProjectInsightHook>
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              ReturnType<typeof useReportControllerWorkProjectInsightHook>
            >
          >,
          TError,
          Awaited<
            ReturnType<
              ReturnType<typeof useReportControllerWorkProjectInsightHook>
            >
          >
        >,
        'initialData'
      >
  }
): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useReportControllerWorkProjectInsight<
  TData = Awaited<
    ReturnType<ReturnType<typeof useReportControllerWorkProjectInsightHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: ReportControllerWorkProjectInsightPathParameters,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkProjectInsightHook>
          >
        >,
        TError,
        TData
      >
    >
  }
): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get work project insight
 */

export function useReportControllerWorkProjectInsight<
  TData = Awaited<
    ReturnType<ReturnType<typeof useReportControllerWorkProjectInsightHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  { from, to }: ReportControllerWorkProjectInsightPathParameters,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkProjectInsightHook>
          >
        >,
        TError,
        TData
      >
    >
  }
): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
} {
  const queryOptions = useReportControllerWorkProjectInsightQueryOptions(
    { from, to },
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Get work vs break insight
 */
export const useReportControllerWorkVsBreakInsightHook = () => {
  const reportControllerWorkVsBreakInsight = useCustomInstance<WorkRecords>()

  return useCallback(
    ({ from, to }: ReportControllerWorkVsBreakInsightPathParameters) => {
      return reportControllerWorkVsBreakInsight({
        url: `/app/report/work-vs-break-insight/${encodeURIComponent(String(from))}/${encodeURIComponent(String(to))}`,
        method: 'GET',
      })
    },
    [reportControllerWorkVsBreakInsight]
  )
}

export const getReportControllerWorkVsBreakInsightQueryKey = ({
  from,
  to,
}: ReportControllerWorkVsBreakInsightPathParameters) => {
  return [`/app/report/work-vs-break-insight/${from}/${to}`] as const
}

export const useReportControllerWorkVsBreakInsightInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerWorkVsBreakInsightHook>>
    >
  >,
  TError = ErrorType<ErrorDto>,
>(
  { from, to }: ReportControllerWorkVsBreakInsightPathParameters,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkVsBreakInsightHook>
          >
        >,
        TError,
        TData
      >
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getReportControllerWorkVsBreakInsightQueryKey({ from, to })

  const reportControllerWorkVsBreakInsight =
    useReportControllerWorkVsBreakInsightHook()

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerWorkVsBreakInsightHook>>
    >
  > = () => reportControllerWorkVsBreakInsight({ from, to })

  return {
    queryKey,
    queryFn,
    enabled: !!(from && to),
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerWorkVsBreakInsightHook>>
    >,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type ReportControllerWorkVsBreakInsightInfiniteQueryResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useReportControllerWorkVsBreakInsightHook>>
  >
>
export type ReportControllerWorkVsBreakInsightInfiniteQueryError =
  ErrorType<ErrorDto>

export function useReportControllerWorkVsBreakInsightInfinite<
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerWorkVsBreakInsightHook>>
    >
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: ReportControllerWorkVsBreakInsightPathParameters,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkVsBreakInsightHook>
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              ReturnType<typeof useReportControllerWorkVsBreakInsightHook>
            >
          >,
          TError,
          Awaited<
            ReturnType<
              ReturnType<typeof useReportControllerWorkVsBreakInsightHook>
            >
          >
        >,
        'initialData'
      >
  }
): DefinedUseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useReportControllerWorkVsBreakInsightInfinite<
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerWorkVsBreakInsightHook>>
    >
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: ReportControllerWorkVsBreakInsightPathParameters,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkVsBreakInsightHook>
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              ReturnType<typeof useReportControllerWorkVsBreakInsightHook>
            >
          >,
          TError,
          Awaited<
            ReturnType<
              ReturnType<typeof useReportControllerWorkVsBreakInsightHook>
            >
          >
        >,
        'initialData'
      >
  }
): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useReportControllerWorkVsBreakInsightInfinite<
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerWorkVsBreakInsightHook>>
    >
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: ReportControllerWorkVsBreakInsightPathParameters,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkVsBreakInsightHook>
          >
        >,
        TError,
        TData
      >
    >
  }
): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get work vs break insight
 */

export function useReportControllerWorkVsBreakInsightInfinite<
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerWorkVsBreakInsightHook>>
    >
  >,
  TError = ErrorType<ErrorDto>,
>(
  { from, to }: ReportControllerWorkVsBreakInsightPathParameters,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkVsBreakInsightHook>
          >
        >,
        TError,
        TData
      >
    >
  }
): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
} {
  const queryOptions =
    useReportControllerWorkVsBreakInsightInfiniteQueryOptions(
      { from, to },
      options
    )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData, TError> }

  query.queryKey = queryOptions.queryKey

  return query
}

export const useReportControllerWorkVsBreakInsightQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useReportControllerWorkVsBreakInsightHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  { from, to }: ReportControllerWorkVsBreakInsightPathParameters,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkVsBreakInsightHook>
          >
        >,
        TError,
        TData
      >
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getReportControllerWorkVsBreakInsightQueryKey({ from, to })

  const reportControllerWorkVsBreakInsight =
    useReportControllerWorkVsBreakInsightHook()

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerWorkVsBreakInsightHook>>
    >
  > = () => reportControllerWorkVsBreakInsight({ from, to })

  return {
    queryKey,
    queryFn,
    enabled: !!(from && to),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerWorkVsBreakInsightHook>>
    >,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type ReportControllerWorkVsBreakInsightQueryResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useReportControllerWorkVsBreakInsightHook>>
  >
>
export type ReportControllerWorkVsBreakInsightQueryError = ErrorType<ErrorDto>

export function useReportControllerWorkVsBreakInsight<
  TData = Awaited<
    ReturnType<ReturnType<typeof useReportControllerWorkVsBreakInsightHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: ReportControllerWorkVsBreakInsightPathParameters,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkVsBreakInsightHook>
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              ReturnType<typeof useReportControllerWorkVsBreakInsightHook>
            >
          >,
          TError,
          Awaited<
            ReturnType<
              ReturnType<typeof useReportControllerWorkVsBreakInsightHook>
            >
          >
        >,
        'initialData'
      >
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useReportControllerWorkVsBreakInsight<
  TData = Awaited<
    ReturnType<ReturnType<typeof useReportControllerWorkVsBreakInsightHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: ReportControllerWorkVsBreakInsightPathParameters,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkVsBreakInsightHook>
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              ReturnType<typeof useReportControllerWorkVsBreakInsightHook>
            >
          >,
          TError,
          Awaited<
            ReturnType<
              ReturnType<typeof useReportControllerWorkVsBreakInsightHook>
            >
          >
        >,
        'initialData'
      >
  }
): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useReportControllerWorkVsBreakInsight<
  TData = Awaited<
    ReturnType<ReturnType<typeof useReportControllerWorkVsBreakInsightHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: ReportControllerWorkVsBreakInsightPathParameters,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkVsBreakInsightHook>
          >
        >,
        TError,
        TData
      >
    >
  }
): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get work vs break insight
 */

export function useReportControllerWorkVsBreakInsight<
  TData = Awaited<
    ReturnType<ReturnType<typeof useReportControllerWorkVsBreakInsightHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  { from, to }: ReportControllerWorkVsBreakInsightPathParameters,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<typeof useReportControllerWorkVsBreakInsightHook>
          >
        >,
        TError,
        TData
      >
    >
  }
): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
} {
  const queryOptions = useReportControllerWorkVsBreakInsightQueryOptions(
    { from, to },
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Get work history insight
 */
export const useReportControllerGetWorkHistoryHook = () => {
  const reportControllerGetWorkHistory = useCustomInstance<WorkRecords[]>()

  return useCallback(
    ({ from, to }: ReportControllerGetWorkHistoryPathParameters) => {
      return reportControllerGetWorkHistory({
        url: `/app/report/work-history/${encodeURIComponent(String(from))}/${encodeURIComponent(String(to))}`,
        method: 'GET',
      })
    },
    [reportControllerGetWorkHistory]
  )
}

export const getReportControllerGetWorkHistoryQueryKey = ({
  from,
  to,
}: ReportControllerGetWorkHistoryPathParameters) => {
  return [`/app/report/work-history/${from}/${to}`] as const
}

export const useReportControllerGetWorkHistoryInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
    >
  >,
  TError = ErrorType<ErrorDto>,
>(
  { from, to }: ReportControllerGetWorkHistoryPathParameters,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
        >,
        TError,
        TData
      >
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getReportControllerGetWorkHistoryQueryKey({ from, to })

  const reportControllerGetWorkHistory = useReportControllerGetWorkHistoryHook()

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
    >
  > = () => reportControllerGetWorkHistory({ from, to })

  return {
    queryKey,
    queryFn,
    enabled: !!(from && to),
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
    >,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type ReportControllerGetWorkHistoryInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>>
>
export type ReportControllerGetWorkHistoryInfiniteQueryError =
  ErrorType<ErrorDto>

export function useReportControllerGetWorkHistoryInfinite<
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
    >
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: ReportControllerGetWorkHistoryPathParameters,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
          >,
          TError,
          Awaited<
            ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
          >
        >,
        'initialData'
      >
  }
): DefinedUseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useReportControllerGetWorkHistoryInfinite<
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
    >
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: ReportControllerGetWorkHistoryPathParameters,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
          >,
          TError,
          Awaited<
            ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
          >
        >,
        'initialData'
      >
  }
): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useReportControllerGetWorkHistoryInfinite<
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
    >
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: ReportControllerGetWorkHistoryPathParameters,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
        >,
        TError,
        TData
      >
    >
  }
): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get work history insight
 */

export function useReportControllerGetWorkHistoryInfinite<
  TData = InfiniteData<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
    >
  >,
  TError = ErrorType<ErrorDto>,
>(
  { from, to }: ReportControllerGetWorkHistoryPathParameters,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
        >,
        TError,
        TData
      >
    >
  }
): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
} {
  const queryOptions = useReportControllerGetWorkHistoryInfiniteQueryOptions(
    { from, to },
    options
  )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData, TError> }

  query.queryKey = queryOptions.queryKey

  return query
}

export const useReportControllerGetWorkHistoryQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  { from, to }: ReportControllerGetWorkHistoryPathParameters,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
        >,
        TError,
        TData
      >
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getReportControllerGetWorkHistoryQueryKey({ from, to })

  const reportControllerGetWorkHistory = useReportControllerGetWorkHistoryHook()

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
    >
  > = () => reportControllerGetWorkHistory({ from, to })

  return {
    queryKey,
    queryFn,
    enabled: !!(from && to),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
    >,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type ReportControllerGetWorkHistoryQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>>
>
export type ReportControllerGetWorkHistoryQueryError = ErrorType<ErrorDto>

export function useReportControllerGetWorkHistory<
  TData = Awaited<
    ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: ReportControllerGetWorkHistoryPathParameters,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
          >,
          TError,
          Awaited<
            ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
          >
        >,
        'initialData'
      >
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useReportControllerGetWorkHistory<
  TData = Awaited<
    ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: ReportControllerGetWorkHistoryPathParameters,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
          >,
          TError,
          Awaited<
            ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
          >
        >,
        'initialData'
      >
  }
): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
export function useReportControllerGetWorkHistory<
  TData = Awaited<
    ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  pathParams: ReportControllerGetWorkHistoryPathParameters,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
        >,
        TError,
        TData
      >
    >
  }
): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
}
/**
 * @summary Get work history insight
 */

export function useReportControllerGetWorkHistory<
  TData = Awaited<
    ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
  >,
  TError = ErrorType<ErrorDto>,
>(
  { from, to }: ReportControllerGetWorkHistoryPathParameters,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<ReturnType<typeof useReportControllerGetWorkHistoryHook>>
        >,
        TError,
        TData
      >
    >
  }
): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>
} {
  const queryOptions = useReportControllerGetWorkHistoryQueryOptions(
    { from, to },
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>
  }

  query.queryKey = queryOptions.queryKey

  return query
}
